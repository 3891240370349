import React from "react";

import {
  MainNavBar,
  Footer,
  AboutSection1,
  AboutSection2,
  AboutSection3,
  AboutSection4,
  AboutSection5,
  LineSeperator,
} from "../../UIcomponents/UiComponents";

import { useQuery } from "react-query";
import employeeRequests from "../../../services/helpers/employeesquery";
import boardMembersRequests from "../../../services/helpers/boardmembersquery";
import aboutRequests from "../../../services/helpers/aboutquery";

import { updatePageHead } from "../../../common/UpdateMetaAndTitle";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";

export default function About() {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  //page head update
  updatePageHead("About/Contact", "", "");

  //list  all  employees
  let queryDataSection4 = {
    resource: "employees",
  };
  const section4QueryData = useQuery(
    ["listAllEmployees", queryDataSection4],
    () => employeeRequests.listAllEmployees(queryDataSection4)
  );

  //list  all  board  members
  let queryDataSection5 = {
    resource: "boardmembers",
  };
  const section5QueryData = useQuery(
    ["listAllBoardMembers", queryDataSection5],
    () => boardMembersRequests.listAllBoardMembers(queryDataSection5)
  );

  //getAboutHKS
  let queryDataAboutHKS = {
    resource: "about-hks",
    language: appLanguage
  };
  const queryDatagetAboutHks = useQuery(
    ["getAboutHks", queryDataAboutHKS],
    () => aboutRequests.getAboutHks(queryDataAboutHKS)
  );

  return (
    <>
      <MainNavBar
        pageTitle={`${t("About")}/${t("Contact")}`}
        bgClass={"bg-highlight"}
      />

      <div className="content-container-wrapper  bg-highlight">
        <div className="content-container-content">
          {queryDatagetAboutHks && (
            <AboutSection2 queryData={queryDatagetAboutHks} />
          )}

          {queryDatagetAboutHks && (
            <AboutSection1 queryData={queryDatagetAboutHks} />
          )}

          {section4QueryData.data && (
            <AboutSection4 queryData={section4QueryData} />
          )}

          {section5QueryData.data && (
            <AboutSection5 queryData={section5QueryData} />
          )}
        </div>
      </div>
      <LineSeperator />
      <Footer />
    </>
  );
}
