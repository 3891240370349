import React from "react";
import "./style.css";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";

import moment from "moment";
import { renderStartAndEndDate } from "../../../helper/formatDate";

export default function UpcomingProgramList(props) {
  const { queryData, t } = props;

  let nextYear = moment().add(1, "years").format("YYYY");

  return (
    <div className="upcomingprogramlist__container">
      {queryData && (
        <>
          <div className="upcomingprogramlist__title text-fabric-sans">
            {t("Upcoming")}
          </div>
          <div className="upcomingprogramlist__artist-list">
            {queryData.map((artist) => {
              return (
                <Link to={`/artistprofile/${artist.id}`} key={artist.name}>
                  <div className="upcomingprogramlist__artist">
                    <p className="upcomingprogramlist__artist-date">
                      {
                        renderStartAndEndDate(artist.dateStart, artist.dateEnd)
                      }
                    </p>
                    <p className="upcomingprogramlist__artist-name fs-2-5em lh-2em">
                      {artist.name} {artist.country && ` (${artist.country})`}
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
