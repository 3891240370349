import api from "../api";
import to from "await-to-js";
import { getSingle } from "../queryGenerator";
import moment from "moment";

const Requests = {
  // special query
  // single type data

  getThemeValues: async () => {
    let queryData = {
      resource: "theme",
    };

    let resourceString = await getSingle(queryData.resource);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));

    if (!response) return null;

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
};

export default Requests;
