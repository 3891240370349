import React from "react";
import "./style.css";
import { Row, Col, Button, Form, Input } from "antd";

export default function NewsletterSubscription(props) {
  const { t } = props;
  return (
    <div className="newslettersubscription__container">
      <Row gutter={12}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xxl={{ span: 24 }}
        >
          <p className="newslettersubscription__title fs-0-9em">
            <a href='https://mailchi.mp/kunstsenter/sign-ups-newsletter-hks' target='_blank' rel="noreferrer">
              {t("Sign up for our weekly newsletter")}
            </a>
          </p>
          <div className="newslettersubscription__form-container">
            {/* not using form for now */}
            {/* <SubscriptionForm t={t} /> */}
          </div>
        </Col>
      </Row>
    </div>
  );
}

function SubscriptionForm(props) {
  const { t } = props;
  return (
    <div className="newslettersubscription__form">
      <Input placeholder={`${t("Your email address")}`} className="placeholder-f-s-1-2em" />
      <Button>{t("Send")}</Button>
    </div>
  );
}
