import moment from "moment";

export const renderStartAndEndDate = (startDate, endDate) => {
  if (startDate && endDate) {
    let a = formatDate(startDate)
    let b = formatDate(endDate)
    console.log(a.props.children, b.props.children)

    if (a.props.children === b.props.children) {
      return <span>{a}</span>
    } else {
      return <span>{a} - {b}</span>;
    }
  }
}

export const formatDate = (dateString) => {
  if (dateString) {
    let n_date = moment(dateString).format("DD.MM.YYYY");
    let _dd = moment(dateString).format("DD");
    let _mm = moment(dateString).format("MM");
    let _yy = moment(dateString).format("YYYY").slice(2, 4);

    let _xx = `${_dd}.${_mm}.${_yy}`;
    // return `${n_date}`;
    return <span className="text-fabric-sans">{_xx}</span>;
  }

};

export const formatDateWithFullYear = (dateString) => {
  if (dateString) {
    let n_date = moment(dateString).format("DD.MM.YYYY");
    let _dd = moment(dateString).format("DD");
    let _mm = moment(dateString).format("MM");
    let _yy = moment(dateString).format("YYYY")

    let _xx = `${_dd}.${_mm}.${_yy}`;
    // return `${n_date}`;
    return <span className="text-fabric-sans">{_xx}</span>;
  }

};
