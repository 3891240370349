import React, { useState } from "react";

import "./styles.css";

import { Link, withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";

import { NewsletterSubscription } from "../UiComponents";
import ReactHtmlParser from "react-html-parser";

import { Row, Col } from "antd";
import {
  InstagramOutlined,
  FacebookOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";

function Footer(props) {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  const { about } = props;

  return (
    <div className="content-container-wrapper bg-highlight footer-container-mar-100">
      <div className="content-container-content">
        <div className="footer__container">
          <Row gutter={42}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 16 }}
              xl={{ span: 16 }}
              xxl={{ span: 16 }}
            >
              {about.aboutDetails && (
                <div className="footer__left-line2">
                  <p className="footer__left-line2-h">{t("Opening Hours")}</p>
                  <div className="ck-content">
                    {ReactHtmlParser(about.aboutDetails.openingHours)}
                  </div>
                </div>
              )}

              <div>
                <NewsletterSubscription t={t} />
              </div>
            </Col>
            {about.aboutDetails && (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}
                xl={{ span: 8 }}
                xxl={{ span: 8 }}
              >
                <div className="footer__left-line1">
                  <div className="ck-content">
                    {ReactHtmlParser(about.aboutDetails.address)}
                  </div>
                </div>
                <div className="footer__left-line1">
                  <div className="ck-content">
                    {ReactHtmlParser(about.aboutDetails.contact)}
                  </div>
                </div>
                <div className="footer__right-icons">
                  <a
                    href={`${about.aboutDetails && about.aboutDetails.instagram
                      }`}
                  >
                    <i className="fab fa-instagram"></i>
                    {/* <InstagramOutlined /> */}
                  </a>
                  <a
                    href={`${about.aboutDetails && about.aboutDetails.facebook
                      }`}
                  >
                    <i className="fab fa-facebook-f"></i>
                    {/* <FacebookOutlined /> */}
                  </a>
                  <a
                    href={`${about.aboutDetails && about.aboutDetails.youtube}`}
                  >
                    <i className="fab fa-youtube"></i>
                    {/* <YoutubeOutlined /> */}
                  </a>
                </div>

                <div className="footer__right-copyright">
                  <p>
                    {about.aboutDetails && about.aboutDetails.copyrightText}
                    <br />
                    {t("Org. No.")}{" "}
                    {about.aboutDetails && about.aboutDetails.orgNo}
                  </p>
                  <p>
                    {t("Designed by")}{" "}
                    <a href="https://studioboi.no/">
                      <span className="footer__ext-link">Studio Boi</span>{" "}
                      <br />
                    </a>
                    <a href="#">
                      {t("Web development of")}{" "}
                      <span className="footer__ext-link">
                        Fjell og Fjord IT
                      </span>
                    </a>
                  </p>
                </div>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
}

// connect  redux  store
const mapStateToProps = (state) => {
  return {
    about: state.about,
  };
};

export default withRouter(connect(mapStateToProps)(Footer));
