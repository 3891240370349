import React, { useState, useEffect } from "react";
import "./styles.css";

import { Row, Col } from "antd";
import { Link } from "react-router-dom";
// language context
import LocalizationContext from "../../../../localization/LocalizationContext";

import { IMAGE_BASE_URL } from "../../../../services/api";

export default function TrianglePageSection3(props) {
  const { data, isLoading } = props.queryData;

  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  let [gridDataList, setGridDataList] = useState([]);

  useEffect(() => {
    // this filter is meaning less here
    // just kept  for now
    // shall  be  removed  later

    // console.log(data);
    let x = data.filter((item) => {
      return item.language === appLanguage;
    });
    let y = x && x.slice(0, 4);
    setGridDataList(y);
  }, [data, appLanguage]);

  let getColVal = (layoutString, baseCol) => {
    if (layoutString === "ONE") {
      return baseCol;
    } else if (layoutString === "TWO") {
      return baseCol;
      // return baseCol * 2;
    } else if (layoutString === "THREE") {
      return baseCol;
      // return baseCol * 3;
    } else return baseCol;
  };

  return (
    <div className="trianglepagesection3__container">
      {gridDataList && gridDataList.length > 0 && (
        <>
          <p className="trianglepagesection3__title text-fabric-sans">
            {t("Related Content")}
          </p>

          <Row gutter={35}>
            {gridDataList.map((item) => {
              return (
                <Col
                  key={item.title}
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: `${getColVal(1, 6)}` }}
                  xl={{ span: `${getColVal(1, 6)}` }}
                  xxl={{ span: `${getColVal(1, 6)}` }}
                  style={{ marginBottom: "35px" }}
                  flex=""
                >
                  <div className="programgrid__program-info-container">
                    <div className="programgrid__program-image">
                      <Link to={`/triangle/${item.id}`}>
                        <div className="programgrid__image-container">
                          {item.mainImage && (
                            <img
                              src={`${IMAGE_BASE_URL}${item.mainImage.url}`}
                            />
                          )}
                        </div>
                      </Link>
                    </div>

                    <Link to={`/triangle/${item.id}`}>
                      <p className="programgrid__program-info-program-title fs-1-4em lh-1-2em">
                        {item.title}
                      </p>
                    </Link>
                  </div>
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </div>
  );
}
