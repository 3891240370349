import React, { useState } from "react";
import "./styles.css";
import { IMAGE_BASE_URL } from "../../../../services/api";

import { LightBoxSimple } from "../../lightboxsimple/LightBoxSimple";

import { Row, Col } from "antd";
export default function ArtistProfileSection1(props) {
  const { isLoading, data, error } = props.queryData;

  const [toggle, setToggle] = useState(false);
  const [sIndex, setSIndex] = useState(0);

  // Handler
  const lightBoxHandler = (state, sIndex) => {
    setToggle(state);
    setSIndex(sIndex);
  };


  return (
    <>
      {data && data.profileImg && (
        <div className="artistprofilesection1__container">
          <p className="artistprofilesection1__name  fs-2-5em lh-1-2em">
            {data.name}
          </p>
          <Row>
            <Col span={24}>
              <div className="artistprofilesection1__img">
                <img src={`${IMAGE_BASE_URL}${data.profileImg.url}`}
                  onClick={() => {
                    lightBoxHandler(true, 0);
                  }}
                />
              </div>
              <span className="artistprofilesection1__img-caption">
                {data.imageCaption}
              </span>
            </Col>
          </Row>
          <LightBoxSimple
            toggle={toggle}
            lightBoxHandler={lightBoxHandler}
            data={[
              {
                "id": 1,
                "image": `${IMAGE_BASE_URL}${data.profileImg.url}`,
                "title": "",
                "description": ""
              }
            ]}
            sIndex={sIndex}
            setSIndex={setSIndex}
          />
        </div>
      )}
    </>
  );
}
