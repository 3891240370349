import React, { useRef, useState, useEffect } from "react";
import "./styles.css";

import { IMAGE_BASE_URL } from "../../../../services/api";

import ReactHtmlParser from "react-html-parser";

import { Row, Col, Carousel } from "antd";
import { LightBoxSimple } from "../../lightboxsimple/LightBoxSimple";

export default function BookstoreSection1(props) {
  const slider = useRef();
  const { sectionInfo } = props;

  let [currentSlide, setCurrentSlide] = useState(1);
  let [totalSlide, setTotalSlide] = useState(0);
  let [captionList, setCaptionList] = useState(null);
  let [midImageCaptionList, setMidImageCaptionList] = useState(null);

  const [imageListForLightBox, setImageListForLightBox] = useState([])
  const [toggle, setToggle] = useState(false);
  const [sIndex, setSIndex] = useState(0);

  // Handler
  const lightBoxHandler = (state, sIndex) => {
    setToggle(state);
    setSIndex(sIndex);
  };

  useEffect(() => {
    if (sectionInfo.image) {
      setTotalSlide(sectionInfo.image.length);
      let x = []
      // prepare the image list 
      sectionInfo.image.map((imageItem, index) => {
        x.push(
          {
            "id": index,
            "image": `${IMAGE_BASE_URL}${imageItem.url}`,
            "title": "",
            "description": ""
          }
        )
      })
      setImageListForLightBox(x)
    }
  }, [sectionInfo]);

  let carouselSettings = {
    dotPosition: "bottom",
    autoPlay: true,
    dots: true,
  };

  const nextSlide = () => {
    slider.current.next();
    if (currentSlide === totalSlide) {
      setCurrentSlide(1);
    } else {
      setCurrentSlide(currentSlide + 1);
    }
  };
  const prevSlide = () => {
    slider.current.prev();
    if (currentSlide <= 1) {
      setCurrentSlide(totalSlide);
    } else {
      setCurrentSlide(currentSlide - 1);
    }
  };


  const handleBeforeCarouselChange = (before, after) => {
    console.log("before:", before + 1, " after: ", after + 1);
    setCurrentSlide(after + 1);
  };

  return (
    <div className="bookstoresection1__container">
      <p className="bookstoresection1__title">{sectionInfo.title}</p>
      <Row gutter={33} className="bookstoresection1__content">
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 15 }}
          xl={{ span: 15 }}
          xxl={{ span: 15 }}
        >
          <div className="bookstoresection1__left-carousel">
            <Carousel
              {...carouselSettings}
              ref={(ref) => {
                slider.current = ref;
              }}
              beforeChange={(b, a) => {
                handleBeforeCarouselChange(b, a);
              }}
            >
              {sectionInfo.image.map((imageItem, index) => {
                return (
                  <div
                    className="bookstoresection1__left-image"
                    key={imageItem.url}
                  >
                    <img src={`${IMAGE_BASE_URL}${imageItem.url}`} alt=""
                      onClick={() => {
                        lightBoxHandler(true, index);
                      }}
                    />
                  </div>
                );
              })}
            </Carousel>
            {/* lightbox for carousel */}
            <LightBoxSimple
              toggle={toggle}
              lightBoxHandler={lightBoxHandler}
              data={imageListForLightBox}
              sIndex={sIndex}
              setSIndex={setSIndex}
            />

          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 9 }}
          xl={{ span: 9 }}
          xxl={{ span: 9 }}
        >
          <div className="ck-content  bookstoresection1__right-description fs-1-1em ">
            {ReactHtmlParser(sectionInfo?.description)}
          </div>

          <a href={`${sectionInfo?.externalLink}`}>
            <p className="bookstoresection1__right-link fs-1-6em lh-1-4em hide-underline-link">
              {sectionInfo?.externalLinkTitle}
            </p>
          </a>
        </Col>
      </Row>
    </div >
  );
}
