
import React, { useState, useEffect } from 'react'

import ImageList from './components/imagelist/ImageList'
import SlideShow from './components/slideshow/SlideShow'
import TextBox from './components/textbox/TextBox'
import SingleImage from './components/singleimage/SingleImage'
import Quote from './components/quote/Quote'

import './styles.css'
function RenderArticleContent(props) {
    const { articleContent } = props

    // for refrence
    // const validSectionTypes = [
    //     'article-sections.single-image',
    //     'article-sections.slide-show',
    //     'article-sections.quote',
    //     'article-sections.text-box',
    //     'article-sections.image-list-horizontal'
    // ]

    return (
        <div className="renderarticlecontent__container text-fabric-serif">
            {
                articleContent.map(articleContentItem => {
                    return (
                        <>
                            <>
                                {articleContentItem.__component === 'article-sections.single-image' ? <SingleImage content={articleContentItem} /> : null}
                                {articleContentItem.__component === 'article-sections.slide-show' ? <SlideShow content={articleContentItem} /> : null}
                                {articleContentItem.__component === 'article-sections.quote' ? <Quote content={articleContentItem} /> : null}
                                {articleContentItem.__component === 'article-sections.text-box' ? <TextBox content={articleContentItem} /> : null}
                                {articleContentItem.__component === 'article-sections.image-list-horizontal' ? <ImageList content={articleContentItem} /> : null}
                            </>
                            <Seperator />
                        </>
                    )
                })
            }
        </div>
    )
}

function Seperator() { return (<div style={{ height: '60px' }}></div>) }

export default RenderArticleContent 