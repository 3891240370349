import React, { useState, useEffect } from "react";
import "./style.css";
import { Row, Col, Button, Form, Input } from "antd";

import { IMAGE_BASE_URL } from "../../../../services/api";
import { useQuery } from "react-query";
import annualReportRequests from "../../../../services/helpers/annualreportquery";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";

import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

// language context
import LocalizationContext from "../../../../localization/LocalizationContext";

function AboutSection5(props) {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  const { about } = props;
  const { isLoading, data, error } = props.queryData;

  //getArtistByID from url
  let queryDataAnnualReport = {
    resource: "annual-reports",
  };
  const getAnnualReportsQueryResult = useQuery(
    ["listAllAnnualReports", queryDataAnnualReport],
    () => annualReportRequests.listAllAnnualReports(queryDataAnnualReport)
  );

  return (
    <div className="aboutsection5__container">
      <Row gutter={42}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <div className="aboutsection5__left-container">
            <div className="aboutsection5__left-title">
              <p>{t("The Board")}</p>
            </div>
            <div className="aboutsection5__left-content">
              <div className="aboutsection5__left-content-block">
                {data &&
                  data.map((boardMember) => {
                    if (!boardMember.is_varaer)
                      return (
                        <p
                          className="aboutsection5__left-content-line fs-1-1em"
                          key={boardMember.name}

                        >
                          {boardMember.name}
                          <br />
                          <span>
                            {appLanguage === "no"
                              ? boardMember.nor_position
                              : null}
                            {appLanguage === "en"
                              ? boardMember.eng_position
                              : null}
                          </span>
                        </p>
                      );
                  })}
              </div>

              <div className="aboutsection5__left-content-block">
                <p className="aboutsection5__left-content-line-2 fs-1-1em">
                  {t("Goods")}:
                </p>
                {data &&
                  data.map((boardMember) => {
                    if (boardMember.is_varaer)
                      return (
                        <p
                          className="aboutsection5__left-content-line-2 fs-1-1em"
                          key={boardMember.id}
                        >
                          {boardMember.name},{" "}
                          {appLanguage === "no"
                            ? boardMember.nor_position
                            : null}
                          {appLanguage === "en"
                            ? boardMember.eng_position
                            : null}
                        </p>
                      );
                  })}
              </div>
            </div>
          </div>
        </Col>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 12 }}
          xl={{ span: 12 }}
          xxl={{ span: 12 }}
        >
          <div className="aboutsection5__right-container">
            <div className="aboutsection5__right-content-block">
              <div className="aboutsection5__right-title">
                <p>{t("Annual Reports")}</p>
              </div>

              <div className="aboutsection5__right-year-list">
                {getAnnualReportsQueryResult.data &&
                  getAnnualReportsQueryResult.data.map((annualReport, index) => {
                    return (
                      <div key={index} >
                        {
                          annualReport.report && (
                            <a
                              href={`${IMAGE_BASE_URL}${annualReport.report.url}`}
                              key={annualReport.id}
                            >
                              <p className="mar-r-10">
                                {annualReport.year && annualReport.year.year}
                              </p>
                            </a>
                          )
                        }
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="aboutsection5__right-content-block">
              <div className="aboutsection5__right-title">
                <p>{t("Purpose")}</p>
              </div>
              <div className="ck-content" style={{ marginTop: "25px" }}>
                {about &&
                  about.aboutDetails &&
                  ReactHtmlParser(about.aboutDetails.purpose)}
              </div>
            </div>

            <div className="aboutsection5__right-content-block">
              <div className="aboutsection5__right-title">
                <p>{t("Articles of Assoiciation")}</p>
              </div>
              <p className="aboutsection5__right-content-link" style={{ marginTop: "25px" }}>
                {about &&
                  about.aboutDetails &&
                  about.aboutDetails.articlesOfAssoiciation && (
                    <a
                      href={`${IMAGE_BASE_URL}${about.aboutDetails.articlesOfAssoiciation.url}`}
                    >
                      {`${about.aboutDetails.articlesOfAssoiciationLinkText}`}
                    </a>
                  )}
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </div >
  );
}

// connect  redux  store
const mapStateToProps = (state) => {
  return {
    about: state.about,
  };
};

export default withRouter(connect(mapStateToProps)(AboutSection5));
