
import qs from 'qs'

// quieries with no dependence on language

export const getSingle = (resource) => {
  return `${resource}`;
};

export const listAll = (resource) => {
  return `${resource}?_sort=published_at:desc`;
};

export const findOne = (resource, id) => {
  return `${resource}/${id}`;
};

export const searchInKeyword = (resource, param, keyword, start, limit) => {
  return `${resource}?${param}_contains=${keyword}&_start=${start}&_limit=${limit}&_sort=published_at:desc`;
};

export const searchKeywordWithoutPagination = (resource, param, keyword) => {
  return `${resource}?${param}_contains=${keyword}&_sort=published_at:desc`;
};

export const findSectionContent = (resource, param, keyword) => {
  return `${resource}?${param}=${keyword}&_sort=published_at:desc`;
};

// Query by language
// l = language
// language === en (english
// these queries below will fetch data from backend that has multilingual settings

export const l_listAll = (resource, language) => {
  return `${resource}?language_eq=${language}&_sort=published_at:desc`;
};

export const l_searchKeywordWithoutPagination_programs = (
  resource,
  language,
  param,
  keyword
) => {
  const query = qs.stringify({
    _sort: 'dateStart:DESC',
    _where: {
      language_eq: language,
      _or: [
        [{ title_contains: keyword }],
        [{ 'artist.name_contains': keyword }],
      ],
    },
  });

  let resourceString = `${resource}?${query}`
  return resourceString
}
export const l_searchKeywordWithoutPagination = (
  resource,
  language,
  param,
  keyword
) => {
  return `${resource}?language_eq=${language}&${param}_contains=${keyword}&_sort=published_at:desc`;
};

export const l_searchKeywordWithPagination = (
  resource,
  language,
  param,
  keyword,
  start,
  limit
) => {
  return `${resource}?language_eq=${language}&${param}_contains=${keyword}&_start=${start}&_limit=${limit}&_sort=published_at:desc`;
};

// special query to get triangle by datw
export const l_searchTriangleKeywordWithPagination = (
  resource,
  language,
  param,
  keyword,
  start,
  limit
) => {
  return `${resource}?language_eq=${language}&${param}_contains=${keyword}&_start=${start}&_limit=${limit}&_sort=date:desc`;
};


export const l_getSectionContent = (resource, language, param, keyword) => {
  return `${resource}?language_eq=${language}&${param}=${keyword}&_sort=published_at:desc`;
};
