import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// import slices
import aboutSlice from "./features/about/aboutSlice";

const aboutPersistConfig = {
  key: "about",
  storage: storage,
};

const appReducer = combineReducers({
  about: persistReducer(aboutPersistConfig, aboutSlice),
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};

export default rootReducer;
