import api from "../api";
import to from "await-to-js";
import {
  listAll,
  findOne,
  findSectionContent,
  getSingle,
} from "../queryGenerator";
import moment from "moment";
import qs from 'qs'


const artistsRequests = {
  getArtistInResidencySection1: async (queryData) => {
    // console.log(queryData);
    let resourceString = await getSingle(queryData.resource);
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  // current artist is a featured artist
  // current set to true in the backend
  getCurrentArtist: async (queryData) => {
    // console.log(queryData);

    let resourceString = await findSectionContent(
      queryData.resource,
      queryData.param,
      queryData.string
    ); // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  getArtistdyID: async (queryData) => {
    // console.log(queryData);
    let resourceString = await findOne(queryData.resource, queryData.id);

    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  listAllArtists: async (queryData) => {
    // console.log(queryData);
    let resourceString = await listAll(queryData.resource);
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  // artist listing 3 types
  // former artists , these artists have end date in past
  getFormerArtists: async (queryData) => {
    let todayDate = moment().format("YYYY-MM-DD");

    let resourceString = `${queryData.resource}?isArtistInResidency=true&${queryData.param}_contains=${queryData.string}&_start=${queryData.start}&_limit=${queryData.limit}&dateEnd_lt=${todayDate}`;

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  // current artist, these artists have end date in future
  // and the year is same as the current year
  getCurrentArtists: async (queryData) => {
    let todayDate = moment().format("YYYY-MM-DD");
    let thisYear = moment().format("YYYY");

    let resourceString = `${queryData.resource}?isArtistInResidency=true&year.year_eq=${thisYear}`;
    // let resourceString = `${queryData.resource}?year_eq=${thisYear}&dateEnd_gte=${todayDate}`;

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  // upcoming artist is the list of artist of next year
  getUpcomingArtists: async (queryData) => {
    let todayDate = moment().format("YYYY-MM-DD");
    let thisYear = moment().format("YYYY");
    let nextYear = moment().add(1, "years").format("YYYY");

    let query = qs.stringify({
      _sort: `dateStart:ASC`,
      _where: {
        dateStart_gt: todayDate,
        isArtistInResidency: false
      },
    });

    let resourceString = `artists?${query}`
    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    if (error) {
      throw error
    }
    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },

  // special query
  // single type
  getArtistApplyInfo: async (queryData) => {
    let resourceString = await getSingle(queryData.resource);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
};

export default artistsRequests;
