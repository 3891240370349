import axios from "axios";

import { createHashHistory } from "history"; // or createBrowserHistory
const history = createHashHistory();

// API CONSTANTS ( URLs)
//for local
// export const APP_BASE_URL = "http://localhost:1337";
// export const IMAGE_BASE_URL = "http://localhost:1337";

// for test server:
//export const APP_BASE_URL = "https://hks-test-backend.herokuapp.com";

// Configuration for CI: Application will get APP_BASE_URL from environment:
export const APP_BASE_URL = process.env.REACT_APP_APP_BASE_URL;
export const IMAGE_BASE_URL = "";

// const getToken = () => {
//   // token fetching here...
//   return token;
// };

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    // maybe reroute  to 404 page
    // history.push("/404");

    console.log("interceptor error", error);

    if (error.response && error.response.status === 401) {
      console.log("401 error", error.response.data);
      throw error.response.data;
    }
    if (error.response && error.response.status === 400) {
      console.log("400 error", error.response.data);
      throw error.response.data;
    }
    if (error.response && error.response.status === 404) {
      console.log("404 error", error.response.data);
      throw error.response.data;
    }
    throw error;
  }
);

const api = {
  GETPUBLIC_QUERY: async (resource) => {
    // console.log(resource);
    try {
      return await axios.get(APP_BASE_URL + "/" + resource);
    } catch (error) {
      throw await error;
    }
  },
};

export default api;
