import React, { useState, useEffect } from 'react'
import { Row, Col } from 'antd'

import { IMAGE_BASE_URL } from "../../../../../services/api";
import { LightBoxSimple } from "../../../lightboxsimple/LightBoxSimple";

import './styles.css'

function ImageList(props) {
    const { content } = props
    // const imageList = content && content.images && content.images.slice(0, 2)
    let [captionList, setCaptionList] = useState([]);

    const [imageListForLightBox, setImageListForLightBox] = useState([])
    const [toggle, setToggle] = useState(false);
    const [sIndex, setSIndex] = useState(0);
    // 
    let [totalSlide, setTotalSlide] = useState(0);


    // Handler
    const lightBoxHandler = (state, sIndex) => {
        setToggle(state);
        setSIndex(sIndex);
    };

    useEffect(() => {
        if (content?.images?.length) {
            setTotalSlide(content?.images?.length);
            let x = []
            // prepare the image list 
            content?.images?.map((imageItem, index) => {
                x.push(
                    {
                        "id": index,
                        "image": `${IMAGE_BASE_URL}${imageItem.url}`,
                        "title": "",
                        "description": ""
                    }
                )
            })
            setImageListForLightBox(x)
        }
    }, [content]);


    useEffect(() => {
        if (content && content.captions?.length) {
            setCaptionList(content.captions.split("#"));
        }
    }, [content]);

    return (
        <div className="content-container-wrapper">
            <div className="content-container-content">
                <div className="imagelist__container">
                    <Row align="middle" gutter={[20, 20]}>
                        {
                            imageListForLightBox && imageListForLightBox.length > 0 && imageListForLightBox.map((imageItem, index) => {
                                return (<Col
                                    xs={{ span: 24 }}
                                    sm={{ span: 24 }}
                                    md={{ span: 24 }}
                                    lg={{ span: 12 }}
                                    xl={{ span: 12 }}
                                    xxl={{ span: 12 }}
                                >
                                    <div className="imagelist__image">
                                        <img
                                            src={`${imageItem.image}`}
                                            alt=""
                                            onClick={() => {
                                                lightBoxHandler(true, index);
                                            }}
                                        />
                                        <p className='fs-1em'>
                                            {" "}
                                            {captionList.length > 0 ? captionList[index] : null}
                                        </p>
                                    </div>
                                </Col>)
                            })
                        }
                    </Row>
                    {/* lightbox for carousel */}
                    <LightBoxSimple
                        toggle={toggle}
                        lightBoxHandler={lightBoxHandler}
                        data={imageListForLightBox}
                        sIndex={sIndex}
                        setSIndex={setSIndex}
                    />
                </div>
            </div>
        </div>
    )
}

export default ImageList