import React from "react";
import "./styles.css";

import { Row, Col } from "antd";
import { Link } from "react-router-dom";

import ReactHtmlParser from "react-html-parser";

export default function ArtAndPublicSection1(props) {
  const { queryData, appLanguage } = props;

  return (
    <div className="artandpublicsection1__container">
      <Row gutter={12}>
        {/* {data && ( */}
        <>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 16 }}
            xl={{ span: 16 }}
            xxl={{ span: 16 }}
          >
            <div className="artandpublicsection1__content">
              <p className="artandpublicsection1__content-title fs-2-5em lh-1-2em">
                {appLanguage === "en" && queryData.eng_title}
                {appLanguage === "no" && queryData.nor_title}
              </p>
              <p className="artandpublicsection1__content-description lh-1-4em">
                {appLanguage === "en" &&
                  ReactHtmlParser(queryData.eng_description)}
                {appLanguage === "no" &&
                  ReactHtmlParser(queryData.nor_description)}
              </p>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <div className="artandpublicsection1__right">
              <p className="artandpublicsection1__right-more">
                {appLanguage === "en" && <a href={queryData.eng_linkUrl}>
                  <span className="artandpublicsection1__right-more-text fs-2-5em lh-1-2em hide-underline-link">
                    {queryData.eng_linkText}
                  </span>
                </a>
                }
                {appLanguage === "no" && <a href={queryData.nor_linkUrl}>
                  <span className="artandpublicsection1__right-more-text fs-2-5em lh-1-2em hide-underline-link">
                    {queryData.nor_linkText}
                  </span>
                </a>
                }
              </p>
            </div>
          </Col>
        </>
        {/* )} */}
      </Row>
    </div>
  );
}
