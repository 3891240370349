import React, { useState, useEffect } from 'react'

import './styles.css'
import ReactHtmlParser from "react-html-parser";

function Quote(props) {
    const { content } = props

    return (
        <div className="content-container-wrapper">
            {/* <div className="content-container-content"> */}
            <div className="quote__content">
                <div className="ck-content quote__text fs-1-8em lh-1-2em">
                    {ReactHtmlParser(content.quoteText)}
                </div>
                <div className="quote__author">
                    {content.author}
                </div>

            </div>
            {/* </div> */}
        </div>
    )
}

export default Quote