import React, { useEffect, useState } from "react";
import "./style.css";
import { IMAGE_BASE_URL } from "../../../../services/api";

import { Row, Col, Button, Form, Input } from "antd";
import { Link } from "react-router-dom";

// language context
import LocalizationContext from "../../../../localization/LocalizationContext";

export default function AboutSection4(props) {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  const { isLoading, data, error } = props.queryData;
  const [filteredDataList, setFilteredDataList] = useState([])
  // console.log(data);

  useEffect(() => {
    let x = data.sort(function (a, b) { return a.priority - b.priority });
    setFilteredDataList(x)
  }, [data])

  return (
    <div className="aboutsection4__container">
      <div className="aboutsection4__title-section">
        <p>{t("Employees")}</p>
      </div>

      <div className="aboutsection4__image-grid-container">
        {filteredDataList.length > 0 && (
          <Row gutter={62} gutter={[{ xs: 0, sm: 0, md: 0, lg: 62 }]}>
            {data.map((emp) => {
              return (
                <Col
                  key={emp.id}
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 6 }}
                  xl={{ span: 6 }}
                  xxl={{ span: 6 }}
                >
                  <div className="aboutsection4__image-item">
                    <div className="aboutsection4__image-grid-image">
                    {emp.profileImg && (
                          <img src={`${IMAGE_BASE_URL}${emp.profileImg.url}`} />
                        )}
                    </div>
                    <p className="aboutsection4__image-grid-name">
                        {emp.name}
                      </p>
                    <p className="aboutsection4__image-grid-position">
                      {appLanguage === "no" ? emp.nor_position : null}
                      {appLanguage === "en" ? emp.eng_position : null}
                    </p>
                    <p className="aboutsection4__image-grid-email">
                      {emp.email}
                    </p>
                  </div>
                </Col>
              );
            })}
          </Row>
        )}
      </div>
    </div>
  );
}
