import React, { useEffect } from "react";

import "./styles.css";

import {
  CloseOutlined,
  InstagramOutlined,
  FacebookOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import { Menu, Row, Col, Button } from "antd";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { useDispatch, connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import LocalizationContext from "../../../localization/LocalizationContext";

import { Footer, LineSeperator } from "../UiComponents";
const { SubMenu } = Menu;

function HamMenu(props) {
  const { about } = props;

  const { t, i18n } = React.useContext(LocalizationContext);

  // console.log(i18n.language);
  // close the nav
  const closeHamMenu = () => {
    window.closeNav();
  };

  useEffect(() => {
    if (!localStorage.getItem('hks_i18nextLng')) {
      i18n.changeLanguage("no");
      localStorage.setItem("hks_i18nextLng", "no");
    }
  }, [])

  const setLanguageToEng = () => {
    i18n.changeLanguage("en");
  };
  const setLanguageToNor = () => {
    i18n.changeLanguage("no");
  };

  return (
    <div>
      <div id="mySidepanel" className="sidepanel text-fabric-serif">
        <div className="content-container-wrapper">
          <div className="content-container-content">
            <div className="sidepanel__header">
              <div className="mainnavbar__site-title text-fabric-sans">
                <Link to="/">
                  {t("Hordaland")} {t("Kunstsenter")}
                </Link>
              </div>
              <div className="sidepanel__header__ham-menu-icon">
                {
                  <CloseOutlined
                    className="closebtn"
                    onClick={closeHamMenu}
                  />
                }
              </div>
            </div>

            <Row gutter={22}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 14 }}
                xl={{ span: 14 }}
                xxl={{ span: 14 }}
              >
                <div className="sidepanel__content">
                  <Menu
                    onClick={closeHamMenu}
                    // style={{ width: "100%" }}
                    // defaultSelectedKeys={["1"]}
                    // defaultOpenKeys={["sub1"]}
                    mode="inline"
                  >
                    {/* <Menu.Item>
                      <Link to="/" />
                      {t("Home")}
                    </Menu.Item> */}
                    <Menu.Item>
                      <Link to="/program" />
                      {t("Program")}
                    </Menu.Item>
                    <Menu.Item>
                      <Link to="/artandpublic" />
                      {t(["Art and the Public"])}
                    </Menu.Item>
                    <Menu.Item>
                      <Link to="/possibilities" />
                      {t("Opportunities")}
                    </Menu.Item>
                    <Menu.Item>
                      <Link to="/artistinresidency" />
                      {t(["Artist In Residency"])}
                    </Menu.Item>
                    <Menu.Item>
                      <Link to="/triangle" />
                      {t("Triangle")}
                    </Menu.Item>
                    <Menu.Item>
                      <Link to="/bookstore" />
                      {t("Bookshop + Cafe")}
                    </Menu.Item>
                    <Menu.Item>
                      <Link to="/about" />
                      {t("About")}/{t("Contact")}
                    </Menu.Item>
                    <Menu.Item>
                      <Link to="/search" />
                      {t("Search")}
                    </Menu.Item>
                  </Menu>
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 10 }}
                xl={{ span: 10 }}
                xxl={{ span: 10 }}
              >
                <div className="sidepanel__content-right text-fabric-sans">
                  {about.aboutDetails && (
                    <div className="sidepanel__content-right-top d-only-lg">
                      <div className="sidepanel__content-right__line1">
                        <p className="sidepanel__content-right__title fs-1-2em">
                          {t("Opening Hours")}
                        </p>

                        <div className="sidepanel__content-right__text ck-content">
                          <div className="ck-content fs-1-2em">
                            {ReactHtmlParser(about.aboutDetails.openingHours)}
                          </div>
                        </div>
                      </div>
                      <div className="sidepanel__content-right__line1">
                        <p className="sidepanel__content-right__title fs-1-2em">
                          {t("Entrance")}:
                        </p>
                        <div className="sidepanel__content-right__text ck-content">
                          <div className="ck-content fs-1-2em">
                            {ReactHtmlParser(about.aboutDetails.entrance)}
                          </div>
                        </div>
                      </div>
                      <div className="sidepanel__content-right__line1">
                        <p className="sidepanel__content-right__title fs-1-2em">
                          {t("Contact")}:
                        </p>
                        <div className="sidepanel__content-right__text ck-content">
                          <div className="ck-content fs-1-2em">
                            {ReactHtmlParser(about.aboutDetails.contact)}
                          </div>
                        </div>
                      </div>
                      <div className="sidepanel__content-right__line1">
                        <div className="sidepanel__content-right__text ck-content">
                          <div className="ck-content fs-1-2em">
                            {ReactHtmlParser(about.aboutDetails.address)}
                          </div>
                        </div>
                      </div>
                      <div className="sidepanel__content-right-icons ">
                        <a href={`${about.aboutDetails.instagram}`}>
                          <i className="fab fa-instagram"></i>
                          {/* <InstagramOutlined /> */}
                        </a>
                        <a href={`${about.aboutDetails.facebook}`}>
                          {/* <FacebookOutlined /> */}
                          <i className="fab fa-facebook-f"></i>
                        </a>
                        <a href={`${about.aboutDetails.youtube}`}>
                          {/* <YoutubeOutlined /> */}
                          <i className="fab fa-youtube"></i>
                        </a>
                      </div>
                    </div>
                  )}
                  <div className="sidepanel__content-right-bottom">
                    <p
                      className={
                        "sidepanel__content-right-bottom-translate " +
                        (i18n.language === "no"
                          ? "sidepanel__content-right-b-t-active"
                          : "")
                      }
                      onClick={setLanguageToNor}
                    >
                      NOR
                    </p>

                    <p className="sidepanel__content-right-bottom-translate">
                      /
                    </p>
                    <p
                      className={
                        "sidepanel__content-right-bottom-translate " +
                        (i18n.language === "en"
                          ? "sidepanel__content-right-b-t-active"
                          : "")
                      }
                      onClick={setLanguageToEng}
                    >
                      ENG
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="d-only-sm">
          <LineSeperator />
          <Footer />
        </div>
      </div>
    </div>
  );
}

// connect  redux  store
const mapStateToProps = (state) => {
  return {
    about: state.about,
  };
};

export default withRouter(connect(mapStateToProps)(HamMenu));
