import React, { useState, useEffect } from "react";
import "./styles.css";

import { Row, Col } from "antd";
import { Link } from "react-router-dom";
// language context
import LocalizationContext from "../../../../localization/LocalizationContext";

import { IMAGE_BASE_URL } from "../../../../services/api";
import { formatDate } from "../../../helper/formatDate";

export default function RelatedProjects({ data }) {
  // const { data, isLoading } = props.queryData;

  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  let [gridDataList, setGridDataList] = useState([]);

  const [showHoverEffect, setShowHoverEffect] = useState(false)
  const [showHoverEffectId, setShowHoverEffectId] = useState(null)

  const onHoverStyleText = {
    textDecoration: "underline"
  }
  const onHoverStyleImage = {
    opacity: 0.8
  }
  const onMouseHoverHeadingOrImage = (id) => {
    setShowHoverEffectId(id)
    setShowHoverEffect(true)
  }
  const onMouseRemoveHeadingOrImage = (id) => {
    setShowHoverEffectId(null)
    setShowHoverEffect(false)
  }

  useEffect(() => {

    if (data && appLanguage) {
      console.log(data);
      let x = data.filter((item) => {
        return item.language === appLanguage;
      });
      let y = x && x.slice(0, 4);
      setGridDataList(y);
    }
  }, [data, appLanguage]);

  let getColVal = (layoutString, baseCol) => {
    if (layoutString === "ONE") {
      return baseCol;
    } else if (layoutString === "TWO") {
      return baseCol;
      // return baseCol * 2;
    } else if (layoutString === "THREE") {
      return baseCol;
      // return baseCol * 3;
    } else return baseCol;
  };

  console.log(gridDataList)
  return (
    <div className="programpagesection3__container">
      {gridDataList && gridDataList.length > 0 && (
        <>
          <p className="programpagesection3__title text-fabric-sans">{t("Related Content")}</p>

          <Row gutter={35}>
            {gridDataList.map((item, index) => {
              return (
                <Col
                  key={index}
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: `${getColVal(item.layoutColumn, 6)}` }}
                  xl={{ span: `${getColVal(item.layoutColumn, 6)}` }}
                  xxl={{ span: `${getColVal(item.layoutColumn, 6)}` }}
                  style={{ marginBottom: "35px" }}
                  flex=""
                >
                  <div className="programgrid__program-info-container">
                    <div className="programgrid__program-image">
                      <Link to={`/program/${item.id}`}>
                        <div className="programgrid__image-container">
                          {item.mainImg && (
                            <img src={`${IMAGE_BASE_URL}${item.mainImg.url}`}
                              style={showHoverEffect && showHoverEffectId === item.id ? { ...onHoverStyleImage } : null}
                              onMouseOver={() => onMouseHoverHeadingOrImage(item.id)}
                              onMouseOut={() => onMouseRemoveHeadingOrImage(item.id)}
                            />
                          )}
                        </div>
                      </Link>
                    </div>
                    <p className="programgrid__program-info-date">
                      {formatDate(item.dateStart)} - {formatDate(item.dateEnd)}
                    </p>
                    {/* <p className="programgrid__program-info-artist-name">
                    {data[0].name}
                  </p> */}
                    <Link to={`/program/${item.id}`}>
                      <p className="programgrid__program-info-program-title fs-1-4em lh-1-2em"
                        style={showHoverEffect && showHoverEffectId === item.id ? { ...onHoverStyleText } : null}
                        onMouseOver={() => onMouseHoverHeadingOrImage(item.id)}
                        onMouseOut={() => onMouseRemoveHeadingOrImage(item.id)}
                      >
                        {item.title}
                      </p>
                    </Link>
                  </div>
                </Col>
              );
            })}
          </Row>
        </>
      )}
    </div>
  );
}
