import React, { useState } from "react";
import "./styles.css";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import { TagsDisplay } from "../../UiComponents";
import { formatDate } from "../../../helper/formatDate";
import { IMAGE_BASE_URL } from "../../../../services/api";


export default function PreviousProgramsDisplay(props) {
  const { filteredData, pagination, handlePagination, t } = props;

  // display image on hover over title [state]
  const [displayImagePreviewOnHover, setDisplayImagePreviewOnHover] = useState(null)

  const handleShowOnHoverOverTitle = (id) => {
    setDisplayImagePreviewOnHover(id)
  }
  const handleHideOnHoverOverTitle = (id) => {
    setDisplayImagePreviewOnHover(null)
  }

  return (
    <div className="previousprogramsdisplay__container">
      {filteredData && filteredData.length < 1 ? <p className="mar-t-10">{"No Results"}</p> :
        <>
          {filteredData &&
            filteredData.map((item) => {
              return (
                <Row className="previousprogramsdisplay__content-row" type="flex">
                  <Col span={18} className="previousprogramsdisplay__content-text">
                    <div className="previousprogramsdisplay__program-info">
                      <p className="previousprogramsdisplay__program-info-line1">
                        {formatDate(item.dateStart)} - {formatDate(item.dateEnd)}
                      </p>

                      <p className="previousprogramsdisplay__program-info-line2 fs-1-6em lh-1-2em">
                        {item.artist && item.artist.name}
                      </p>
                      <Link to={`/program/${item.id}`}
                        onMouseOver={() => { handleShowOnHoverOverTitle(item.id) }}
                        onMouseOut={() => { handleHideOnHoverOverTitle(item.id) }}
                      >
                        <p className="previousprogramsdisplay__program-info-line3 fs-1-6em lh-1-2em text-fabric-sans-italic">
                          {item.title}
                        </p>
                      </Link>
                    </div>

                    <TagsDisplay tagList={item.tags} />
                  </Col>
                  <Col span={6}>
                    <div className="previousprogramsdisplay__content-row-image"  >
                      {
                        // true &&
                        (displayImagePreviewOnHover === item.id) &&
                        <>
                          {item.mainImg && item.mainImg.url && <img alt={`${item.title}`} src={`${IMAGE_BASE_URL}${item.mainImg.url}`} />}
                        </>
                      }
                    </div>

                  </Col>
                </Row>
              );
            })}

          <>
            {pagination.isNextPageAvailable ? (
              <div className="content__load-more">
                <Button
                  className="content__load-more-button"
                  onClick={handlePagination}
                >
                  {t("Load More")}
                </Button>
              </div>
            ) : (
              <div className="content__load-more">
                <p className="content__load-more-text">{t("No more results")}</p>
              </div>
            )}
          </>
        </>
      }
    </div>
  );
}
