import React from "react";

import {
  MainNavBar,
  Footer,
  PossibilitiesSection1,
  PossibilitiesSearch,
  PossibilitiesMandate,
  AnnouncementBar,
  LineSeperator,
} from "../../UIcomponents/UiComponents";

import { updatePageHead } from "../../../common/UpdateMetaAndTitle";
import { useQuery } from "react-query";
import opportunitiesRequests from "../../../services/helpers/opportunitiesquery";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";

export default function Possibilities() {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  updatePageHead(`${t("Opportunities")}`, "", "");

  //getsection1
  //featured opportunity
  let queryDataSection1 = {
    language: appLanguage,
    resource: "opportunities",
    param: "if_possibilities",
    string: "true",
  };
  const possibilitiesSection1QueryResult = useQuery(
    ["getFeaturedOpportunity", queryDataSection1],
    () => opportunitiesRequests.getFeaturedOpportunity(queryDataSection1)
  );

  return (
    <>
      <MainNavBar
        pageTitle={`${t("Opportunities")}`}
        bgClass={"bg-highlight"}
      />

      <div className="content-container-wrapper bg-highlight">
        <div className="content-container-content">
          <PossibilitiesSection1 queryData={possibilitiesSection1QueryResult} />
          <PossibilitiesSearch />
          {/* <PossibilitiesMandate /> */}
        </div>
      </div>
      <LineSeperator />
      <Footer />
    </>
  );
}
