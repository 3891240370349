import React from "react";

import {
  MainNavBar,
  Footer,
  ArtAndPublicSection1,
  ArtAndPublicSection2,
  LineSeperator,
} from "../../UIcomponents/UiComponents";

import { updatePageHead } from "../../../common/UpdateMetaAndTitle";
import { useQuery } from "react-query";
import artAndPublicRequests from "../../../services/helpers/artandpublicquery";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";

export default function ArtAndPublic(props) {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  //update page head
  updatePageHead(`${t("Art and the Public")}`, "", "");

  // get section 1
  let queryDataS1 = {
    resource: "art-and-public-s-1",
  };

  const artAndPublicSection1QueryResult = useQuery(
    ["getArtAndPublicSection1", queryDataS1],
    () => artAndPublicRequests.getArtAndPublicSection1(queryDataS1)
  );

  // get section 2
  let queryData = {
    language: appLanguage,
    resource: "art-and-publics",
  };

  const artAndPublicSection2QueryResult = useQuery(
    ["listAllArtAndPublicSections", queryData],
    () => artAndPublicRequests.listAllArtAndPublicSections(queryData)
  );

  // console.log(artAndPublicSection2QueryResult);
  return (
    <>
      <MainNavBar pageTitle="Art and the Public" bgClass={"bg-highlight"} />

      <div className="content-container-wrapper bg-highlight">
        <div className="content-container-content">
          {/* section 1 */}
          {artAndPublicSection1QueryResult.isLoading && <p>{t("Loading")}...</p>}

          {artAndPublicSection1QueryResult.data && (
            <ArtAndPublicSection1
              queryData={artAndPublicSection1QueryResult.data}
              appLanguage={appLanguage}
            />
          )}

          {/* map them  */}
          {artAndPublicSection2QueryResult.isLoading && <p>{t("Loading")}...</p>}

          {artAndPublicSection2QueryResult.data &&
            artAndPublicSection2QueryResult.data.map((sectionInfo) => {
              return (
                <ArtAndPublicSection2
                  key={sectionInfo.id}
                  sectionInfo={sectionInfo}
                  t={t}
                />
              );
            })}

          {/* <ArtAndPublicSection3 />
          <ArtAndPublicSection4 /> */}

          {/* {artAndPublicSection5QueryResult.data && (
            <ArtAndPublicSection5 queryData={artAndPublicSection5QueryResult} />
          )}

          {listProgramsArtAndPublicQueryResult.data && (
            <ArtAndPublicSection6
              queryData={listProgramsArtAndPublicQueryResult}
            />
          )} */}
        </div>
      </div>
      <LineSeperator />
      <Footer />
    </>
  );
}
