import React, { useState, useEffect } from 'react'

import ReactHtmlParser from "react-html-parser";
import './styles.css'

function TextBox(props) {
    const { content } = props
    return (
        <div className="content-container-wrapper">
            <div className="content-container-content">
                <div className="textbox__container">
                    <div className="ck-content fs-1-1em lh-1-1em">
                        {ReactHtmlParser(content.text)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TextBox