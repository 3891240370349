import React, { useEffect, useState } from "react";
import "./style.css";
import { Row, Col, Button, Form, Input } from "antd";

import ReactHtmlParser from "react-html-parser";

import { Link } from "react-router-dom";
import { formatDate } from "../../../helper/formatDate";
export default function ArtistInResidencySection3(props) {
  const { isLoading, data, error } = props.queryData;
  const { appLanguage, t } = props;

  let [displayData, setDisplayData] = useState({
    title: null,
    description: null,
    deadline: null,
    link: null,
  });
  useEffect(() => {
    if (appLanguage === "en") {
      setDisplayData({
        title: data.eng_title,
        description: data.engDescription,
        deadline: data.deadline,
        link: data.link,
      });
    } else if (appLanguage === "no") {
      setDisplayData({
        title: data.nor_title,
        description: data.norDescription,
        deadline: data.deadline,
        link: data.link,
      });
    } else {
      setDisplayData({
        title: null,
        description: null,
        deadline: null,
        link: null,
      });
    }
  }, [appLanguage]);

  return (
    <div className="possibilitiessection1__container">
      <Row gutter={12}>
        <>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 16 }}
            xl={{ span: 16 }}
            xxl={{ span: 16 }}
          >
            <div className="possibilitiessection1__content">
              <p className="possibilitiessection1__content-title fs-2-5em lh-1-2em">
                {displayData.title}
              </p>
              <div className="possibilitiessection1__content-description">
                <div className="ck-content">
                  {ReactHtmlParser(displayData.description)}
                </div>
              </div>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <div className="possibilitiessection1__right">
              <p className="possibilitiessection1__right-deadline fs-2-5em lh-1-2em">
                {t("Application Deadline")}: <br />{" "}
                {formatDate(displayData.deadline)}
              </p>
              <p className="possibilitiessection1__right-more fs-2-5em lh-1-2em">
                <a href={`${displayData.link}`}>
                  <span className="possibilitiessection1__right-more-text hide-underline-link">
                    {t("Apply Now")} →
                  </span>
                </a>
              </p>
            </div>
          </Col>
        </>
      </Row>
    </div>
  );
}
