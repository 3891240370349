import React, { useState, useEffect } from "react";

import { Row, Col, Button, Input } from "antd";
import {
  CloseOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";

import PossibilitiesSearchDisplay from "./PossibilitiesSearchDisplay";

import { useQuery } from "react-query";
import opportunitiesSearchRequests from "../../../../services/helpers/search/opportunities-search";
import tagsRequests from "../../../../services/helpers/tagsquery";

// language context
import LocalizationContext from "../../../../localization/LocalizationContext";

export default function PossibilitiesSearch() {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  //
  let [filterType, setFilterType] = useState(null);
  let [showFilterBar, setShowFilterBar] = useState(false);

  let [currentSorting, setCurrentSorting] = useState('DESC')

  let [tagList, setTagList] = useState(null);
  let [yearList, setYearList] = useState(null);
  //
  let [searchKeyword, setSearchKeyword] = useState("");
  let [selectedYear, setSelectedYear] = useState(null)
  let [selectedTag, setSelectedTag] = useState(null)

  let [totalData, setTotalData] = useState([]);
  let [pagination, setPagination] = useState({
    start: 0,
    limit: 4,
    step: 4,
    page: 1,
    isNextPageAvailable: true,
  });

  // list all tags by type program
  let queryDataGetYears = {
    resource: "years",
  };
  const yearListQuery = useQuery(["getAllYears", queryDataGetYears], () =>
    tagsRequests.getAllYears(queryDataGetYears)
  );

  let queryDataGetTags = {
    resource: "tag-types",
    param: "type",
    string: "opportunities_tags",
    language: i18n.language
  };
  const opportunitiesTags = useQuery(
    ["opportunitiesTags", queryDataGetTags],
    () => tagsRequests.getTagTypeByName(queryDataGetTags)
  );
  useEffect(() => {
    let tempTagList = [];
    if (opportunitiesTags.data && opportunitiesTags.data.length > 0) {
      let x = opportunitiesTags.data[0].tags;
      // console.log(x);
      let _filteredTagsByLanguage = x.filter((tagItem) => tagItem.language === i18n.language)
      tempTagList = _filteredTagsByLanguage.map((tagItem) => {
        return tagItem.tagName;
      });
    }
    setTagList(tempTagList);
  }, [opportunitiesTags.data]);

  // effect to set the lsit  of  all  years
  //
  useEffect(() => {
    let tempYearList = [];
    if (yearListQuery.data && yearListQuery.data.length > 0) {
      let x = yearListQuery.data;
      // console.log(x);
      tempYearList = x.map((item) => {
        return item.year;
      });
    }
    setYearList(tempYearList);
  }, [yearListQuery.data]);

  useEffect(() => {
    filterForKeyword('')
  }, [])


  // clear filter
  let clearFilter = () => {
    setSearchKeyword('')
    setCurrentSorting('DESC')
    setSelectedYear(null)
    setSelectedTag(null)
  };
  // pagination
  let handlePagination = () => {
    let paginateData = [...totalData]

    // clearFilter();
    let totalrows = paginateData.length;
    let x = pagination;
    if (x.isNextPageAvailable) {
      setPagination({
        ...x,
        start: x.start,
        limit: x.limit + x.step,
        page: x.page + 1,
      });
    }
    if (totalrows < x.limit) {
      setPagination({
        ...x,
        isNextPageAvailable: false,
      });
    }
  };

  const closeShowFilterBar = () => {
    setShowFilterBar(false);
    setFilterType(null)
  };
  const showFilterBarSection = () => {
    setShowFilterBar(true);
  };


  const filterForKeyword = () => {
    let queryData = {
      lang: i18n.language,
      string: searchKeyword,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    };
    let response = opportunitiesSearchRequests.searchOpportunities(queryData, 'keyword')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForTag = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    };
    let response = opportunitiesSearchRequests.searchOpportunities(queryData, 'tag')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForYear = () => {
    // query based on year
    let queryData = {
      lang: i18n.language,
      param: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = opportunitiesSearchRequests.searchOpportunities(queryData, 'year')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForKeywordAndYear = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedYear,
      string: searchKeyword,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = opportunitiesSearchRequests.searchOpportunities(queryData, 'keywordYear')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForKeywordAndTag = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      string: searchKeyword,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = opportunitiesSearchRequests.searchOpportunities(queryData, 'keywordTag')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForTagAndYear = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      secondParam: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = opportunitiesSearchRequests.searchOpportunities(queryData, 'tagYear')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForKeywordAndTagAndYear = () => {
    let queryData = {
      lang: i18n.language,
      string: searchKeyword,
      param: selectedTag,
      secondParam: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = opportunitiesSearchRequests.searchOpportunities(queryData, 'keywordTagYear')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }

  const initiateSearch = () => {
    // usecases
    if (searchKeyword.length > 0) {
      if (!selectedTag && !selectedYear) {
        filterForKeyword()
      }
      if (!selectedTag && selectedYear) {
        filterForKeywordAndYear()
      }
      if (selectedTag && !selectedYear) {
        filterForKeywordAndTag()
      }
      if (selectedTag && selectedYear) {
        filterForKeywordAndTagAndYear()
      }
    } else {
      if (selectedTag && !selectedYear) {
        filterForTag()
      }
      if (!selectedTag && selectedYear) {
        filterForYear()
      }
      if (selectedTag && selectedYear) {
        filterForTagAndYear()
      }
      if (!selectedTag && !selectedYear) {
        filterForKeyword()
      }
    }
  }
  useEffect(() => {
    initiateSearch()
  }, [selectedTag, selectedYear, searchKeyword, currentSorting, pagination.limit, i18n.language])

  return (
    <>
      <>
        <div>
          <div className="possibilitiessearch__container">
            <Row>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
              >
                <p className="possibilitiessearch__title">
                  {t("Current opportunities")}:{" "}
                </p>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
              >
                <div className="possibilitiessearch__input flex-row-justify-space-between ">
                  <Input
                    className="placeholder-f-s-1-2em"
                    placeholder={`${t("Search")}`}
                    onPressEnter={(e) => {
                      setSearchKeyword(e.target.value.trim());
                    }}
                    value={searchKeyword}
                    onChange={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                  />
                  <div className="flex-row-align-items-center">
                    <Button
                      className={
                        filterType === "tag"
                          ? "search-filter-active"
                          : "search-filter"
                      }
                      onClick={() => {
                        if (filterType === "tag") {
                          closeShowFilterBar();
                          return
                        }
                        showFilterBarSection();
                        setFilterType("tag");
                      }}
                    >
                      {t("Filter")}
                    </Button>
                    <Button
                      className={
                        filterType === "year"
                          ? "search-filter-active mar-l-10"
                          : "search-filter mar-l-10"
                      }
                      onClick={() => {
                        if (filterType === "year") {
                          closeShowFilterBar();
                          return
                        }
                        showFilterBarSection();
                        setFilterType("year");
                      }}
                    >
                      {t("Year")}
                    </Button>

                    <span className="mar-l-10 fs-1-3em">
                      <DownOutlined onClick={() => { setCurrentSorting('DESC') }}
                        className={currentSorting === 'DESC' ? 'sort-btn-active' : 'sort-btn'}
                      />
                    </span>
                    <span className="fs-1-3em mar-l-10 ">
                      <UpOutlined onClick={() => { setCurrentSorting('ASC') }}
                        className={currentSorting === 'ASC' ? 'sort-btn-active' : 'sort-btn'}
                      />
                    </span>

                  </div>
                </div>
              </Col>
              {showFilterBar && (
                <>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}
                    xl={{ span: 24 }}
                    xxl={{ span: 24 }}
                  >
                    {filterType === "tag" && (
                      <div className="flex-row-justify-space-between">
                        <div className="tag-list">
                          {tagList.map((tag) => {
                            return (
                              <p
                                key={tag}
                                className={
                                  selectedTag === tag
                                    ? "tag-item-active mar-b-10"
                                    : "tag-item mar-b-10"
                                }
                                onClick={() => {
                                  if (selectedTag === tag) {
                                    setSelectedTag(null)
                                    return
                                  }
                                  setSelectedTag(tag)
                                }}
                              >
                                <span className="uppercasetext">{t(`${tag}`)}</span>
                              </p>
                            );
                          })}
                        </div>
                        <div className="possibilitiessearch__filter-close">
                          <CloseOutlined
                            className="closebtn"
                            onClick={() => {
                              closeShowFilterBar();
                              setFilterType(null)
                              clearFilter();
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {filterType === "year" && (
                      <div className="flex-row-justify-space-between ">
                        <div className="tag-list">
                          {yearList.map((year) => {
                            return (
                              <p
                                key={year}
                                className={
                                  selectedYear === year
                                    ? "tag-item-active mar-b-10"
                                    : "tag-item mar-b-10"
                                }
                                onClick={() => {
                                  if (selectedYear === year) {
                                    setSelectedYear(null)
                                    return
                                  }
                                  setSelectedYear(year)
                                }}
                              >
                                {t(`${year}`)}
                              </p>
                            );
                          })}
                        </div>
                        <div className="previousprogramssearch____filter-close">
                          <CloseOutlined
                            className="closebtn"
                            onClick={() => {
                              closeShowFilterBar();
                              setFilterType(null)
                              clearFilter();
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Col>
                  <Col span={24}>

                  </Col>
                </>
              )}
            </Row>
          </div>
          {totalData && (
            <PossibilitiesSearchDisplay
              t={t}
              filteredData={totalData}
              pagination={pagination}
              handlePagination={handlePagination}
            />
          )}
        </div>
      </>
    </>
  );
}
