import React from "react";
import "./styles.css";

import { Row, Col, Button } from "antd";

import { IMAGE_BASE_URL } from "../../../../services/api";

import { Link, withRouter } from "react-router-dom";
import { TagsDisplay } from "../../UiComponents";

export default function TriangleSection2(props) {
  const data = props.queryData;

  return (
    <div className="trianglesection2__container">
      {data && (
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <div className="trianglesection2__content">
              <Link to={`/triangle/${data.id}`}>
                <p className="trianglesection2__content-title text-fabric-sans-italic fs-2-5em lh-1-2em">
                  {data.title}
                </p>
              </Link>
              <p className="trianglesection2__content-line-1 fs-1-2em lh-1-4em ">
                {data.subtitle}
              </p>
              <p className="trianglesection2__content-line-1 fs-1-6em lh-1-4em text-fabric-serif-italic mar-b-10">
                {data.author && data.author.authorName}
              </p>
              <TagsDisplay tagList={data.tags} />
            </div>
          </Col>
        </Row>
      )}
    </div>
  );
}
