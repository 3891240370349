import React from "react";
import "./style.css";
import { Row, Col, Button } from "antd";

import { ArtistInResidencySection1sub2 } from "../../artistinresidency/artistinresidency-section1/ArtistInResidencySection1";
// language context
import LocalizationContext from "../../../../localization/LocalizationContext";

import { IMAGE_BASE_URL } from "../../../../services/api";

export default function ArtistProfileSection3(props) {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  let x = {
    data: [props.queryData.data],
    isLoading: props.queryData.isLoading,
  };

  // console.log(x);

  return (
    <div className="artistinprofilesection3__container">
      {x.data.length > 0 && (
        <>
          {x.data.programs && (
            <p className="artistprofilesection3__title text-fabric-sans">
              {t("Related Content")}
            </p>
          )}
          <ArtistInResidencySection1sub2
            appLanguage={appLanguage}
            queryData={x}
          />
        </>
      )}
    </div>
  );
}
