import React, { useState, useEffect, useRef } from "react";

import "./styles.css";

import {
  MainNavBar,
  Footer,
  LineSeperator,
  GlobalSearchSection1,
  GlobalSearchDisplaySection,
} from "../../UIcomponents/UiComponents";
import { updatePageHead } from "../../../common/UpdateMetaAndTitle";
import { useQuery } from "react-query";
import artistsSearchRequests from "../../../services/helpers/search/artists-search";
import opportunitiesSearchRequests from "../../../services/helpers/search/opportunities-search";
import programsSearchRequests from "../../../services/helpers/search/programs-search";
import trianglesSearchRequests from "../../../services/helpers/search/triangles-search";
import tagsRequests from "../../../services/helpers/tagsquery";
import { withRouter } from "react-router-dom";
// language context
import LocalizationContext from "../../../localization/LocalizationContext";
import { defaultPaginationState } from "../../../consts/pagination";

function Search(props) {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  //update page head
  updatePageHead(`${t("Search")}`, "", "");

  const url_Name = props.match.params.name;
  const url_Param = props.match.params.param;

  let [tagList, setTagList] = useState(null);

  let [yearList, setYearList] = useState(null);
  let [filterType, setFilterType] = useState(null);

  let [totalData, setTotalData] = useState([]);
  let [currentSorting, setCurrentSorting] = useState('DESC')
  let [submitSearch, setSubmitSearch] = useState(false);
  let [searchKeyword, setSearchKeyword] = useState("");
  let [selectedYear, setSelectedYear] = useState(null)
  let [selectedTag, setSelectedTag] = useState(null)
  let [tagFromUrl, setTagFromUrl] = useState(null)
  let [pagination, setPagination] = useState(defaultPaginationState);
  const searchKeywordRef = useRef(searchKeyword);

  useEffect(() => {
    if (url_Name && url_Param === "tag") {
      setSelectedTag(url_Name)
      setTagFromUrl(url_Name)
    }
  }, [url_Name, url_Param]);


  let queryDataGetYears = {
    resource: "years",
  };
  const yearListQuery = useQuery(["getAllYears", queryDataGetYears], () =>
    tagsRequests.getAllYears(queryDataGetYears)
  );
  let queryDataSectionGetGlobalTags = {
    resource: "tag-types",
    param: "type",
    string: "global_tags",
    language: i18n.language
  };
  const globalTagInfoQuery = useQuery(
    ["getTagTypeByName", queryDataSectionGetGlobalTags],
    () => tagsRequests.getTagTypeByName(queryDataSectionGetGlobalTags)
  );
  useEffect(() => {
    let tempTagList = [];
    if (globalTagInfoQuery.data && globalTagInfoQuery.data.length > 0) {
      let x = globalTagInfoQuery.data[0].tags;
      // console.log(x);
      let _filteredTagsByLanguage = x.filter((tagItem) => tagItem.language === appLanguage)
      tempTagList = _filteredTagsByLanguage.map((tagItem) => {
        return tagItem.tagName;
      });
    }
    setTagList(tempTagList);
  }, [globalTagInfoQuery.data]);
  useEffect(() => {
    let tempYearList = [];
    if (yearListQuery.data && yearListQuery.data.length > 0) {
      let x = yearListQuery.data;
      // console.log(x);
      tempYearList = x.map((item) => {
        return item.year;
      });
    }
    setYearList(tempYearList);
  }, [yearListQuery.data]);


  // clear filter
  let clearFilter = () => {
    setSearchKeyword('')
    setCurrentSorting('DESC')
    setSelectedYear(null)
    setSelectedTag(null)
  };

  // pagination
  let handlePagination = () => {
    let paginateData = [...totalData]

    let totalrows = paginateData.length;
    let x = pagination;
    if (x.isNextPageAvailable) {
      setPagination({
        ...x,
        start: x.start,
        limit: x.limit + x.step,
        page: x.page + 1,
      });
    }
    if (totalrows < x.limit) {
      setPagination({
        ...x,
        isNextPageAvailable: false,
      });
    }
  };


  const artists_filterForKeyword = () => {
    let queryData = {
      lang: i18n.language,
      string: searchKeywordRef.current,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    };
    let response = artistsSearchRequests.searchArtists(queryData, 'keyword')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'artists')
      }
    })
  }
  const artists_filterForTag = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    };
    let response = artistsSearchRequests.searchArtists(queryData, 'tag')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'artists')
      }
    })
  }
  const artists_filterForYear = () => {
    // query based on year
    let queryData = {
      lang: i18n.language,
      param: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = artistsSearchRequests.searchArtists(queryData, 'year')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'artists')
      }
    })
  }
  const artists_filterForKeywordAndYear = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedYear,
      string: searchKeywordRef.current,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = artistsSearchRequests.searchArtists(queryData, 'keywordYear')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'artists')
      }
    })
  }
  const artists_filterForKeywordAndTag = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      string: searchKeywordRef.current,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = artistsSearchRequests.searchArtists(queryData, 'keywordTag')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'artists')
      }
    })
  }
  const artists_filterForTagAndYear = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      secondParam: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = artistsSearchRequests.searchArtists(queryData, 'tagYear')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'artists')
      }
    })
  }
  const artists_filterForKeywordAndTagAndYear = () => {
    let queryData = {
      lang: i18n.language,
      string: searchKeywordRef.current,
      param: selectedTag,
      secondParam: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = artistsSearchRequests.searchArtists(queryData, 'keywordTagYear')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'artists')
      }
    })
  }

  const triangles_filterForKeyword = () => {
    let queryData = {
      lang: i18n.language,
      string: searchKeywordRef.current,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    };
    let response = trianglesSearchRequests.searchTriangles(queryData, 'keyword')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'triangles')
      }
    })
  }
  const triangles_filterForTag = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    };
    let response = trianglesSearchRequests.searchTriangles(queryData, 'tag')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'triangles')
      }
    })
  }
  const triangles_filterForYear = () => {
    // query based on year
    let queryData = {
      lang: i18n.language,
      param: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = trianglesSearchRequests.searchTriangles(queryData, 'year')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'triangles')
      }
    })
  }
  const triangles_filterForKeywordAndYear = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedYear,
      string: searchKeywordRef.current,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = trianglesSearchRequests.searchTriangles(queryData, 'keywordYear')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'triangles')
      }
    })
  }
  const triangles_filterForKeywordAndTag = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      string: searchKeywordRef.current,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = trianglesSearchRequests.searchTriangles(queryData, 'keywordTag')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'triangles')
      }
    })
  }
  const triangles_filterForTagAndYear = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      secondParam: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = trianglesSearchRequests.searchTriangles(queryData, 'tagYear')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'triangles')
      }
    })
  }
  const triangles_filterForKeywordAndTagAndYear = () => {
    let queryData = {
      lang: i18n.language,
      string: searchKeywordRef.current,
      param: selectedTag,
      secondParam: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = trianglesSearchRequests.searchTriangles(queryData, 'keywordTagYear')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'triangles')
      }
    })
  }

  const opportunities_filterForKeyword = () => {
    let queryData = {
      lang: i18n.language,
      string: searchKeywordRef.current,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    };
    let response = opportunitiesSearchRequests.searchOpportunities(queryData, 'keyword')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'opportunities')
      }
    })
  }
  const opportunities_filterForTag = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    };
    let response = opportunitiesSearchRequests.searchOpportunities(queryData, 'tag')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'opportunities')
      }
    })
  }
  const opportunities_filterForYear = () => {
    // query based on year
    let queryData = {
      lang: i18n.language,
      param: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = opportunitiesSearchRequests.searchOpportunities(queryData, 'year')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'opportunities')
      }
    })
  }
  const opportunities_filterForKeywordAndYear = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedYear,
      string: searchKeywordRef.current,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = opportunitiesSearchRequests.searchOpportunities(queryData, 'keywordYear')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'opportunities')
      }
    })
  }
  const opportunities_filterForKeywordAndTag = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      string: searchKeywordRef.current,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = opportunitiesSearchRequests.searchOpportunities(queryData, 'keywordTag')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'opportunities')
      }
    })
  }
  const opportunities_filterForTagAndYear = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      secondParam: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = opportunitiesSearchRequests.searchOpportunities(queryData, 'tagYear')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'opportunities')
      }
    })
  }
  const opportunities_filterForKeywordAndTagAndYear = () => {
    let queryData = {
      lang: i18n.language,
      string: searchKeywordRef.current,
      param: selectedTag,
      secondParam: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = opportunitiesSearchRequests.searchOpportunities(queryData, 'keywordTagYear')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'opportunities')
      }
    })
  }

  const programs_filterForKeyword = () => {
    let queryData = {
      lang: i18n.language,
      string: searchKeywordRef.current,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    };
    let response = programsSearchRequests.searchPrograms(queryData, 'keyword')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'programs')
      }
    })
  }
  const programs_filterForTag = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    };
    let response = programsSearchRequests.searchPrograms(queryData, 'tag')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'programs')
      }
    })
  }
  const programs_filterForYear = () => {
    // query based on year
    let queryData = {
      lang: i18n.language,
      param: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = programsSearchRequests.searchPrograms(queryData, 'year')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'programs')
      }
    })
  }
  const programs_filterForKeywordAndYear = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedYear,
      string: searchKeywordRef.current,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = programsSearchRequests.searchPrograms(queryData, 'keywordYear')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'programs')
      }
    })
  }
  const programs_filterForKeywordAndTag = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      string: searchKeywordRef.current,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = programsSearchRequests.searchPrograms(queryData, 'keywordTag')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'programs')
      }
    })
  }
  const programs_filterForTagAndYear = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      secondParam: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = programsSearchRequests.searchPrograms(queryData, 'tagYear')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'programs')
      }
    })
  }
  const programs_filterForKeywordAndTagAndYear = () => {
    let queryData = {
      lang: i18n.language,
      string: searchKeywordRef.current,
      param: selectedTag,
      secondParam: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = programsSearchRequests.searchPrograms(queryData, 'keywordTagYear')
    response.then(res => {
      if (res) {
        setTotalDataInStateForType(res, 'programs')
      }
    })
  }

  const setTotalDataInStateForType = async (data, contentType) => {
    if (!data.length) return
    setTotalData(function updateTotalData(prevData) {
      prevData.filter(item => item.type !== contentType)
      return [...prevData, { data: data, type: contentType }];
    });


  }

  const initiateSearch = () => {
    // usecases
    if (searchKeywordRef.current.length > 0) {
      if (!selectedTag && !selectedYear) {
        programs_filterForKeyword()
        opportunities_filterForKeyword()
        artists_filterForKeyword()
        triangles_filterForKeyword()
      }
      if (!selectedTag && selectedYear) {
        programs_filterForKeywordAndYear()
        opportunities_filterForKeywordAndYear()
        artists_filterForKeywordAndYear()
        triangles_filterForKeywordAndYear()
      }
      if (selectedTag && !selectedYear) {
        programs_filterForKeywordAndTag()
        opportunities_filterForKeywordAndTag()
        artists_filterForKeywordAndTag()
        triangles_filterForKeywordAndTag()
      }
      if (selectedTag && selectedYear) {
        programs_filterForKeywordAndTagAndYear()
        opportunities_filterForKeywordAndTagAndYear()
        artists_filterForKeywordAndTagAndYear()
        triangles_filterForKeywordAndTagAndYear()
      }
    } else {
      if (selectedTag && !selectedYear) {
        programs_filterForTag()
        opportunities_filterForTag()
        artists_filterForTag()
        triangles_filterForTag()
      }
      if (!selectedTag && selectedYear) {
        programs_filterForYear()
        opportunities_filterForYear()
        artists_filterForYear()
        triangles_filterForYear()
      }
      if (selectedTag && selectedYear) {
        programs_filterForTagAndYear()
        opportunities_filterForTagAndYear()
        artists_filterForTagAndYear()
        triangles_filterForTagAndYear()
      }
      if (!selectedTag && !selectedYear) {
        programs_filterForKeyword()
        opportunities_filterForKeyword()
        artists_filterForKeyword()
        triangles_filterForKeyword()
      }
    }
  };
  
  useEffect(() => {
    searchKeywordRef.current = searchKeyword;
    if (!submitSearch) {
      setSubmitSearch(true);
      console.log('submitting');
    } else {
      console.log('already submitting, skipping');
    }
  }, [selectedTag, selectedYear, searchKeyword, currentSorting, pagination.limit, i18n.language])

  useEffect(() => {
    setPagination(defaultPaginationState);
  }, [selectedTag, selectedYear, searchKeyword, currentSorting, i18n.language]);

  useEffect(() => {
    if (submitSearch) {
      setTimeout(
        function () {
          console.log(searchKeywordRef.current);
          performSearch();
          setSubmitSearch(false);
        }, 1500);
    }
  }, [submitSearch]);

  const performSearch = () => {
    console.log('performing search');
    if (!tagFromUrl && !(searchKeywordRef.current.trim().length > 0) && !selectedTag && !selectedYear) {
      setTotalData([]);
      return
    }
    setTotalData([]);
    initiateSearch();
  }

  const onSearchSubmit = () => {
    setPagination(defaultPaginationState);
    performSearch();
  }

  return (
    <>
      <MainNavBar pageTitle={`${t("Search")}`} bgClass={"bg-highlight"} />

      <div className="content-container-wrapper bg-highlight">
        <div className="content-container-content">
          {/* section  1 will  be search and  then inside  it there  will be another component to  show result */}

          <GlobalSearchSection1
            //
            t={t}
            appLanguage={appLanguage}
            // search handle
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            onSubmit={onSearchSubmit}
            // selected tag
            setSelectedTag={setSelectedTag}
            selectedTag={selectedTag}

            // selected year
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}

            // tags
            tagList={tagList}
            yearList={yearList}
            // sort
            currentSorting={currentSorting}
            setCurrentSorting={setCurrentSorting}
            // filter
            clearFilter={clearFilter}
            filterType={filterType}
            setFilterType={setFilterType}
          />

          <div className="searchpage__results-section">

            {!totalData && totalData.length < 1 && (
              <p className="mar-t-10">{t("No Results")}</p>
            )}

            {totalData && totalData.length > 0 && (
              <GlobalSearchDisplaySection
                t={t}
                searchResultData={totalData}
                handlePagination={handlePagination}
                pagination={pagination}
              />
            )}
          </div>
        </div>
      </div>
      <LineSeperator />
      <Footer />
    </>
  );
}

export default withRouter(Search);
