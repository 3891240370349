import api from "../api";
import to from "await-to-js";
import { l_getSectionContent, findOne } from "../queryGenerator";
import moment from "moment";
import qs from 'qs'

const programsRequests = {
  listAllCurrentPrograms: async (queryData) => {
    // console.log(queryData);
    let todayDate = moment().format("YYYY-MM-DD");

    const query = qs.stringify({
      _where: {
        language_eq: queryData.lang,
        _or: [
          [{ dateEnd_gte: todayDate }],
          [{ noEndDate: true }],
        ],
      },
      _sort: 'dateStart:ASC'
    });

    let resourceString = `programs?${query}`

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    if (error) {
      throw error
    }
    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },

  getProgramByID: async (queryData) => {
    // console.log(queryData);
    let resourceString = await findOne(queryData.resource, queryData.id);

    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);
    if (error) {
      throw error
    }
    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },

  getRelatedPrograms: async (queryData) => {
    // console.log(queryData);
    let resourceString = await l_getSectionContent(
      queryData.resource,
      queryData.language,
      queryData.param,
      queryData.string
    );
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);
    if (error) {
      throw error
    }
    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  }
};

export default programsRequests;
