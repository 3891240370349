import React from "react";

import "./styles.css";

import { Row, Col } from "antd";
import { Link } from "react-router-dom";

export default function ArticleAboutProject(props) {
  const { t } = props;
  const { data } = props

  // console.log('related project >>>', data)
  return (
    <div className="content-container-wrapper bg-highlight">
      <div className="content-container-content">
        <div className="articleaboutproject__container">
          <Row gutter={12}>
            <>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
                xxl={{ span: 6 }}
              >
                <div className="fs-1-6em lh-1-2em">
                  {t("About the project")}
                </div>
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 18 }}
                xl={{ span: 18 }}
                xxl={{ span: 18 }}
              >
                <div>
                  <p className="articleaboutproject__content-description">
                    {data.shortDescription}
                  </p>
                  <Link to={`/projects/${data.id}`}>
                    <p className="fs-1-4em lh-1-2em">{t("Read More")}→</p>
                  </Link>
                </div>
              </Col>
            </>
          </Row>
        </div>
      </div>
    </div>
  );
}
