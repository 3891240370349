import themeRequests from "../../services/helpers/themequery";
import artistsRequests from "../../services/helpers/artistsquery";

let x = {
  "summer": {
    "highlight": "#fce123",
    "primary_text": "#1a1a1a",
    "primary_dark": "#1a1a1a",
    "primary_light": "#ffffff"
  },
  "autumn": {
    "highlight": "#f7bba8",
    "primary_text": "#1a1a1a",
    "primary_dark": "#1a1a1a",
    "primary_light": "#ffffff"
  },
  "winter": {
    "highlight": "#97d8e6",
    "primary_text": "#1a1a1a",
    "primary_dark": "#1a1a1a",
    "primary_light": "#ffffff"
  },
  "spring": {
    "highlight": "#9ce6a8",
    "primary_text": "#1a1a1a",
    "primary_dark": "#1a1a1a",
    "primary_light": "#ffffff"
  }
}
// Summer (current color), Autumn, Winter, Spring

export async function loadTheme() {
  // fetch the theme options from backend
  const themeData = await themeRequests.getThemeValues();
  // set colors
  await setColors(themeData);
  // if avaible then set them accordingly
}

function setColors(themeData) {
  // console.log(themeData);

  let seasonName = themeData && themeData.season ? themeData?.season?.toLowerCase() : 'summer';
  let colorScheme = themeData && themeData.colorScheme ? themeData.colorScheme : x;

  if (seasonName && colorScheme) {
    let availableColrs = colorScheme[seasonName];

    setColorsWithAvailableColors(availableColrs);
  }
}
function setColorsWithAvailableColors(colors) {
  // console.log(colors);
  if (colors.highlight) {
    document.documentElement.style.setProperty(
      "--highlight-color",
      colors.highlight
    );
  }
  if (colors.primary_text)
    document.documentElement.style.setProperty(
      "--primary-text",
      `${colors.primary_text}`
    );

  if (colors.primary_dark)
    document.documentElement.style.setProperty(
      "--primary-dark",
      `${colors.primary_dark}`
    );

  if (colors.primary_light)
    document.documentElement.style.setProperty(
      "--primary-light",
      `${colors.primary_light}`
    );
}
