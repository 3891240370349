
import React, { useState, useEffect } from 'react'

import { Row, Col } from 'antd'
import { IMAGE_BASE_URL } from "../../../../../services/api";
import { LightBoxSimple } from "../../../lightboxsimple/LightBoxSimple";

import './styles.css'

function SingleImage(props) {

    const [toggle, setToggle] = useState(false);
    const [sIndex, setSIndex] = useState(0);

    // Handler
    const lightBoxHandler = (state, sIndex) => {
        setToggle(state);
        setSIndex(sIndex);
    };


    const { content } = props
    return (
        <div className="content-container-wrapper">
            {/* <div className="content-container-content"> */}
            <div className="singleimage__container">
                <Row>
                    <Col span={24}>
                        {content.singleImage && (
                            <div className="singleimage__img">
                                <img src={`${IMAGE_BASE_URL}${content.singleImage.url}`}
                                    onClick={() => {
                                        lightBoxHandler(true, 0);
                                    }}
                                />
                            </div>
                        )}
                        <p className="singleimage__img-caption fs-1em">
                            {content.caption}
                        </p>
                    </Col>
                </Row>
                {content.singleImage &&
                    <LightBoxSimple
                        toggle={toggle}
                        lightBoxHandler={lightBoxHandler}
                        data={[
                            {
                                "id": 1,
                                "image": `${IMAGE_BASE_URL}${content.singleImage.url}`,
                                "title": "",
                                "description": ""
                            }
                        ]}
                        sIndex={sIndex}
                        setSIndex={setSIndex}
                    />
                }
            </div>
            {/* </div> */}
        </div>
    )
}

export default SingleImage