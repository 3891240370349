

import React, { useState, useEffect } from 'react'
import { LightBox } from 'react-lightbox-pack';

import "react-lightbox-pack/dist/index.css";
import './styles.css'

export const LightBoxSimple = (props) => {

    const {
        toggle,
        lightBoxHandler,
        data,
        sIndex,
        setSIndex,
    } = props

    useEffect(() => {
        if (data.length === 1) {
            let ri = window.document.querySelector('.lightboxsimple__container ._18xMp ._20cg_')
            let le = window.document.querySelector('.lightboxsimple__container ._2pWTS ._20cg_')
            if (le) le.style.display = "none"
            if (ri) ri.style.display = "none"
        }
    }, [data])

    useEffect(() => {
        if (data) {
            let box = window.document.querySelector('.lightboxsimple__container ._3AWTh')
            box && box.addEventListener('click', (e) => {
                if (e.target === e.currentTarget) {
                    lightBoxHandler(false, 0)
                }
            })
        }
    }, [data, lightBoxHandler])


    return (
        <div className="lightboxsimple__container">
            <LightBox
                state={toggle}
                event={lightBoxHandler}
                data={data}
                imageWidth={"80vw"}
                imageHeight={"80vh"}
                thumbnailHeight={0}
                thumbnailWidth={0}
                setImageIndex={setSIndex}
                imageIndex={sIndex}
            />
            {
                data && data.length > 1 &&
                <>
                    {
                        toggle && <p className="lightboxsimple__slideindex text-fabric-serif">{sIndex + 1}/{data.length}</p>
                    }
                </>
            }
        </div>
    );
}
