import React, { useState, useEffect, useRef } from 'react'
import { Row, Col, Carousel } from 'antd'
import './styles.css'
import { IMAGE_BASE_URL } from "../../../../../services/api";

import { LightBoxSimple } from "../../../lightboxsimple/LightBoxSimple";

function SlideShow(props) {

    const slider = useRef();
    const { content } = props;

    let [currentSlide, setCurrentSlide] = useState(1);
    let [totalSlide, setTotalSlide] = useState(0);
    let [captionList, setCaptionList] = useState(null);

    let carouselSettings = {
        dotPosition: "bottom",
        autoPlay: true,
        dots: false,
    };

    const [imageListForLightBox, setImageListForLightBox] = useState([])
    const [toggle, setToggle] = useState(false);
    const [sIndex, setSIndex] = useState(0);

    // Handler
    const lightBoxHandler = (state, sIndex) => {
        setToggle(state);
        setSIndex(sIndex);
    };

    useEffect(() => {
        if (content.slideshow && content.slideshow.length) {
            setTotalSlide(content.slideshow.length);
            let x = []
            // prepare the image list 
            content.slideshow.map((imageItem, index) => {
                x.push(
                    {
                        "id": index,
                        "image": `${IMAGE_BASE_URL}${imageItem.url}`,
                        "title": "",
                        "description": ""
                    }
                )
            })
            setImageListForLightBox(x)
        }
    }, [content]);

    useEffect(() => {
        if (content.slideshow) {
            setTotalSlide(content.slideshow.length);
        }
    }, [content]);

    useEffect(() => {
        if (content.captions) {
            setCaptionList(content.captions.split("#"));
        }
    }, [content]);

    const nextSlide = () => {
        slider.current.next();
        if (currentSlide === totalSlide) {
            setCurrentSlide(1);
        } else {
            setCurrentSlide(currentSlide + 1);
        }
    };
    const prevSlide = () => {
        slider.current.prev();
        if (currentSlide <= 1) {
            setCurrentSlide(totalSlide);
        } else {
            setCurrentSlide(currentSlide - 1);
        }
    };

    const handleBeforeCarouselChange = (before, after) => {
        console.log("before:", before + 1, " after: ", after + 1);
        setCurrentSlide(after + 1);
    };


    return (
        <div className="slideshow__container">
            <div className="slideshow__carousel">
                <Carousel
                    {...carouselSettings}
                    ref={(ref) => {
                        slider.current = ref;
                    }}
                    beforeChange={(b, a) => {
                        handleBeforeCarouselChange(b, a);
                    }}
                >
                    {content.slideshow.map((imageItem, index) => {
                        return (
                            <div
                                className="slideshow__image"
                                key={imageItem.url}
                            >
                                <img src={`${IMAGE_BASE_URL}${imageItem.url}`} alt=""
                                    onClick={() => {
                                        lightBoxHandler(true, index);
                                    }}
                                />
                            </div>
                        );
                    })}
                </Carousel>

                {/* lightbox for carousel */}
                <LightBoxSimple
                    toggle={toggle}
                    lightBoxHandler={lightBoxHandler}
                    data={imageListForLightBox}
                    sIndex={sIndex}
                    setSIndex={setSIndex}
                />


                <div className="content-container-wrapper">
                    <div className="content-container-content">
                        <div className="slideshow__caption">
                            <Row>
                                <Col span={16}>
                                    <p className="slideshow__slider-caption fs-1em">
                                        {captionList &&
                                            captionList.length > 0 &&
                                            captionList[currentSlide - 1]}
                                    </p>
                                </Col>
                                <Col span={8}>
                                    <div className="slideshow__slider-control fs-1em">
                                        <span onClick={prevSlide}>{`<`}</span>
                                        <span>
                                            {currentSlide}/{totalSlide}
                                        </span>
                                        <span onClick={nextSlide}>{`>`}</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlideShow