import React, { useState } from "react";

import "./styles.css";
import { IMAGE_BASE_URL } from "../../../../services/api";

import { Row, Col, Button } from "antd";

import { Link } from "react-router-dom";
import { TagsDisplay } from "../../UiComponents";

export default function TriangleSection4(props) {
  let data = props.queryData;

  const [showHoverEffect, setShowHoverEffect] = useState(false)

  const onHoverStyleText = {
    textDecoration: "underline"
  }
  const onHoverStyleImage = {
    opacity: 0.8
  }
  const onMouseHoverHeadingOrImage = () => {
    setShowHoverEffect(true)
  }
  const onMouseRemoveHeadingOrImage = () => {
    setShowHoverEffect(false)
  }

  return (
    <div className="trianglesection4__container">
      {data && (
        <>
          <Row gutter={12}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
              xl={{ span: 24 }}
              xxl={{ span: 24 }}
            >
              <Link to={`/triangle/${data.id}`}>
                <div className="trianglesection4__top-container">
                  {data.mainImage && (
                    <img src={`${IMAGE_BASE_URL}${data.mainImage.url}`}
                      style={showHoverEffect ? { ...onHoverStyleImage } : null}
                      onMouseOver={onMouseHoverHeadingOrImage}
                      onMouseOut={onMouseRemoveHeadingOrImage}
                    />
                  )}
                </div>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            ></Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 12 }}
              xl={{ span: 12 }}
              xxl={{ span: 12 }}
            >
              <div className="homesection1__bottom-container">
                <Link to={`/triangle/${data.id}`}>
                  <p className="trianglesection4__content-title fs-2-5em lh-1-2em"
                    style={showHoverEffect ? { ...onHoverStyleText } : null}
                    onMouseOver={onMouseHoverHeadingOrImage}
                    onMouseOut={onMouseRemoveHeadingOrImage}
                  >
                    {data.title}
                  </p>
                </Link>

                <p className="trianglesection4__content-text fs-1-2em lh-1-4em">
                  {data.shortDescription}(…)
                </p>

                <TagsDisplay tagList={data.tags} />
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
