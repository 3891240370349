import React from "react";

import "./styles.css";

import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../../../services/api";
import { TagsDisplay } from "../../UiComponents";
import LocalizationContext from "../../../../localization/LocalizationContext";

import { formatDate } from "../../../helper/formatDate";

export default function HomeSection4(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const { data, isLoading, error } = props.queryData;
  // let featuredHomeArtist = data && data[0];
  return (
    <div className="homesection4__container">
      {isLoading && <p>{t("Loading")}...</p>}
      {data && data.length > 0 && (
        <div className="homesection4__content">
          <div className="homesection4__image">
            <Link to={`/artistprofile/${data[0].id}`}>
              {data[0].profileImg && (
                <img src={`${IMAGE_BASE_URL}${data[0].profileImg.url}`} />
              )}
            </Link>
          </div>
          <div className="homesection4__info-text">
            <p className="homesection4__text-line1">
              {formatDate(data[0].dateStart)} - {formatDate(data[0].dateEnd)}
            </p>
            <Link to={`/artistprofile/${data[0].id}`}>
              <p className="homesection4__text-line2 fs-5-5em">
                {data[0].name} ({data[0].country})
              </p>
            </Link>
          </div>
          <div style={{ margin: "15px" }}></div>
          <TagsDisplay tagList={data[0].tags} />
        </div>
      )}
    </div>
  );
}
