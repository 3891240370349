import React from "react";

import "./styles.css";

import { updatePageHead } from "../../../common/UpdateMetaAndTitle";
import { useQuery } from "react-query";
import homePageRequests from "../../../services/helpers/homepagequery";
import artistsRequests from "../../../services/helpers/artistsquery";
import opportunitiesRequests from "../../../services/helpers/opportunitiesquery";
import triangleRequests from "../../../services/helpers/trianglequery";

import {
  MainNavBar,
  HomeSection1,
  HomeSection2,
  HomeSection3,
  HomeSection4,
  HomeSection5,
  AboutSection3,
  TriangleSection2,
  TriangleSection3,
  TriangleSection4,
  Footer,
  AnnouncementBar,
} from "../../UIcomponents/UiComponents";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";

export default function Home() {
  //update page head
  updatePageHead("Home", "", "");

  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  //getsection1
  //featured section1 program
  let queryDataSection1 = {
    resource: "programs",
    language: appLanguage,
    param: "displayHomePage",
    string: "section1",
  };
  const homeSection1QueryResult = useQuery(
    ["getHomeSection1", queryDataSection1],
    () => homePageRequests.getHomeSection1(queryDataSection1)
  );

  //getsection2
  //featured section2 program
  let queryDataSection2 = {
    resource: "programs",
    language: appLanguage,
    param: "displayHomePage",
    string: "section2",
  };
  const homeSection2QueryResult = useQuery(
    ["getHomeSection2", queryDataSection2],
    () => homePageRequests.getHomeSection2(queryDataSection2)
  );

  //getsection3
  //opportunities section1  fetch here and show details
  //featured opportunity
  let queryDataSection3 = {
    resource: "opportunities",
    language: appLanguage,
    param: "if_possibilities",
    string: "true",
  };
  const homeSection3QueryResult = useQuery(
    ["getFeaturedOpportunity", queryDataSection3],
    () => opportunitiesRequests.getFeaturedOpportunity(queryDataSection3)
  );

  // console.log(homeSection3QueryResult);

  // getsection4
  // featured artist query
  //get current artist in residency
  let queryDataSection4 = {
    resource: "artists",
    param: "current",
    string: "true",
  };
  const homeSection4QueryResult = useQuery(
    ["getCurrentArtist", queryDataSection4],
    () => artistsRequests.getCurrentArtist(queryDataSection4)
  );

  // getsection5
  // featured triangle for home page
  //multilingual
  let queryDataSection5 = {
    resource: "triangles",
    language: appLanguage,
    param: "if_home",
    string: "true",
  };
  const homeSection5QueryResult = useQuery(
    ["getFeaturedTriangle", queryDataSection5],
    () => triangleRequests.getFeaturedTriangle(queryDataSection5)
  );

  return (
    <>
      <MainNavBar pageTitle="" bgClass="bg-white" />

      {homeSection1QueryResult.data && (
        <HomeSection1 queryData={homeSection1QueryResult} />
      )}

      {homeSection2QueryResult.data && (
        <HomeSection2 queryData={homeSection2QueryResult} />
      )}

      {homeSection3QueryResult.data && (
        <HomeSection3 queryData={homeSection3QueryResult} />
      )}

      <div className="content-container-wrapper">
        <div className="content-container-content">
          {/* loadings all  */}
          {homeSection1QueryResult.isLoading && <p>Loading ...</p>}
          {homeSection2QueryResult.isLoading && <p>Loading ...</p>}
          {homeSection3QueryResult.isLoading && <p>Loading ...</p>}
          {homeSection4QueryResult.isLoading && <p>Loading ...</p>}
          {homeSection5QueryResult.isLoading && <p>Loading ...</p>}

          {homeSection4QueryResult.data && (
            <HomeSection4 queryData={homeSection4QueryResult} />
          )}

          {homeSection5QueryResult.data &&
            homeSection5QueryResult.data.length > 0 && (
              <>
                <>
                  {homeSection5QueryResult.data[0].type ===
                    "literaryResponse" ? (
                    <TriangleSection2
                      queryData={homeSection5QueryResult.data[0]}
                    />
                  ) : null}
                </>
                <>
                  {homeSection5QueryResult.data[0].type === "archiveStory" ||
                    homeSection5QueryResult.data[0].type === "interview" ? (
                    <TriangleSection3
                      queryData={homeSection5QueryResult.data[0]}
                    />
                  ) : null}
                </>
                <>
                  {homeSection5QueryResult.data[0].type === "article" ? (
                    <TriangleSection4
                      queryData={homeSection5QueryResult.data[0]}
                    />
                  ) : null}
                </>
              </>
              // <HomeSection5 queryData={homeSection5QueryResult} />
            )}
        </div>
      </div>
      <Footer />
    </>
  );
}
