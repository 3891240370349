function getMeta(metaName) {
  const metas = document.getElementsByTagName("meta");
  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute("name") === metaName) {
      return metas[i];
    }
  }
  return "";
}

export function updatePageHead(pageTitle, pageDescription, pageKeyword) {
  const descriptionTag = getMeta("description");
  const keywordTag = getMeta("keywords");

  //   if (!pageTitle) {
  //     setTitle(TITLE_SUFFIX);
  //     descriptionTag.content = DESCRIPTION_SUFFIX;
  //     keywordTag.content = KEYWORDS_SUFFIX;
  //     return;
  //   }

  setTitle(pageTitle);

  descriptionTag.content = pageDescription + " | " + DESCRIPTION_SUFFIX;

  keywordTag.content = pageKeyword + ", " + +KEYWORDS_SUFFIX;
}

export function setTitle(name) {
  window.document.title = name + " | " + TITLE_SUFFIX;
}

const TITLE_SUFFIX = "Hordaland Kunstsenter ";

const KEYWORDS_SUFFIX = "keywords";

const DESCRIPTION_SUFFIX = "description";
