import api from "../api";
import to from "await-to-js";
import {
  findSectionContent,
  findOne,
  l_searchKeywordWithPagination,
  l_getSectionContent,
} from "../queryGenerator";

const opportunitiesRequests = {
  searchOpportunityByTitle: async (queryData) => {
    // console.log(queryData);
    let resourceString = await l_searchKeywordWithPagination(
      queryData.resource,
      queryData.language,
      queryData.param,
      queryData.string,
      queryData.start,
      queryData.limit
    );
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },

  getOpportunityById: async (queryData) => {
    // console.log(queryData);
    let resourceString = await findOne(queryData.resource, queryData.id);

    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },

  // getArtAndPublicOpportunity: async (queryData) => {
  //   // console.log(queryData);
  //   let resourceString = await findSectionContent(
  //     queryData.resource,
  //     queryData.param,
  //     queryData.string
  //   );
  //   // console.log(resourceString);

  //   let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
  //   // console.log(error, response);

  //   if (response.status === "fail") {
  //     throw { ...response };
  //   } else {
  //     return response;
  //   }
  // },
  getFeaturedOpportunity: async (queryData) => {
    // console.log(queryData);
    let resourceString = await l_getSectionContent(
      queryData.resource,
      queryData.language,
      queryData.param,
      queryData.string
    );
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
};

export default opportunitiesRequests;
