import api from "../api";
import to from "await-to-js";
import { listAll, findOne, findSectionContent } from "../queryGenerator";
import moment from "moment";

const tagsRequests = {
  // current artist is a featured artist
  // current set to true in the backend

  getAllTags: async (queryData) => {
    // console.log(queryData);
    let resourceString = await listAll(queryData.resource);
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  getAllTagTypes: async (queryData) => {
    // console.log(queryData);
    let resourceString = await listAll(queryData.resource);
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  getTagTypeByName: async (queryData) => {
    // console.log(queryData);
    let resourceString = await findSectionContent(
      queryData.resource,
      queryData.param,
      queryData.string
    ); // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  getTagByName: async (queryData) => {
    // console.log(queryData);
    let resourceString = await findSectionContent(
      queryData.resource,
      queryData.param,
      queryData.string
    ); // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },

  // years
  //
  //
  getAllPreviousYears: async (queryData) => {
    // console.log(queryData);
    // let resourceString = await listAll(queryData.resource);
    let resourceString = `${queryData.resource}?_sort=year:DESC`;
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  getAllYears: async (queryData) => {
    // console.log(queryData);
    // let resourceString = await listAll(queryData.resource);
    let resourceString = `${queryData.resource}?_sort=year:DESC`;
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
};

export default tagsRequests;
