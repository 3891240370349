import React from "react";
import "./styles.css";

import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { formatDate } from "../../helper/formatDate";

export default function AuthorSection(props) {
  const { authorDetails, queryData, t } = props;

  return (
    <div className="content-container-wrapper">
      <div className="content-container-content">
        <div className="author-of-article">
          <p>{authorDetails.authorName}</p>
          <p>
            {t("Published")}: {formatDate(queryData.publishedOn)}
          </p>
        </div>
      </div>
    </div>
  );
}
