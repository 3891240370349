import React, { useState, useEffect } from "react";

import { Row, Col, Button, Input } from "antd";
import {
  CloseOutlined,
  DownOutlined,
  UpOutlined,
} from "@ant-design/icons";
// language context
import moment from "moment";
import LocalizationContext from "../../../../localization/LocalizationContext";
import PreviousProgramsDisplay from "./PreviousProgramsDisplay";
import { useQuery } from "react-query";
import programsSearchRequests from "../../../../services/helpers/search/programs-search";
import tagsRequests from "../../../../services/helpers/tagsquery";
import { defaultPaginationState } from "../../../../consts/pagination";

export default function PreviousProgramsSearch(props) {
  const { t, i18n } = React.useContext(LocalizationContext);
  let [showFilterBar, setShowFilterBar] = useState(false);
  let [filterType, setFilterType] = useState(null)
  let [searchKeyword, setSearchKeyword] = useState("");
  let [selectedYear, setSelectedYear] = useState(null)
  let [selectedTag, setSelectedTag] = useState(null)
  let [currentSorting, setCurrentSorting] = useState('DESC')
  let [tagList, setTagList] = useState(null);
  let [yearList, setYearList] = useState(null);
  let [totalData, setTotalData] = useState([]);
  let [pagination, setPagination] = useState(defaultPaginationState);

  // list all tags and years for the programs page
  let queryDataGetYears = {
    resource: "years",
  };
  const yearListQuery = useQuery(["getAllYears", queryDataGetYears], () =>
    tagsRequests.getAllYears(queryDataGetYears)
  );

  let queryDataGetTags = {
    resource: "tag-types",
    param: "type",
    string: "programs_tags",
    language: i18n.language
  };
  const programsTags = useQuery(["programTags", queryDataGetTags], () =>
    tagsRequests.getTagTypeByName(queryDataGetTags)
  );
  useEffect(() => {
    let tempTagList = [];
    if (programsTags.data && programsTags.data.length > 0) {
      let x = programsTags.data[0].tags;
      // console.log(x);
      let _filteredTagsByLanguage = x.filter((tagItem) => tagItem.language === i18n.language)
      tempTagList = _filteredTagsByLanguage.map((tagItem) => {
        return tagItem.tagName;
      });
    }
    setTagList(tempTagList);
  }, [programsTags.data]);

  useEffect(() => {
    let tempYearList = [];
    if (yearListQuery.data && yearListQuery.data.length > 0) {
      let x = yearListQuery.data;
      // console.log(x);
      tempYearList = x.map((item) => {
        return item.year;
      });
    }
    setYearList(tempYearList);
  }, [yearListQuery.data]);


  useEffect(() => {
    filterForKeyword('')
  }, [])


  // clear filter
  let clearFilter = () => {
    setSearchKeyword('')
    setCurrentSorting('DESC')
    setSelectedYear(null)
    setSelectedTag(null)
  };

  // pagination
  let handlePagination = () => {
    let paginateData = [...totalData]

    let totalrows = paginateData.length;
    let x = pagination;
    if (x.isNextPageAvailable) {
      setPagination({
        ...x,
        start: x.start,
        limit: x.limit + x.step,
        page: x.page + 1,
      });
    }
    if (totalrows < x.limit) {
      setPagination({
        ...x,
        isNextPageAvailable: false,
      });
    }
  };

  const closeShowFilterBar = () => {
    setShowFilterBar(false);
    setFilterType(null)
  };

  const showFilterBarSection = () => {
    setShowFilterBar(true);
  };

  const filterForKeyword = () => {
    let queryData = {
      lang: i18n.language,
      string: searchKeyword,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting,
      newerThan: moment().format("YYYY-MM-DD")
    };
    let response = programsSearchRequests.searchPrograms(queryData, 'keyword')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForTag = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting,
      newerThan: moment().format("YYYY-MM-DD")
    };
    let response = programsSearchRequests.searchPrograms(queryData, 'tag')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForYear = () => {
    // query based on year
    let queryData = {
      lang: i18n.language,
      param: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting,
      newerThan: moment().format("YYYY-MM-DD")
    }
    let response = programsSearchRequests.searchPrograms(queryData, 'year')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForKeywordAndYear = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedYear,
      string: searchKeyword,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting,
      newerThan: moment().format("YYYY-MM-DD")
    }
    let response = programsSearchRequests.searchPrograms(queryData, 'keywordYear')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForKeywordAndTag = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      string: searchKeyword,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting,
      newerThan: moment().format("YYYY-MM-DD")
    }
    let response = programsSearchRequests.searchPrograms(queryData, 'keywordTag')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForTagAndYear = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      secondParam: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting,
      newerThan: moment().format("YYYY-MM-DD")
    }
    let response = programsSearchRequests.searchPrograms(queryData, 'tagYear')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForKeywordAndTagAndYear = () => {
    let queryData = {
      lang: i18n.language,
      string: searchKeyword,
      param: selectedTag,
      secondParam: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting,
      newerThan: moment().format("YYYY-MM-DD")
    }
    let response = programsSearchRequests.searchPrograms(queryData, 'keywordTagYear')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }

  const initiateSearch = () => {
    console.log('search');
    // usecases
    if (searchKeyword.length > 0) {
      if (!selectedTag && !selectedYear) {
        filterForKeyword()
      }
      if (!selectedTag && selectedYear) {
        filterForKeywordAndYear()
      }
      if (selectedTag && !selectedYear) {
        filterForKeywordAndTag()
      }
      if (selectedTag && selectedYear) {
        filterForKeywordAndTagAndYear()
      }
    } else {
      if (selectedTag && !selectedYear) {
        filterForTag()
      }
      if (!selectedTag && selectedYear) {
        filterForYear()
      }
      if (selectedTag && selectedYear) {
        filterForTagAndYear()
      }
      if (!selectedTag && !selectedYear) {
        filterForKeyword()
      }
    }
  }

  useEffect(() => {
    console.log('resetting pagination');
    setPagination(defaultPaginationState);
  }, [selectedTag, selectedYear, searchKeyword, currentSorting, i18n.language]);

  useEffect(() => {
    initiateSearch()
  }, [selectedTag, selectedYear, searchKeyword, currentSorting, pagination.limit, i18n.language]);

  return (
    <div>
      <div className="previousprogramssearch__container">
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
            xxl={{ span: 24 }}
          >
            <p className="previousprogramssearch__title">
              {t("Archive of previous exhibitions and events")}
            </p>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
            xxl={{ span: 24 }}
          >
            <div className="previousprogramssearch__search-box flex-row-justify-space-between ">
              <Input
                onPressEnter={(e) => {
                  setSearchKeyword(e.target.value.trim());
                }}
                className="placeholder-f-s-1-6em"
                placeholder={`${t("Search")}`}
                value={searchKeyword}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
              />
              <div className="flex-row-align-items-center">
                <Button
                  className={
                    filterType === "tag"
                      ? "search-filter-active"
                      : "search-filter"
                  }
                  onClick={() => {
                    if (filterType === "tag") {
                      closeShowFilterBar();
                      return
                    }
                    showFilterBarSection();
                    setFilterType("tag");
                  }}
                >
                  {t("Filter")}
                </Button>
                <Button
                  className={
                    filterType === "year"
                      ? "search-filter-active mar-l-10"
                      : "search-filter mar-l-10"
                  }
                  onClick={() => {
                    if (filterType === "year") {
                      closeShowFilterBar();
                      return
                    }
                    showFilterBarSection();
                    setFilterType("year");
                  }}
                >
                  {t("Year")}
                </Button>
                <span className="mar-l-10 fs-1-3em">
                  <DownOutlined onClick={() => { setCurrentSorting('DESC') }}
                    className={currentSorting === 'DESC' ? 'sort-btn-active' : 'sort-btn'}
                  />
                </span>
                <span className="fs-1-3em mar-l-10 ">
                  <UpOutlined onClick={() => { setCurrentSorting('ASC') }}
                    className={currentSorting === 'ASC' ? 'sort-btn-active' : 'sort-btn'}
                  />
                </span>

              </div>
            </div>
          </Col>
          {showFilterBar && (
            <>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
              >
                {filterType === "tag" && (
                  <div className="flex-row-justify-space-between ">
                    <div className="tag-list">
                      {tagList.map((tag) => {
                        return (
                          <p
                            key={tag}
                            className={
                              selectedTag === tag
                                ? "tag-item-active mar-b-10"
                                : "tag-item mar-b-10"
                            }
                            onClick={() => {
                              if (selectedTag === tag) {
                                setSelectedTag(null)
                                return
                              }
                              setSelectedTag(tag)
                            }}
                          >
                            <span className="uppercasetext">{t(`${tag}`)}</span>
                          </p>
                        );
                      })}
                    </div>
                    <div className="previousprogramssearch____filter-close">
                      <CloseOutlined
                        className="closebtn"
                        onClick={() => {
                          closeShowFilterBar();
                          setFilterType(null)
                          clearFilter();
                        }}
                      />
                    </div>
                  </div>
                )}
                {filterType === "year" && (
                  <div className="flex-row-justify-space-between ">
                    <div className="tag-list">
                      {yearList.map((year) => {
                        return (
                          <p
                            key={year}
                            className={
                              selectedYear === year
                                ? "tag-item-active mar-b-10"
                                : "tag-item mar-b-10"
                            }
                            onClick={() => {
                              if (selectedYear === year) {
                                setSelectedYear(null)
                                return
                              }
                              setSelectedYear(year)
                            }}
                          >
                            {t(`${year}`)}
                          </p>
                        );
                      })}
                    </div>
                    <div className="previousprogramssearch____filter-close">
                      <CloseOutlined
                        className="closebtn"
                        onClick={() => {
                          closeShowFilterBar();
                          setFilterType(null)
                          clearFilter();
                        }}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </>
          )}
        </Row>
      </div>

      {/* {searchAllPreviousProgramsQueryResult.isLoading && <p className="mar-t-10">Searching...</p>} */}
      {totalData && (
        <PreviousProgramsDisplay
          filteredData={totalData}
          pagination={pagination}
          handlePagination={handlePagination}
          t={t}
        />
      )}
    </div>
  );
}
