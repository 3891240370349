import React, { useState, useEffect } from "react";
import "./style.css";
import { Row, Col } from "antd";
import {
  MainNavBar,
  Footer,
  RenderArticleContent,
} from "../../UIcomponents/UiComponents";

import { LightBoxSimple } from "../../UIcomponents/lightboxsimple/LightBoxSimple";

import { IMAGE_BASE_URL } from "../../../services/api";

import { updatePageHead } from "../../../common/UpdateMetaAndTitle";
import { withRouter, Link } from "react-router";

import { connect } from "react-redux";
import { useQuery } from "react-query";

// import and setting  for rendering html with image
import ReactHtmlParser from "react-html-parser";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";

// import parse from "html-react-parser";
// const options = {
//   replace: (domNode) => {
//     if (domNode.attribs && domNode.attribs.src) {
//       console.log(`${IMAGE_BASE_URL}${domNode.attribs.src}`);
//       return (domNode.attribs.src = `${IMAGE_BASE_URL}${domNode.attribs.src}`);
//     }
//   },
// };

function AboutHks(props) {
  updatePageHead("About HKS", "", "");

  const { about } = props;

  return (
    <>
      <MainNavBar backUrl="/about" pageTitle={`About Hks`} bgClass="bg-white" />

      <div className="content-container-wrapper">
        <div className="content-container-content">
          {about && about.aboutDetails && (
            <>
              <AboutHksSection1 aboutDetails={about.aboutDetails} />
              {/* <AboutHksSection2 aboutDetails={about.aboutDetails} /> */}
            </>
          )}
        </div>
      </div>

      {/* this component renders different sections of a article*/}
      {
        about && about.aboutDetails && about.aboutDetails.articleContent.length > 0 ?
          <RenderArticleContent articleContent={about.aboutDetails.articleContent} /> : null
      }
      <Seperator />


      <Footer />
    </>
  );
}

function Seperator() {
  return (<div style={{ height: "40px" }}></div>)
}

function AboutHksSection1(props) {
  const { aboutDetails } = props;

  const [toggle, setToggle] = useState(false);
  const [sIndex, setSIndex] = useState(0);

  const { t, i18n } = React.useContext(LocalizationContext);

  // Handler
  const lightBoxHandler = (state, sIndex) => {
    setToggle(state);
    setSIndex(sIndex);
  };

  return (
    <>
      <div className="artistprofilesection1__container">
        <p className="trianglepagesection1__title fs-2-5em lh-1-2em">
          { t('About Hordaland Kunstsenter') }
        </p>
        <Row style={{ marginBottom: "40px" }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 18 }}
            xl={{ span: 18 }}
            xxl={{ span: 18 }}
          >
            {/* <TagsDisplay tagList={data.tags} /> */}
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 6 }}
            xl={{ span: 6 }}
            xxl={{ span: 6 }}
          >
            {aboutDetails.description_document && (
              <div className="text-fabric-sans has-link text-underline fs-1-1em">
                <a
                  href={`${IMAGE_BASE_URL}${aboutDetails.description_document.url}`}
                >
                  Download PDF →
                </a>
              </div>
            )}
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <div className="artistprofilesection1__img">
              {aboutDetails.image && (
                <img src={`${IMAGE_BASE_URL}${aboutDetails.image.url}`}
                  onClick={() => {
                    lightBoxHandler(true, 0);
                  }}
                />
              )}
            </div>
            <span className="artistprofilesection1__img-caption">
              {aboutDetails.imageCaption}
            </span>
          </Col>
          <LightBoxSimple
            toggle={toggle}
            lightBoxHandler={lightBoxHandler}
            data={[
              {
                "id": 1,
                "image": `${IMAGE_BASE_URL}${aboutDetails.image.url}`,
                "title": "",
                "description": ""
              }
            ]}
            sIndex={sIndex}
            setSIndex={setSIndex}
          />
        </Row>
      </div>
    </>
  );
}

function AboutHksSection2(props) {
  const { aboutDetails } = props;
  return (
    <div className="artistprofilesection2__container">
      <Row className="artistprofilesection2__artist-intro">
        <Col span={24}>
          <div style={{ margin: "60px 0px" }}>
            <div
              className="ck-content"
            // className="artistprofilesection2__artist-description"
            // style={{
            //   fontSize: "1.4em",
            // }}
            >
              {ReactHtmlParser(aboutDetails.description)}
              {/* {parse(aboutDetails.description, options)} */}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

// connect  redux  store
const mapStateToProps = (state) => {
  return {
    about: state.about,
  };
};

export default withRouter(connect(mapStateToProps)(AboutHks));
