import api from "../api";
import to from "await-to-js";
import {
  l_getSectionContent,
  getSingle,
  l_searchTriangleKeywordWithPagination,
  findOne,
} from "../queryGenerator";

const triangleRequests = {
  getTriangleSection1: async (queryData) => {
    // console.log(queryData);
    let resourceString = await getSingle(queryData.resource);
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  searchTriangleByTitle: async (queryData) => {
    // console.log(queryData);
    let resourceString = await l_searchTriangleKeywordWithPagination(
      queryData.resource,
      queryData.language,
      queryData.param,
      queryData.string,
      queryData.start,
      queryData.limit
    );
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },

  getFeaturedTriangle: async (queryData) => {
    // console.log(queryData);
    let resourceString = await l_getSectionContent(
      queryData.resource,
      queryData.language,
      queryData.param,
      queryData.string
    );
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },

  getTriangleById: async (queryData) => {
    // console.log(queryData);
    let resourceString = await findOne(queryData.resource, queryData.id);

    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },

  getRelatedTriangles: async (queryData) => {
    // console.log(queryData);
    let resourceString = await l_getSectionContent(
      queryData.resource,
      queryData.language,
      queryData.param,
      queryData.string
    );
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },

  // listTriangles: async (queryData) => {
  //   // console.log(queryData);

  //   let resourceString = `${queryData.resource}?_start=${queryData.start}&_limit=${queryData.limit}&`;

  //   let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
  //   // console.log(error, response);

  //   if (response.status === "fail") {
  //     throw { ...response };
  //   } else {
  //     return response;
  //   }
  // },
};

export default triangleRequests;
