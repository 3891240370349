import React from "react";

import {
  MainNavBar,
  Footer,
  BookstoreSection1,
  BookstoreSection2,
  LineSeperator,
  AnnouncementBar,
} from "../../UIcomponents/UiComponents";

import { updatePageHead } from "../../../common/UpdateMetaAndTitle";

import { useQuery } from "react-query";
import bookshopRequests from "../../../services/helpers/bookshopquery";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";

export default function Triangle(props) {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  //update page head
  updatePageHead(`${t("Bookshop + Cafe")}`, "", "");

  // search query using usequery
  // search/list all triangles
  let queryData = {
    language: appLanguage,
    resource: "bookshop-sections",
  };

  const bookShopSectionsQueryResult = useQuery(
    ["listAllBookshopSections", queryData],
    () => bookshopRequests.listAllBookshopSections(queryData)
  );

  return (
    <>
      <MainNavBar
        pageTitle={`${t("Bookshop + Cafe")}`}
        bgClass={"bg-highlight"}
      />

      <div className="content-container-wrapper bg-highlight">
        <div className="content-container-content">
          {bookShopSectionsQueryResult.data &&
            bookShopSectionsQueryResult.data.map((sectionInfo) => {
              return (
                <BookstoreSection1
                  key={sectionInfo.title}
                  sectionInfo={sectionInfo}
                />
              );
            })}
          {/* <BookstoreSection2 /> */}
        </div>
      </div>
      <LineSeperator />
      <Footer />
    </>
  );
}
