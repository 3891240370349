import React, { useState, useEffect } from "react";

import "./styles.css";

import { CloseOutlined } from "@ant-design/icons";

import { useQuery } from "react-query";
import announcementRequests from "../../../services/helpers/announcementquery";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";

export default function AnnouncementBar(props) {
  const { setStateOfAnnouncementMessage } = props;
  let [announcementVisible, setAnnouncementVisible] = useState(true);

  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  // search query using usequery
  // search/list all triangles
  let queryData = {
    language: appLanguage,
    resource: "announcements",
  };

  const getAllAnnouncementsQueryResult = useQuery(
    ["getAllAnnouncements", queryData],
    () => announcementRequests.getAllAnnouncements(queryData)
  );

  const toggleAnnouncementBar = () => {
    setAnnouncementVisible(!announcementVisible);
    setStateOfAnnouncementMessage(true);
  };
  // console.log(announcementVisible);

  return (
    <div className="content-container-wrapper bg-highlight">
      <div className="content-container-content">
        {announcementVisible && (
          <>
            {getAllAnnouncementsQueryResult.data &&
              getAllAnnouncementsQueryResult.data.length > 0 && (
                <div className="announcementbar__container">
                  <span>
                    {getAllAnnouncementsQueryResult.data[0].announcement}{" "}
                  </span>
                  <span>
                    <CloseOutlined
                      onClick={() => {
                        toggleAnnouncementBar();
                      }}
                    />
                  </span>
                </div>
              )}
          </>
        )}
      </div>
    </div>
  );
}
