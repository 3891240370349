import React from "react";

import "./styles.css";
import { Button } from "antd";

import {
  DisplayProgramResult,
  DisplayTriangleResult,
  DisplayOpportunitiesResult,
  DisplayArtistsResult,
} from "./DisplaySectionBasedOnResource";

export default function GlobalSearchDisplaySection(props) {
  const { searchResultData, handlePagination, pagination } = props;

  return (
    <div className="globalsearchdisplay__container">
      {searchResultData.length > 0 &&
        searchResultData.map((item, index) => {
          return (
            <div>
              <>
                {item.type === "programs" ? (
                  <DisplayProgramResult resultList={item.data} />
                ) : null}
              </>
              <>
                {item.type === "triangles" ? (
                  <DisplayTriangleResult resultList={item.data} />
                ) : null}
              </>
              <>
                {item.type === "opportunities" ? (
                  <DisplayOpportunitiesResult resultList={item.data} />
                ) : null}
              </>
              <>
                {item.type === "artists" ? (
                  <DisplayArtistsResult resultList={item.data} />
                ) : null}
              </>
            </div>
          );
        })}

      <>
        {
          pagination.isNextPageAvailable ?

            <div className="content__load-more">
              <Button
                className="content__load-more-button"
                onClick={() => { handlePagination() }}
              >
                Load More
              </Button>
            </div>
            :
            <div className="content__load-more">
              <p className="content__load-more-text">No more results</p>
            </div>
        }
      </>
    </div>
  );
}
