import api from "../api";
import to from "await-to-js";
import { findOne } from "../queryGenerator";

const projectRequests = {
  getProjectById: async (queryData) => {
    // console.log(queryData);
    let resourceString = await findOne(queryData.resource, queryData.id);

    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
};

export default projectRequests;
