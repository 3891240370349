import React, { useEffect, useState } from "react";
import "./styles.css";

import ReactHtmlParser from "react-html-parser";

import { Row, Col } from "antd";
import { IMAGE_BASE_URL } from "../../../../services/api";
import { Link } from "react-router-dom";

export default function ArtAndPublicSection2(props) {
  const { t } = props
  const { sectionInfo } = props;

  const [showHoverEffect, setShowHoverEffect] = useState(false)
  const [showHoverEffectId, setShowHoverEffectId] = useState(null)
  const [showHoverEffectType, setShowHoverEffectType] = useState(null)

  const [showMainImage, setShowMainImage] = useState(false)

  useEffect(() => {
    if (sectionInfo && sectionInfo.mainImage && sectionInfo.mainImage.url) {
      if (sectionInfo.programs.length === 0 && sectionInfo.triangles.length === 0 && sectionInfo.projects.length === 0) {
        setShowMainImage(true)
      }
    }
  }, [sectionInfo])

  const onHoverStyleText = {
    textDecoration: "underline"
  }
  const onHoverStyleImage = {
    opacity: 0.8
  }
  const onMouseHoverHeadingOrImage = (id, type) => {
    setShowHoverEffectId(id)
    setShowHoverEffect(true)
    setShowHoverEffectType(type)
  }
  const onMouseRemoveHeadingOrImage = (id, type) => {
    setShowHoverEffectId(null)
    setShowHoverEffect(false)
    setShowHoverEffectType(type)
  }

  return (
    <>{sectionInfo && (
      <div className="artandpublicsection2__container">
        <p className="artandpublicsection2__title">{sectionInfo.title}</p>

        <Row gutter={48} className="artandpublicsection2__content ">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 10 }}
            xl={{ span: 10 }}
            xxl={{ span: 10 }}
          >
            <div>
              <div className="artandpublicsection2__content-description lh-1-4em">
                <div className="ck-content">
                  {ReactHtmlParser(sectionInfo.description)}
                </div>
              </div>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 2 }}
            xl={{ span: 2 }}
            xxl={{ span: 2 }}
          ></Col>
          {
            showMainImage ?
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                <div className="artandpublicsection2__img">
                  {sectionInfo?.mainImage && (
                    <img
                      src={`${IMAGE_BASE_URL}${sectionInfo?.mainImage?.url}`}
                    />
                  )}
                  <p className="artandpublicsection2__img-caption">
                    {sectionInfo?.mainImage_caption}
                  </p>
                </div>
              </Col>
              :
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 12 }}
                xl={{ span: 12 }}
                xxl={{ span: 12 }}
              >
                {sectionInfo.programs && sectionInfo.programs.length > 0 ||
                  sectionInfo.triangles && sectionInfo.triangles.length > 0 ||
                  sectionInfo.projects && sectionInfo.projects.length > 0 ? (
                  <p className="artandpublicsection2__right-title fs-1-1em text-uppercase"
                    style={{
                      fontSize: 'inherit !important'
                    }}>
                    {t("Current projects")}
                  </p>
                ) : null}

                {sectionInfo.programs &&
                  sectionInfo.programs.map((programItem) => {
                    return (
                      <div
                        className="artandpublicsection2__project-item"
                        key={programItem.id}
                      >
                        <Link to={`/program/${programItem.id}`}>
                          <p className="fs-2em lh-1-2em"
                            style={showHoverEffect && showHoverEffectId === programItem.id && showHoverEffectType === 'program' ? { ...onHoverStyleText } : null}
                            onMouseOver={() => onMouseHoverHeadingOrImage(programItem.id, 'program')}
                            onMouseOut={() => onMouseRemoveHeadingOrImage(programItem.id, 'program')}
                          >{programItem.title}</p>

                          <div className="artandpublicsection2__img">
                            {programItem.mainImg && (
                              <img
                                src={`${IMAGE_BASE_URL}${programItem.mainImg.url}`}
                                style={showHoverEffect && showHoverEffectId === programItem.id && showHoverEffectType === 'program' ? { ...onHoverStyleImage } : null}
                                onMouseOver={() => onMouseHoverHeadingOrImage(programItem.id, 'program')}
                                onMouseOut={() => onMouseRemoveHeadingOrImage(programItem.id, 'program')}
                              />
                            )}
                            <p className="artandpublicsection2__img-caption">
                              {programItem.mainImg_caption}
                            </p>
                          </div>
                        </Link>
                      </div>
                    );
                  })
                }

                {sectionInfo.triangles &&
                  sectionInfo.triangles.map((triangleItem) => {
                    return (
                      <div
                        className="artandpublicsection2__project-item"
                        key={triangleItem.id}
                      >
                        <Link to={`/triangle/${triangleItem.id}`}>
                          <p className="fs-2em lh-1-2em"
                            style={showHoverEffect && showHoverEffectId === triangleItem.id && showHoverEffectType === 'triangle' ? { ...onHoverStyleText } : null}
                            onMouseOver={() => onMouseHoverHeadingOrImage(triangleItem.id, 'triangle')}
                            onMouseOut={() => onMouseRemoveHeadingOrImage(triangleItem.id, 'triangle')}
                          >{triangleItem.title}</p>

                          <div className="artandpublicsection2__img">
                            {triangleItem.mainImage && (
                              <img
                                src={`${IMAGE_BASE_URL}${triangleItem.mainImage.url}`}
                                style={showHoverEffect && showHoverEffectId === triangleItem.id && showHoverEffectType === 'triangle' ? { ...onHoverStyleImage } : null}
                                onMouseOver={() => onMouseHoverHeadingOrImage(triangleItem.id, 'triangle')}
                                onMouseOut={() => onMouseRemoveHeadingOrImage(triangleItem.id, 'triangle')}
                              />
                            )}
                            <p className="artandpublicsection2__img-caption">
                              {triangleItem.mainImage_caption}
                            </p>
                          </div>
                        </Link>
                      </div>
                    );
                  })}

                {sectionInfo.projects &&
                  sectionInfo.projects.map((projectItem) => {
                    return (
                      <div
                        className="artandpublicsection2__project-item"
                        key={projectItem.id}
                      >
                        <Link to={`/projects/${projectItem.id}`}>
                          <p className="fs-2em lh-1-2em"
                            style={showHoverEffect && showHoverEffectId === projectItem.id && showHoverEffectType === 'project' ? { ...onHoverStyleText } : null}
                            onMouseOver={() => onMouseHoverHeadingOrImage(projectItem.id, 'project')}
                            onMouseOut={() => onMouseRemoveHeadingOrImage(projectItem.id, 'project')}
                          >{projectItem.title}</p>

                          <div className="artandpublicsection2__img">
                            {projectItem.image1 && (
                              <img
                                src={`${IMAGE_BASE_URL}${projectItem.image1.url}`}
                                style={showHoverEffect && showHoverEffectId === projectItem.id && showHoverEffectType === 'project' ? { ...onHoverStyleImage } : null}
                                onMouseOver={() => onMouseHoverHeadingOrImage(projectItem.id, 'project')}
                                onMouseOut={() => onMouseRemoveHeadingOrImage(projectItem.id, 'project')}
                              />
                            )}
                            <p className="artandpublicsection2__img-caption">
                              {projectItem.image1.image1Caption}
                            </p>
                          </div>
                        </Link>
                      </div>
                    );
                  })}
              </Col>
          }
        </Row>
      </div>
    )
    }</>
  );
}
