import api from "../../api";
import to from "await-to-js";
import qs from 'qs'

const programsSearchRequests = {
    searchPrograms: async (queryData, searchCase) => {
        let newerThan = queryData.newerThan || null;
        let queries = [];
        
        const common = {
            _start: queryData.start,
            _limit: queryData.limit,
            _sort: `dateStart:${queryData.sortType}`,
        };

        if (searchCase === 'keyword') {
            queries.push({
                ...common,
                _where: {
                    language_eq: queryData.lang,
                    ...(newerThan ? { dateEnd_lt: newerThan } : {}),
                    ... (((queryData.string || '').length > 0) ? {
                        _or: [
                            [{ title_contains: queryData.string }],
                        ]
                    } : {})
                },
            });
            if ((queryData.string || '').length > 0) {
                queries.push({
                    ...common,
                    _where: {
                        language_eq: queryData.lang,
                        ...(newerThan ? { dateEnd_lt: newerThan } : {}),
                        'artist.name_contains': queryData.string
                    },
                });
            }
        } else if (searchCase === 'tag') {
            queries.push({
                ...common,
                _where: {
                    language_eq: queryData.lang,
                    ...(newerThan ? { dateEnd_lt: newerThan } : {}),
                    _or: [
                        [{ 'tags.tagName_contains': queryData.param }]
                    ]
                },
            });
        } else if (searchCase === 'year') {
            queries.push({
                ...common,
                _where: {
                    language_eq: queryData.lang,
                    ...(newerThan ? { dateEnd_lt: newerThan } : {}),
                    _or: [
                        [{ 'year.year_contains': queryData.param }]
                    ]
                },
            });
        } else if (searchCase === 'keywordTag') {
            queries.push({
                ...common,
                _where: {
                    language_eq: queryData.lang,
                    ...(newerThan ? { dateEnd_lt: newerThan } : {}),
                    'tags.tagName_eq': queryData.param,
                    ... (((queryData.string || '').length > 0) ? {
                        _or: [
                            [{ title_contains: queryData.string }],
                        ]
                    } : {})
                },
            });

            if ((queryData.string || '').length > 0) {
                queries.push({
                    ...common,
                    _where: {
                        language_eq: queryData.lang,
                        ...(newerThan ? { dateEnd_lt: newerThan } : {}),
                        'tags.tagName_eq': queryData.param,
                        _or: [
                            { 'artist.name_contains': queryData.string },
                        ]
                    },
                });
            }
        } else if (searchCase === 'keywordYear') {
            queries.push({
                ...common,
                _where: {
                    language_eq: queryData.lang,
                    ...(newerThan ? { dateEnd_lt: newerThan } : {}),
                    'year.year_eq': queryData.param,
                    ... (((queryData.string || '').length > 0) ? {
                        _or: [
                            [{ title_contains: queryData.string }],
                        ]
                    } : {})
                },
            });
            if ((queryData.string || '').length > 0) {
                queries.push({
                    ...common,
                    _where: {
                        language_eq: queryData.lang,
                        ...(newerThan ? { dateEnd_lt: newerThan } : {}),
                        'year.year_eq': queryData.param,
                        _or: [
                            { 'artist.name_contains': queryData.string },
                        ]
                    },
                });
            }
        } else if (searchCase === 'keywordTagYear') {
            queries.push({
                ...common,
                _where: {
                    language_eq: queryData.lang,
                    ...(newerThan ? { dateEnd_lt: newerThan } : {}),
                    'tags.tagName_eq': queryData.param,
                    'year.year_eq': queryData.secondParam,
                    ... (((queryData.string || '').length > 0) ? {
                        _or: [
                            [{ title_contains: queryData.string }],
                        ]
                    } : {})
                },
            });
            if ((queryData.string || '').length > 0) {
                queries.push({
                    ...common,
                    _where: {
                        language_eq: queryData.lang,
                        ...(newerThan ? { dateEnd_lt: newerThan } : {}),
                        'tags.tagName_eq': queryData.param,
                        'year.year_eq': queryData.secondParam,
                        _or: [
                            { 'artist.name_contains': queryData.string },
                        ]
                    },
                });
            }
        } else if (searchCase === 'tagYear') {
            queries.push({
                ...common,
                _where: {
                    language_eq: queryData.lang,
                    ...(newerThan ? { dateEnd_lt: newerThan } : {}),
                    'tags.tagName_eq': queryData.param,
                    'year.year_eq': queryData.secondParam,
                },
            });
        }

        queries = queries
            .map(query => qs.stringify(query));

        const result = [];
        await Promise.all(queries.map(async (query) => {
            let resourceString = `programs?${query}`
            let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
            if (error) {
                throw error
            }
            if (response.status === "fail") {
                throw { ...response };
            } else {
                const existingIds = result.map(a => a.id);
                result.push(...response.filter(article => !existingIds.includes(article.id)));
            }
        }));

        return result
            .sort(function (a, b) {
                return (queryData.sortType == 'DESC') ? (new Date(a).getTime() - new Date(b).getTime()) :
                    (new Date(b).getTime() - new Date(a).getTime());
            });
    },

};

export default programsSearchRequests;
