import React, { useState, useEffect } from "react";

import {
  MainNavBar,
  Footer,
  TriangleSection1,
  TriangleSection2,
  TriangleSection3,
  TriangleSection4,
  AnnouncementBar,
  SimpleSectionOne,
} from "../../UIcomponents/UiComponents";

import { updatePageHead } from "../../../common/UpdateMetaAndTitle";

import { Button } from "antd";

import { useQuery } from "react-query"

import trianglesSearchRequests from '../../../services/helpers/search/triangles-search'
import triangleRequests from "../../../services/helpers/trianglequery";
import tagsRequests from "../../../services/helpers/tagsquery";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";

export default function Triangle() {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  //update page head
  updatePageHead("Triangle", "", "");
  //
  let [tagList, setTagList] = useState(null);

  let [yearList, setYearList] = useState(null);
  let [filterType, setFilterType] = useState(null);

  let [totalData, setTotalData] = useState([]);
  let [currentSorting, setCurrentSorting] = useState('DESC')

  let [searchKeyword, setSearchKeyword] = useState("");
  let [selectedYear, setSelectedYear] = useState(null)
  let [selectedTag, setSelectedTag] = useState(null)

  let [pagination, setPagination] = useState({
    start: 0,
    limit: 4,
    step: 4,
    page: 1,
    isNextPageAvailable: true,
  });

  // get section 1
  let queryDataSectionOne = {
    resource: "triangle-section-one",
  };

  const triangleSection1QueryResult = useQuery(
    ["getTriangleSection1", queryDataSectionOne],
    () => triangleRequests.getTriangleSection1(queryDataSectionOne)
  );

  // list all tags by type program

  //all  years
  let queryDataGetYears = {
    resource: "years",
  };
  const yearListQuery = useQuery(["getAllYears", queryDataGetYears], () =>
    tagsRequests.getAllYears(queryDataGetYears)
  );


  // all tags
  let queryDataGetTags = {
    resource: "tag-types",
    param: "type",
    string: "triangles_tags",
    language: i18n.language
  };
  const triangleTags = useQuery(["trianglesTags", queryDataGetTags], () =>
    tagsRequests.getTagTypeByName(queryDataGetTags)
  );

  useEffect(() => {
    let tempTagList = [];
    if (triangleTags.data && triangleTags.data.length > 0) {
      let x = triangleTags.data[0].tags;
      // console.log(x);
      let _filteredTagsByLanguage = x.filter((tagItem) => tagItem.language === i18n.language)
      tempTagList = _filteredTagsByLanguage.map((tagItem) => {
        return tagItem.tagName;
      });
    }
    setTagList(tempTagList);
  }, [triangleTags.data]);

  // effect to set the lsit  of  all  years
  useEffect(() => {
    let tempYearList = [];
    if (yearListQuery.data && yearListQuery.data.length > 0) {
      let x = yearListQuery.data;
      // console.log(x);
      tempYearList = x.map((item) => {
        return item.year;
      });
    }
    setYearList(tempYearList);
  }, [yearListQuery.data]);


  // clear filter
  let clearFilter = () => {
    setSearchKeyword('')
    setCurrentSorting('DESC')
    setSelectedYear(null)
    setSelectedTag(null)
  };

  // pagination
  let handlePagination = () => {
    let paginateData = [...totalData]

    // clearFilter();
    let totalrows = paginateData.length;
    let x = pagination;
    if (x.isNextPageAvailable) {
      setPagination({
        ...x,
        start: x.start,
        limit: x.limit + x.step,
        page: x.page + 1,
      });
    }
    if (totalrows < x.limit) {
      setPagination({
        ...x,
        isNextPageAvailable: false,
      });
    }
  };

  const filterForKeyword = () => {
    let queryData = {
      lang: i18n.language,
      string: searchKeyword,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    };
    let response = trianglesSearchRequests.searchTriangles(queryData, 'keyword')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForTag = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    };
    let response = trianglesSearchRequests.searchTriangles(queryData, 'tag')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForYear = () => {
    // query based on year
    let queryData = {
      lang: i18n.language,
      param: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = trianglesSearchRequests.searchTriangles(queryData, 'year')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForKeywordAndYear = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedYear,
      string: searchKeyword,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = trianglesSearchRequests.searchTriangles(queryData, 'keywordYear')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForKeywordAndTag = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      string: searchKeyword,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = trianglesSearchRequests.searchTriangles(queryData, 'keywordTag')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForTagAndYear = () => {
    let queryData = {
      lang: i18n.language,
      param: selectedTag,
      secondParam: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = trianglesSearchRequests.searchTriangles(queryData, 'tagYear')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }
  const filterForKeywordAndTagAndYear = () => {
    let queryData = {
      lang: i18n.language,
      string: searchKeyword,
      param: selectedTag,
      secondParam: selectedYear,
      start: pagination.start,
      limit: pagination.limit,
      sortType: currentSorting
    }
    let response = trianglesSearchRequests.searchTriangles(queryData, 'keywordTagYear')
    response.then(res => {
      if (res) {
        setTotalData(res);
      }
    })
  }

  const initiateSearch = () => {
    // usecases
    if (searchKeyword.length > 0) {
      if (!selectedTag && !selectedYear) {
        filterForKeyword()
      }
      if (!selectedTag && selectedYear) {
        filterForKeywordAndYear()
      }
      if (selectedTag && !selectedYear) {
        filterForKeywordAndTag()
      }
      if (selectedTag && selectedYear) {
        filterForKeywordAndTagAndYear()
      }
    } else {
      if (selectedTag && !selectedYear) {
        filterForTag()
      }
      if (!selectedTag && selectedYear) {
        filterForYear()
      }
      if (selectedTag && selectedYear) {
        filterForTagAndYear()
      }
      if (!selectedTag && !selectedYear) {
        filterForKeyword()
      }
    }
  }
  useEffect(() => {
    initiateSearch()
  }, [selectedTag, selectedYear, searchKeyword, currentSorting, pagination.limit, i18n.language])

  return (
    <>
      <MainNavBar pageTitle={`${t("Triangle")}`} bgClass="bg-white" />

      <div className="content-container-wrapper">
        <div className="content-container-content">

          {/* section 1 */}
          {triangleSection1QueryResult.isLoading && <p>{t("Loading")}...</p>}

          {triangleSection1QueryResult.data && (
            <SimpleSectionOne
              queryData={triangleSection1QueryResult.data}
              appLanguage={appLanguage}
            />
          )}



          {/* search filter */}
          <TriangleSection1
            // search handle
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}

            // selected tag
            setSelectedTag={setSelectedTag}
            selectedTag={selectedTag}

            // selected year
            selectedYear={selectedYear}
            setSelectedYear={setSelectedYear}

            // tags
            tagList={tagList}
            yearList={yearList}
            // sort
            currentSorting={currentSorting}
            setCurrentSorting={setCurrentSorting}
            // filter
            clearFilter={clearFilter}
            filterType={filterType}
            setFilterType={setFilterType}
          />

          {totalData && totalData.length <= 0 ? (
            <p className="mar-t-10">{t("No Results")}</p>
          ) : null}

          {totalData &&
            totalData.map((item) => {
              return (
                <div key={item.title}>
                  <>
                    {item.type === "literaryResponse" ? (
                      <TriangleSection2 queryData={item} />
                    ) : null}
                  </>
                  <>
                    {item.type === "article" || item.type === "archiveStory" ? (
                      <TriangleSection3 queryData={item} />
                    ) : null}
                  </>
                  <>
                    {item.type === "interview" ? (
                      <TriangleSection4 queryData={item} />
                    ) : null}
                  </>
                </div>
              );
            })
          }

          {/* load more section here */}

          <>
            {pagination.isNextPageAvailable ? (
              <div className="content__load-more">
                <Button
                  className="content__load-more-button"
                  onClick={handlePagination}
                >
                  {t("Load More")}
                </Button>
              </div>
            ) : (
              <div className="content__load-more">
                <p className="content__load-more-text">
                  {t("No more results")}
                </p>
              </div>
            )}
          </>

          {/* this  is layout for  literary response */}
          {/* <TriangleSection2 /> */}

          {/* this is layout for archive  story */}
          {/* <TriangleSection3 /> */}

          {/* this is layout for article */}
          {/* <TriangleSection4 /> */}

          {/* unused for now */}
          {/* <TriangleSection5 /> */}
          {/* <TriangleSection6 /> */}
          {/* <TriangleSection7 /> */}
        </div>
      </div>
      <Footer />
    </>
  );
}
