import React, { useState } from "react";
import "./styles.css";

import { Row, Col, Button } from "antd";
import { IMAGE_BASE_URL } from "../../../../services/api";
import LocalizationContext from "../../../../localization/LocalizationContext";
import { Link } from "react-router-dom";

import { TagsDisplay } from "../../UiComponents";
import { formatDate } from "../../../helper/formatDate";

export default function HomeSection2(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const { isLoading, data, error } = props.queryData;

  const [showHoverEffect, setShowHoverEffect] = useState(false)

  const onHoverStyleText = {
    textDecoration: "underline"
  }
  const onHoverStyleImage = {
    opacity: 0.8
  }
  const onMouseHoverHeadingOrImage = () => {
    setShowHoverEffect(true)
  }
  const onMouseRemoveHeadingOrImage = () => {
    setShowHoverEffect(false)
  }


  return (
    <div className="homesection2__container">
      {isLoading && <p>loading...</p>}
      {data && data.length >= 1 && (
        <Row className="homesection2__content-row">
          <Col
            xs={{ span: 24, order: 2 }}
            sm={{ span: 24, order: 2 }}
            md={{ span: 24, order: 2 }}
            lg={{ span: 12, order: 1 }}
            xl={{ span: 12, order: 1 }}
            xxl={{ span: 12, order: 1 }}
          >
            <div className="homesection2__text">
              <div className="homesection2__text-top">
                <p className="homesection2__text-line1">
                  {formatDate(data[0].dateStart)} -{" "}
                  {formatDate(data[0].dateEnd)}
                </p>
                <Link to={`/program/${data[0].id}`}>
                  <p className="homesection2__text-line2 fs-2-5em lh-1-2em"
                    style={showHoverEffect ? { ...onHoverStyleText } : null}
                    onMouseOver={onMouseHoverHeadingOrImage}
                    onMouseOut={onMouseRemoveHeadingOrImage}
                  >
                    {data[0].artist && <span>{`${data[0].artist.name}`}</span>}{" "}
                  </p>
                  <p className="homesection2__text-line2 text-fabric-serif-italic fs-2-5em lh-1-2em"
                    style={showHoverEffect ? { ...onHoverStyleText } : null}
                    onMouseOver={onMouseHoverHeadingOrImage}
                    onMouseOut={onMouseRemoveHeadingOrImage}
                  >
                    {`${data[0].title}`}
                  </p>
                </Link>
              </div>
              <div className="homesection2__text-bottom">
                <p className="homesection2__text-description fs-1-2em lh-1-4em">
                  {data[0].shortDescription && <>{`${data[0].shortDescription} (...)`}</>}
                </p>

                <TagsDisplay tagList={data[0].tags} />
              </div>
            </div>
          </Col>
          {/* <Col
            xs={{ span: 24, order: 3 }}
            sm={{ span: 24, order: 3 }}
            md={{ span: 24, order: 3 }}
            lg={{ span: 3, order: 2 }}
            xl={{ span: 3, order: 2 }}
            xxl={{ span: 3, order: 2 }}
          ></Col> */}
          <Col
            xs={{ span: 24, order: 1 }}
            sm={{ span: 24, order: 1 }}
            md={{ span: 24, order: 1 }}
            lg={{ span: 12, order: 3 }}
            xl={{ span: 12, order: 3 }}
            xxl={{ span: 12, order: 3 }}
          >
            {data[0].mainImg && (
              <div className="homesection2__image">
                <Link to={`/program/${data[0].id}`}>
                  <img src={`${IMAGE_BASE_URL}${data[0].mainImg.url}`}
                    style={showHoverEffect ? { ...onHoverStyleImage } : null}
                    onMouseOver={onMouseHoverHeadingOrImage}
                    onMouseOut={onMouseRemoveHeadingOrImage}
                  />
                </Link>
              </div>
            )}
          </Col>
        </Row>
      )}
    </div>
  );
}
