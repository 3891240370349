import api from "../api";
import to from "await-to-js";
import { l_listAll } from "../queryGenerator";

const cookieRequests = {
  getAllCookieNotifications: async (queryData) => {
    // console.log(queryData);
    let resourceString = await l_listAll(
      queryData.resource,
      queryData.language
    );
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
};

export default cookieRequests;
