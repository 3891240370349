import React, { useState } from "react";
import "./styles.css";
import { IMAGE_BASE_URL } from "../../../../services/api";
import { formatDate } from "../../../helper/formatDate";

import { Row, Col } from "antd";
import { TagsDisplay } from "../../UiComponents";
import { LightBoxSimple } from "../../lightboxsimple/LightBoxSimple";
export default function ProgramPageSection1(props) {
  const { isLoading, data, error } = props.queryData;

  const [toggle, setToggle] = useState(false);
  const [sIndex, setSIndex] = useState(0);

  // Handler
  const lightBoxHandler = (state, sIndex) => {
    setToggle(state);
    setSIndex(sIndex);
  };

  return (
    <>
      {data && data.mainImg && (
        <>
          <div className="programpagesection1__container">
            <Row>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 18 }}
                xl={{ span: 18 }}
                xxl={{ span: 18 }}
              >
                <p className="programgrid__program-info-date">
                  {formatDate(data.dateStart)} - {formatDate(data.dateEnd)}
                </p>
                <p className="programpagesection1__title fs-2-5em lh-1-2em">
                  {data.artist && data.artist.name}
                </p>
                <div className="programpagesection1__artist-name text-fabric-serif-italic fs-2-5em lh-1-2em">
                  {data.title}
                </div>
              </Col>
            </Row>

            <div className="mar-t-10"></div>

            <Row>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 18 }}
                xl={{ span: 18 }}
                xxl={{ span: 18 }}
              >
                <TagsDisplay tagList={data.tags} />
              </Col>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}
                xl={{ span: 6 }}
                xxl={{ span: 6 }}
              >
                {data.description_document && (
                  <div className="text-fabric-sans has-link text-underline fs-1-1em">
                    <a
                      href={`${IMAGE_BASE_URL}${data.description_document.url}`}
                    >
                      Download Pdf
                    </a>
                  </div>
                )}
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                {data.mainImg && (
                  <div className="programpagesection1__img">
                    <img src={`${IMAGE_BASE_URL}${data.mainImg.url}`}
                      onClick={() => {
                        lightBoxHandler(true, 0);
                      }}
                    />
                  </div>
                )}
                <p className="programpagesection1__img-caption">
                  {data.mainImg_caption}
                </p>
              </Col>
            </Row>
            <LightBoxSimple
              toggle={toggle}
              lightBoxHandler={lightBoxHandler}
              data={[
                {
                  "id": 1,
                  "image": `${IMAGE_BASE_URL}${data.mainImg.url}`,
                  "title": "",
                  "description": ""
                }
              ]}
              sIndex={sIndex}
              setSIndex={setSIndex}
            />
          </div>
        </>
      )}
    </>
  );
}
