import React from "react";

import {
  MainNavBar,
  ArtistProfileSection1,
  ArtistProfileSection2,
  ArtistProfileSection3,
  Footer,
  LineSeperator,
} from "../../UIcomponents/UiComponents";
import { useQuery } from "react-query";
import artistsRequests from "../../../services/helpers/artistsquery";
import { updatePageHead } from "../../../common/UpdateMetaAndTitle";
import { withRouter } from "react-router";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";

function ArtistProfile(props) {
  updatePageHead("Artist In  Residency", "", "");

  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  const { profileID } = props.match.params;

  //getArtistByID from url
  let queryDataGetArtist = {
    resource: "artists",
    id: profileID,
  };
  const getArtistdyIDQueryData = useQuery(
    ["getArtistdyID", queryDataGetArtist],
    () => artistsRequests.getArtistdyID(queryDataGetArtist)
  );

  return (
    <>
      {getArtistdyIDQueryData.data && (
        <>
          <MainNavBar
            backUrl="/artistinresidency"
            pageTitle={`${getArtistdyIDQueryData.data.name}`}
            bgClass="bg-white"
          />
          <div className="content-container-wrapper">
            <div className="content-container-content">
              <>
                <ArtistProfileSection1
                  queryData={getArtistdyIDQueryData}
                  t={t}
                  appLanguage={appLanguage}
                />
                <ArtistProfileSection2
                  queryData={getArtistdyIDQueryData}
                  t={t}
                  appLanguage={appLanguage}
                />
                <ArtistProfileSection3
                  queryData={getArtistdyIDQueryData}
                  t={t}
                  appLanguage={appLanguage}
                />
              </>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
}

export default withRouter(ArtistProfile);
