import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import LocalizationContext from "../../../localization/LocalizationContext";

export default function TagsDisplay(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const { tagList } = props;

  let [tags, setTags] = useState(null);
  let [displayTags, setDisplayTags] = useState(null);
  let [showEllipsisTag, setShowEllipsisTag] = useState(false);

  useEffect(() => {
    let z = [];
    tagList &&
      tagList.map((tagItem) => {
        if (i18n.language === tagItem.language) {
          z.push(tagItem.tagName);
        }
      });
    setTags(z);
    setDisplayTags(z.slice(0, 2));
    if (z.length > 2) setShowEllipsisTag(true);
  }, [tagList]);

  const handleDisplayAllTags = () => {
    setDisplayTags(tags);
    setShowEllipsisTag(false);
  };
  const handleHideDisplayTags = () => {
    setDisplayTags(tags.slice(0, 2));
    setShowEllipsisTag(true);
  };

  return (
    <div className="tag-list">
      {displayTags &&
        displayTags.map((tag) => {
          return (
            <Link key={tag} to={`/search/tag/${tag}`}>
              <p className="tag-item">{tag}</p>
            </Link>
          );
        })}
      {showEllipsisTag ? (
        <p className="tag-item" onClick={handleDisplayAllTags}>
          . . .
        </p>
      ) : null}
    </div>
  );
}
