import React, { useState } from "react";
import "./style.css";
import { Row, Col } from "antd";

import { IMAGE_BASE_URL } from "../../../../services/api";
import { Link } from "react-router-dom";
import { TagsDisplay } from "../../UiComponents";
import { formatDate } from "../../../helper/formatDate";
export default function ProgramGrid(props) {
  const { data, isLoading, error } = props.queryData;

  const [showHoverEffect, setShowHoverEffect] = useState(false)
  const [showHoverEffectId, setShowHoverEffectId] = useState(null)

  const onHoverStyleText = {
    textDecoration: "underline"
  }
  const onHoverStyleImage = {
    opacity: 0.8
  }
  const onMouseHoverHeadingOrImage = (id) => {
    setShowHoverEffectId(id)
    setShowHoverEffect(true)
  }
  const onMouseRemoveHeadingOrImage = (id) => {
    setShowHoverEffectId(null)
    setShowHoverEffect(false)
  }

  let getColVal = (layoutString, baseCol) => {
    if (layoutString === "ONE") {
      return baseCol;
    } else if (layoutString === "TWO") {
      return baseCol * 2;
    } else if (layoutString === "THREE") {
      return baseCol * 3;
    } else return baseCol;
  };
  return (
    <div className="programgrid__container">
      {data && data.length >= 1 && (
        <Row gutter={35}>
          {data.map((item) => {
            return (
              <Col
                key={item.title}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: `${getColVal(item.layoutColumn, 6)}` }}
                xl={{ span: `${getColVal(item.layoutColumn, 6)}` }}
                xxl={{ span: `${getColVal(item.layoutColumn, 6)}` }}
                style={{ marginBottom: "35px" }}
                flex=""
              >
                <div className="programgrid__program-info-container">
                  <div className="programgrid__program-image">
                    <Link to={`/program/${item.id}`}>
                      <div className="programgrid__image-container">
                        {item.mainImg && (
                          <img src={`${IMAGE_BASE_URL}${item.mainImg.url}`}
                            style={showHoverEffect && showHoverEffectId === item.id ? { ...onHoverStyleImage } : null}
                            onMouseOver={() => onMouseHoverHeadingOrImage(item.id)}
                            onMouseOut={() => onMouseRemoveHeadingOrImage(item.id)}
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                  <p className="programgrid__program-info-date">
                    {formatDate(item.dateStart)} - {formatDate(item.dateEnd)}
                  </p>
                  <Link to={`/program/${item.id}`}>
                    <p className="programgrid__program-info-artist-name fs-1-4em lh-1-4em"
                      style={showHoverEffect && showHoverEffectId === item.id ? { ...onHoverStyleText } : null}
                      onMouseOver={() => onMouseHoverHeadingOrImage(item.id)}
                      onMouseOut={() => onMouseRemoveHeadingOrImage(item.id)}
                    >
                      {item.artist && item.artist.name}
                    </p>
                  </Link>


                  <Link to={`/program/${item.id}`}>
                    <p className="programgrid__program-info-program-title text-fabric-serif-italic fs-1-4em lh-1-4em"
                      style={showHoverEffect && showHoverEffectId === item.id ? { ...onHoverStyleText } : null}
                      onMouseOver={() => onMouseHoverHeadingOrImage(item.id)}
                      onMouseOut={() => onMouseRemoveHeadingOrImage(item.id)}
                    >
                      {item.title}
                    </p>
                  </Link>

                  <div style={{ margin: "20px" }}></div>
                  <TagsDisplay tagList={item.tags} />
                </div>
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
}
