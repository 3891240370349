import React from "react";
import "./styles.css";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";

import { TagsDisplay } from "../../UiComponents";

import { formatDate } from "../../../helper/formatDate";

export default function PossibilitiesSearchDisplay(props) {
  const { filteredData, handlePagination, pagination, t } = props;

  const opportunityString = {
    artConsultant: "Art Consultant",
    artInPublicSpace: "Art in Public Space",
    openCall: "Open Call",
    summerAcademy: "Summer Academy",
  };
  return (
    <div className="possibilitiessearchdisplay__container">
      {filteredData && filteredData.length < 1 ? <p className="mar-t-10">{t("No Results")}</p> :
        <>
          {filteredData.map((item) => {
            return (
              <Row
                className="possibilitiessearchdisplay__content-row"
                key={item.id}
              >
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 8 }}
                  xl={{ span: 8 }}
                  xxl={{ span: 8 }}
                >
                  <div className="possibilitiessearchdisplay__program-info">
                    <p className="possibilitiessearchdisplay__program-info-date">
                      {/* {t("Application Deadline")}:{" "} */}
                      {formatDate(item.applicationDeadline)}
                    </p>
                    <Link to={`/possibilities/${item.id}`}>
                      <p className="possibilitiessearchdisplay__program-info-title fs-1-6em lh-1-2em">
                        {item.title}
                      </p>
                    </Link>

                    {/* <p className="possibilitiessearchdisplay__program-info-line3">
                  Application deadline: {item.applicationDeadline}
                </p> */}

                    <TagsDisplay tagList={item.tags} />
                  </div>
                </Col>
              </Row>
            );
          })}

          <>
            {pagination.isNextPageAvailable ? (
              <div className="content__load-more">
                <Button
                  className="content__load-more-button"
                  onClick={handlePagination}
                >
                  {t("Load More")}
                </Button>
              </div>
            ) : (
              <div className="content__load-more">
                <p className="content__load-more-text">{t("No more results")}</p>
              </div>
            )}
          </>
        </>}
    </div>
  );
}
