import React from "react";
import "./styles.css";

import { Row, Col } from "antd";
import { Link } from "react-router-dom";

import ReactHtmlParser from "react-html-parser";

export default function SimpleSectionOne(props) {
  const { queryData, appLanguage } = props;

  const getDomForReadMoreLink = () => {
    let triangleItem = queryData?.triangle

    let eng_linkUrl = queryData?.eng_linkUrl
    let nor_linkUrl = queryData?.nor_linkUrl

    if (eng_linkUrl || nor_linkUrl) {
      return (
        <>
          {appLanguage === "en" &&
            <a href={queryData.eng_linkUrl}>
              <span className="simplesectionone__right-more-text fs-2-5em lh-1-2em hide-underline-link text-fabric-sans">
                {queryData.eng_textLink}
              </span>
            </a>
          }
          {
            appLanguage === "no" &&
            <a href={queryData.nor_linkUrl}>
              <span className="simplesectionone__right-more-text fs-2-5em lh-1-2em hide-underline-link text-fabric-sans">
                {queryData.nor_textLink}
              </span>
            </a>
          }
        </>
      )
    } else if (triangleItem) {
      return (
        <>
          {appLanguage === "en" &&
            <Link to={`/triangle/${triangleItem?.id}`}>
              <span className="simplesectionone__right-more-text fs-2-5em lh-1-2em hide-underline-link text-fabric-sans">
                {queryData.eng_textLink}
              </span>
            </Link>
          }
          {
            appLanguage === "no" &&
            <Link to={`/triangle/${triangleItem?.id}`}>
              <span className="simplesectionone__right-more-text fs-2-5em lh-1-2em hide-underline-link text-fabric-sans">
                {queryData.nor_textLink}
              </span>
            </Link>
          }
        </>
      )
    } else {
      return (
        <>
          {appLanguage === "en" &&
            <a href={queryData.eng_linkUrl}>
              <span className="simplesectionone__right-more-text fs-2-5em lh-1-2em hide-underline-link text-fabric-sans">
                {queryData.eng_textLink}
              </span>
            </a>
          }
          {
            appLanguage === "no" &&
            <a href={queryData.nor_linkUrl}>
              <span className="simplesectionone__right-more-text fs-2-5em lh-1-2em hide-underline-link text-fabric-sans">
                {queryData.nor_textLink}
              </span>
            </a>
          }
        </>
      )
    }

  }
  return (
    <div className="simplesectionone__container">
      <Row gutter={12}>
        {/* {data && ( */}
        <>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 16 }}
            xl={{ span: 16 }}
            xxl={{ span: 16 }}
          >
            <div className="simplesectionone__content">
              <p className="simplesectionone__content-title fs-2-5em lh-1-2em text-fabric-sans">
                {appLanguage === "en" && queryData.eng_title}
                {appLanguage === "no" && queryData.nor_title}
              </p>
              <p className="simplesectionone__content-description lh-1-4em text-fabric-sans">
                {appLanguage === "en" &&
                  ReactHtmlParser(queryData.eng_description)}
                {appLanguage === "no" &&
                  ReactHtmlParser(queryData.nor_description)}
              </p>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 8 }}
            xl={{ span: 8 }}
            xxl={{ span: 8 }}
          >
            <div className="simplesectionone__right">
              <p className="simplesectionone__right-more">
                {
                  getDomForReadMoreLink()
                }
              </p>
            </div>
          </Col>
        </>
        {/* )} */}
      </Row>
    </div>
  );
}
