import React from "react";

import "./styles.css";
export default function LineSeperator() {
  return (
    <div className="content-container-wrapper bg-highlight">
      <div className="content-container-content">
        <div className="lineseperator__"></div>
      </div>
    </div>
  );
}
