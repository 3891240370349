/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import "./styles.css";

import { Row, Col } from "antd";
import {
  InstagramOutlined,
  FacebookOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { IMAGE_BASE_URL } from "../../../../services/api";

import { Link, withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";

// language context
import LocalizationContext from "../../../../localization/LocalizationContext";

function AboutSection2(props) {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  const { data, isLoading, error } = props.queryData;
  const { about } = props;

  const [logoUrls, setLogoUrls] = useState([])

  useEffect(() => {
    if (about && about.aboutDetails && about.aboutDetails.supporterLogoUrl) {
      let list = about.aboutDetails.supporterLogoUrl.split('*')
      setLogoUrls(list)
    }
  }, [about])

  return (
    <div className="aboutsection2__container">
      <Row>
        {about && about.aboutDetails && (
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <div className="aboutsection2__left-container">
              <div className="aboutsection2__left-description">
                <div className="ck-content">
                  {ReactHtmlParser(
                    about.aboutDetails.shortDescription)}
                </div>
              </div>
              <Link to="/abouthks">
                <p className="aboutsection2__left-link fs-1-6em">
                  {t(
                    "Read more about the history of Hordaland Kunstsenter"
                  )}{" "}
                  →
                </p>
              </Link>

              <div className="aboutsection2__left-supporter">
                <div className="aboutsection2__left-supporter-text">
                  <div className="ck-content">
                    {ReactHtmlParser(
                      about.aboutDetails.supporterShortDescription)}
                  </div>
                </div>
              </div>
            </div>
          </Col>
        )}
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
          xxl={{ span: 6 }}
        ></Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 6 }}
          xl={{ span: 6 }}
          xxl={{ span: 6 }}
        >
          {about.aboutDetails && (
            <div className="aboutsection2__right-container">
              <div className="sidepanel__content-right__line1">
                <p className="sidepanel__content-right__title">
                  {t("Opening Hours")} :
                </p>
                <div className="sidepanel__content-right__text">
                  <div className="ck-content">
                    {ReactHtmlParser(about.aboutDetails.openingHours)}
                  </div>
                </div>
              </div>
              <div className="sidepanel__content-right__line1">
                <p className="sidepanel__content-right__title">
                  {t("Entrance")}:
                </p>
                <div className="sidepanel__content-right__text">
                  <div className="ck-content">
                    {ReactHtmlParser(about.aboutDetails.entrance)}
                  </div>
                </div>
              </div>
              <div className="sidepanel__content-right__line1">
                <p className="sidepanel__content-right__title">
                  {t("Contact")}:
                </p>
                <div className="sidepanel__content-right__text">
                  <div className="ck-content">
                    {ReactHtmlParser(about.aboutDetails.contact)}
                  </div>
                </div>
              </div>
              <div className="sidepanel__content-right__line1">
                <div className="sidepanel__content-right__text">
                  <div className="ck-content">
                    {ReactHtmlParser(about.aboutDetails.address)}
                  </div>
                </div>
              </div>
              <div className="sidepanel__content-right-icons">
                <a href={`${about.aboutDetails.instagram}`}>
                  <i className="fab fa-instagram"></i>
                  {/* <InstagramOutlined /> */}
                </a>
                <a href={`${about.aboutDetails.facebook}`}>
                  {/* <FacebookOutlined /> */}
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href={`${about.aboutDetails.youtube}`}>
                  <i className="fab fa-youtube"></i>
                  {/* <YoutubeOutlined /> */}
                </a>
              </div>
            </div>
          )}
        </Col>
      </Row>
      <Row>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          xl={{ span: 24 }}
          xxl={{ span: 24 }}
        >
          <div className="aboutsection2__left-supporter">
            <div className="aboutsection2__left-supporter-logos">
              {
                about?.aboutDetails?.supporterLogo.length > 0 && about?.aboutDetails?.supporterLogo?.map((logoItem, index) => {
                  return (
                    <a href={logoUrls.length > 0 && logoUrls[index]} target="_blank">
                      <div className="aboutsection2__left-supporter-logo" key={index}>
                        {logoItem && (
                          <img
                            src={`${IMAGE_BASE_URL}${logoItem.url}`}
                          />
                        )}
                      </div>
                    </a>
                  )
                })
              }
            </div>
          </div>

        </Col>

      </Row>
    </div>
  );
}

// connect  redux  store
const mapStateToProps = (state) => {
  return {
    about: state.about,
  };
};

export default withRouter(connect(mapStateToProps)(AboutSection2));
