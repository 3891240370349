import React, { useState, useEffect } from "react";

import {
  MainNavBar,
  TrianglePageSection1,
  AuthorSection,
  TrianglePageSection3,
  RenderArticleContent,
  Footer,
} from "../../UIcomponents/UiComponents";

import { updatePageHead } from "../../../common/UpdateMetaAndTitle";

import { withRouter } from "react-router";

import { useQuery } from "react-query";
import triangleRequests from "../../../services/helpers/trianglequery";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";

function TrianglePage(props) {
  updatePageHead("Triangle", "", "");

  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  // initial article language while page  loading
  let [articleLoadedLanguage, setArticleLoadedLanguage] = useState(null);

  // console.log(props);
  const { triangleID } = props.match.params;

  //getArtistByID from url
  let queryDataGetTriangle = {
    resource: "triangles",
    id: triangleID,
  };
  const getTriangleByIDQueryResult = useQuery(
    ["getProgramByID", queryDataGetTriangle],
    () => triangleRequests.getTriangleById(queryDataGetTriangle)
  );

  //get  Similar triangle  type by type  with language  selection
  let queryDataGetRelatedTriangles = {
    resource: "triangles",
    language: appLanguage,
    param: "type",
    string:
      getTriangleByIDQueryResult.data &&
      getTriangleByIDQueryResult.data.type,
  };
  const getRelatedTrianglesQueryResult = useQuery(
    ["getRelatedPrograms", queryDataGetRelatedTriangles],
    () =>
      triangleRequests.getRelatedTriangles(queryDataGetRelatedTriangles)
  );

  // multilingual thing effects

  useEffect(() => {
    getTriangleByIDQueryResult.data &&
      setArticleLoadedLanguage(getTriangleByIDQueryResult.data.language);
  }, [getTriangleByIDQueryResult.data]);

  useEffect(() => {
    if (articleLoadedLanguage) {
      i18n.changeLanguage(articleLoadedLanguage)
    }
  }, [articleLoadedLanguage])

  useEffect(() => {
    let switchArticleId =
      getTriangleByIDQueryResult.data &&
      getTriangleByIDQueryResult.data.toggleLanguageWith &&
      getTriangleByIDQueryResult.data.toggleLanguageWith.id;

    let swtichArticleLanguage =
      getTriangleByIDQueryResult.data &&
      getTriangleByIDQueryResult.data.toggleLanguageWith &&
      getTriangleByIDQueryResult.data.toggleLanguageWith.language;

    if (!switchArticleId) return;
    if (swtichArticleLanguage && swtichArticleLanguage === appLanguage) {
      props.history.push(`/triangle/${switchArticleId}`);
    }

  }, [appLanguage]);

  return (
    <>
      {getTriangleByIDQueryResult.isLoading && <p>Loading...</p>}

      {getTriangleByIDQueryResult.data && (
        <>
          <MainNavBar
            backUrl="/triangle"
            pageTitle={`${getTriangleByIDQueryResult.data.title.length > 15
              ? `${getTriangleByIDQueryResult.data.title.slice(0, 15)} (...)`
              : getTriangleByIDQueryResult.data.title
              }`}
            bgClass="bg-white"
          />
          <div className="content-container-wrapper">
            <div className="content-container-content">
              <>
                {getTriangleByIDQueryResult.data && (
                  <TrianglePageSection1
                    queryData={getTriangleByIDQueryResult}
                  />
                )}

                {/* {getTriangleByIDQueryResult.data && (
                  <TrianglePageSection2
                    queryData={getTriangleByIDQueryResult}
                  />
                )} */}
              </>
            </div>
          </div>

          {/* this component renders different sections of a article*/}
          {
            getTriangleByIDQueryResult.data && getTriangleByIDQueryResult.data.articleContent && getTriangleByIDQueryResult.data.articleContent.length > 0 ?
              <RenderArticleContent articleContent={getTriangleByIDQueryResult.data.articleContent} /> : null
          }

          {getTriangleByIDQueryResult.data &&
            getTriangleByIDQueryResult.data.author && (
              <AuthorSection
                queryData={getTriangleByIDQueryResult.data}
                authorDetails={getTriangleByIDQueryResult.data.author}
                t={t}
              />
            )}

          <div className="content-container-wrapper">
            <div className="content-container-content">
              {getRelatedTrianglesQueryResult.data && (
                <TrianglePageSection3
                  queryData={getRelatedTrianglesQueryResult}
                />
              )}
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
}

export default withRouter(TrianglePage);
