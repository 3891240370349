import api from "../api";
import to from "await-to-js";
import { listAll } from "../queryGenerator";

const annualReportRequests = {
  listAllAnnualReports: async (queryData) => {
    // console.log(queryData);
    let resourceString = await listAll(queryData.resource);
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
};

export default annualReportRequests;
