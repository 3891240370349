import React, { useState, useEffect } from "react";
import "./styles.css";
import { IMAGE_BASE_URL } from "../../../../services/api";

import { LightBoxSimple } from "../../lightboxsimple/LightBoxSimple";

import { Link, withRouter } from "react-router-dom";
import { useDispatch, connect } from "react-redux";

import { Row, Col } from "antd";

function AboutSection1(props) {
  const { about } = props;
  // const { data, isLoading, error } = props.queryData;

  const [toggle, setToggle] = useState(false);
  const [sIndex, setSIndex] = useState(0);

  // Handler
  const lightBoxHandler = (state, sIndex) => {
    setToggle(state);
    setSIndex(sIndex);
  };

  return (
    <div className="aboutsection1__container">
      <Row>
        {about && about.aboutDetails && (
          <Col span={24}>
            <div className="aboutsection1__img">
              {about.aboutDetails.image && (
                <img src={`${IMAGE_BASE_URL}${about.aboutDetails.image.url}`}
                  onClick={() => {
                    lightBoxHandler(true, 0);
                  }}
                />
              )}
            </div>
            <span>{about.aboutDetails.imageCaption}</span>
            <LightBoxSimple
              toggle={toggle}
              lightBoxHandler={lightBoxHandler}
              data={[
                {
                  "id": 1,
                  "image": `${IMAGE_BASE_URL}${about.aboutDetails.image.url}`,
                  "title": "",
                  "description": ""
                }
              ]}
              sIndex={sIndex}
              setSIndex={setSIndex}
            />
          </Col>
        )}
      </Row>
    </div>
  );
}

// connect  redux  store
const mapStateToProps = (state) => {
  return {
    about: state.about,
  };
};

export default withRouter(connect(mapStateToProps)(AboutSection1));
