import React from "react";
import "./styles.css";

import { Row, Col } from "antd";
import ReactHtmlParser from "react-html-parser";
export default function ArtistProfileSection2(props) {
  const { isLoading, error, data } = props.queryData;

  const { t, appLanguage } = props;

  return (
    <div className="artistprofilesection2__container">
      {data && (
        <Row className="artistprofilesection2__artist-intro">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}
            xl={{ span: 12 }}
            xxl={{ span: 12 }}
          >
            <div className="artistprofilesection2__artist-description fs-1-1em ">
              <div className="ck-content">
                {
                  appLanguage === 'en' && ReactHtmlParser(data.en_description)
                }
                {
                  appLanguage === 'no' && ReactHtmlParser(data.no_description)
                }
              </div>
            </div>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 5 }}
            xl={{ span: 5 }}
            xxl={{ span: 5 }}
          ></Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 7 }}
            xl={{ span: 7 }}
            xxl={{ span: 7 }}
          >
            {data.website && (
              <>
                <p className="artistprofilesection2__artist-link-title text-fabric-sans fs-1-6em lh-1-2em">
                  {t("Visit Website")}:
                </p>
                <p className="artistprofilesection2__artist-link  text-fabric-sans fs-1-6em lh-1-2em">
                  <a href={`${data.website}`} target="_blank">{data.website}</a>
                </p>
              </>
            )}

            {/* <p className="artistprofilesection2__artist-link">{data.email}</p> */}
          </Col>
        </Row>
      )}
    </div>
  );
}
