import React, { useState, useEffect } from "react";

import "./styles.css";
import { Button } from "antd";

import { useQuery } from "react-query";
import cookieRequests from "../../../services/helpers/cookiequery";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";

export default function CookieStorageNotification(props) {
  //
  const { setStateOfCookieMessage } = props;
  //

  let [cookieMessageVisible, setCookieMessageVisible] = useState(true);

  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  // search query using usequery
  // search/list all triangles
  let queryData = {
    language: appLanguage,
    resource: "cookie-bars",
  };

  const cookieMessage = useQuery(["getAllCookieNotifications", queryData], () =>
    cookieRequests.getAllCookieNotifications(queryData)
  );

  const closeCookieNotificationBar = () => {
    setCookieMessageVisible(false);
    setStateOfCookieMessage(true);
  };

  return (
    <>
      {cookieMessageVisible && (
        <>
          {cookieMessage.data &&
            cookieMessage.data[0] &&
            cookieMessage.data[0].enabled && (
              <div className="cookiestoragenotificationbar__container  bg-dark text-fabric-sans fs-1-2em">
                <div className="cookiestoragenotificationbar__content">
                  <p>{cookieMessage.data[0].description}</p>

                  <Button
                    className="btn-primary-hks fs-1-1em text-uppercase"
                    onClick={closeCookieNotificationBar}
                  >
                    Accept All Cookies
                  </Button>
                </div>
              </div>
            )}
        </>
      )}
    </>
  );
}
