import { createSlice } from "@reduxjs/toolkit";

export const aboutSlice = createSlice({
  name: "about",
  initialState: {
    aboutDetails: {},
    isLoading: false,
    isSuccess: false,
    isError: false,
    error: null,
  },
  reducers: {
    setAbout: (state, action) => {
      return {
        ...state,
        isSuccess: true,
        aboutDetails: action.payload,
        isLoading: false,
      };
    },
    resetAbout: (state, action) => {
      return {
        ...state,
        aboutDetails: {},
        isLoading: false,
        isSuccess: false,
        isError: false,
        error: null,
      };
    },
    setIsLoadingAbout: (state, action) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    setAboutError: (state, action) => {
      return {
        ...state,
        isError: true,
        error: action.payload,
        isLoading: false,
      };
    },
  },
});

export const { setAbout, resetAbout, setIsLoadingAbout, setAboutError } =
  aboutSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCount = (state) => state.counter.value;

export default aboutSlice.reducer;
