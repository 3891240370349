import api from "../api";
import to from "await-to-js";
import { l_getSectionContent } from "../queryGenerator";

const homePageRequests = {
  getHomeSection1: async (queryData) => {
    // console.log(queryData);
    let resourceString = await l_getSectionContent(
      queryData.resource,
      queryData.language,
      queryData.param,
      queryData.string
    );
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  getHomeSection2: async (queryData) => {
    // console.log(queryData);
    let resourceString = await l_getSectionContent(
      queryData.resource,
      queryData.language,
      queryData.param,
      queryData.string
    );
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
};

export default homePageRequests;
