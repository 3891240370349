import React, { useState } from "react";

import { updatePageHead } from "../../../common/UpdateMetaAndTitle";
import {
  MainNavBar,
  Footer,
  ProgramGrid,
  PreviousProgramsSearch,
  LineSeperator,
  UpcomingProgramList,
} from "../../UIcomponents/UiComponents";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";

// react-query imports
import { useQuery } from "react-query";
import programsRequests from "../../../services/helpers/programsquery";
import artistsRequests from "../../../services/helpers/artistsquery";

export default function Program() {
  updatePageHead("Programs", "", "");

  const { t, i18n } = React.useContext(LocalizationContext);

  //listAllPrograms
  let queryDataProgramGrid = {
    resource: "programs",
    lang: i18n.language,
  };

  const listAllCurrentProgramsQueryResult = useQuery(
    ["listAllCurrentPrograms", queryDataProgramGrid],
    () => programsRequests.listAllCurrentPrograms(queryDataProgramGrid)
  );

  //getUpcoming program (ie artist list)

  let queryDataUpcomingProgram = {
    resource: "artists",
    language: i18n.language,
  };

  const listAllUpcomingProgramsQueryResult = useQuery(
    ["getUpcomingArtists", queryDataUpcomingProgram],
    () => artistsRequests.getUpcomingArtists(queryDataUpcomingProgram)
  );

  // console.log();
  return (
    <>
      <MainNavBar pageTitle="Program" bgClass="bg-white" />

      <div className="content-container-wrapper">
        <div className="content-container-content">
          {listAllCurrentProgramsQueryResult.isLoading && (
            <p>{t("Loading")}...</p>
          )}
          {listAllCurrentProgramsQueryResult.data && (
            <ProgramGrid queryData={listAllCurrentProgramsQueryResult} />
          )}

          {listAllUpcomingProgramsQueryResult.isLoading && (
            <p>{t("Loading")}...</p>
          )}
          {listAllUpcomingProgramsQueryResult.data && (
            <UpcomingProgramList
              queryData={listAllUpcomingProgramsQueryResult.data}
              t={t}
            />
          )}
        </div>
      </div>
      <div className="content-container-wrapper bg-highlight">
        <div className="content-container-content">
          <PreviousProgramsSearch />
        </div>
      </div>
      <LineSeperator />
      <Footer />
    </>
  );
}
