import React, { useState, useEffect } from "react";

import "./styles.css";
import { Row, Col, Button, Input } from "antd";
import {
  DownOutlined,
  UpOutlined,
  CloseOutlined,
} from "@ant-design/icons";
// import trianglesection1Display from "./trianglesection1Display";

import LocalizationContext from "../../../../localization/LocalizationContext";

export default function TriangleSection1(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const {
    // search handle
    searchKeyword,
    setSearchKeyword,

    // selected tag
    setSelectedTag,
    selectedTag,

    // selected year
    selectedYear,
    setSelectedYear,

    // tags
    tagList,
    yearList,
    // sort
    currentSorting,
    setCurrentSorting,
    // filter
    clearFilter,
    filterType,
    setFilterType
  } = props;

  let [showFilterBar, setShowFilterBar] = useState(false);

  const closeShowFilterBar = () => {
    setShowFilterBar(false);
    setFilterType(null)
  };
  const showFilterBarSection = () => {
    setShowFilterBar(true);
  };

  return (
    <div>
      <div className="trianglesection1__container">
        <Row>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
            xxl={{ span: 24 }}
          >
            <p className="trianglesection1__text text-fabric-sans">
              {t("Editorial initiatives and projects")}
            </p>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
            xl={{ span: 24 }}
            xxl={{ span: 24 }}
          >
            <div className="trianglesection1__search-box text-fabric-sans">
              <Input
                placeholder={`${t("Search")}`}
                className="placeholder-f-s-1-2em"
                onPressEnter={(e) => {
                  setSearchKeyword(e.target.value.trim());
                }}
                value={searchKeyword}
                onChange={(e) => {
                  setSearchKeyword(e.target.value);
                }}
              />
              <div className="flex-row-align-items-center">
                <Button
                  className={
                    filterType === "tag"
                      ? "search-filter-active"
                      : "search-filter"
                  }
                  onClick={() => {
                    if (filterType === "tag") {
                      closeShowFilterBar();
                      return
                    }
                    showFilterBarSection();
                    setFilterType("tag");
                  }}
                >
                  {t("Filter")}
                </Button>
                <Button
                  className={
                    filterType === "year"
                      ? "search-filter-active mar-l-10"
                      : "search-filter mar-l-10"
                  }
                  onClick={() => {
                    if (filterType === "year") {
                      closeShowFilterBar();
                      return
                    }
                    showFilterBarSection();
                    setFilterType("year");
                  }}
                >
                  {t("Year")}
                </Button>

                <span className="mar-l-10 fs-1-3em">
                  <DownOutlined onClick={() => { setCurrentSorting('DESC') }}
                    className={currentSorting === 'DESC' ? 'sort-btn-active' : 'sort-btn'}
                  />
                </span>
                <span className="fs-1-3em mar-l-10 ">
                  <UpOutlined onClick={() => { setCurrentSorting('ASC') }}
                    className={currentSorting === 'ASC' ? 'sort-btn-active' : 'sort-btn'}
                  />
                </span>


              </div>
            </div>
          </Col>
          {showFilterBar && (
            <>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}
                xl={{ span: 24 }}
                xxl={{ span: 24 }}
              >
                {" "}
                {filterType === "tag" && (
                  <div className="flex-row-justify-space-between ">
                    <div className="tag-list">
                      {tagList.map((tag) => {
                        return (
                          <p
                            key={tag}
                            className={
                              selectedTag === tag
                                ? "tag-item-active mar-b-10"
                                : "tag-item mar-b-10"
                            }
                            onClick={() => {
                              if (selectedTag === tag) {
                                setSelectedTag(null)
                                return
                              }
                              setSelectedTag(tag)
                            }}
                          >
                            <span className="uppercasetext">{t(`${tag}`)}</span>
                          </p>
                        );
                      })}
                    </div>
                    <div className="trianglesection1__filter-close">
                      <CloseOutlined
                        className="closebtn"
                        onClick={() => {
                          closeShowFilterBar();
                          setFilterType(null)
                          clearFilter();
                        }}
                      />
                    </div>
                  </div>
                )}
                {filterType === "year" && (
                  <div className="flex-row-justify-space-between ">
                    <div className="tag-list">
                      {yearList.map((year) => {
                        return (
                          <p
                            key={year}
                            className={
                              selectedYear === year
                                ? "tag-item-active mar-b-10"
                                : "tag-item mar-b-10"
                            }
                            onClick={() => {
                              if (selectedYear === year) {
                                setSelectedYear(null)
                                return
                              }
                              setSelectedYear(year)
                            }}
                          >
                            {t(`${year}`)}
                          </p>
                        );
                      })}
                    </div>
                    <div className="previousprogramssearch____filter-close">
                      <CloseOutlined
                        className="closebtn"
                        onClick={() => {
                          closeShowFilterBar();
                          setFilterType(null)
                          clearFilter();
                        }}
                      />
                    </div>
                  </div>
                )}
              </Col>
              <Col span={24}>

              </Col>
            </>
          )}
        </Row>
      </div>
    </div>
  );
}
