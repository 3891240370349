import React, { useEffect, useState } from "react";

import {
  MainNavBar,
  ProgramPageSection1,
  ProgramPageSection2,
  ProgramPageSection3,
  AuthorSection,
  ArticleAboutProject,
  DisplayArticleContent,
  Footer,
  RenderArticleContent,
} from "../../UIcomponents/UiComponents";

import { useQuery } from "react-query";

import programsRequests from "../../../services/helpers/programsquery";

import { updatePageHead } from "../../../common/UpdateMetaAndTitle";
import { withRouter } from "react-router";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";

function ProgramPage(props) {
  updatePageHead("Program | ", "", "");

  let [articleLoadedLanguage, setArticleLoadedLanguage] = useState(null);

  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  const { programID } = props.match.params;

  //getArtistByID from url
  let queryDataGetProgram = {
    resource: "programs",
    id: programID,
  };
  const getProgramdyIDQueryData = useQuery(
    ["getProgramByID", queryDataGetProgram],
    () => programsRequests.getProgramByID(queryDataGetProgram)
  );

  //get  Similar programs by type  with language  selection
  let queryDataGetRelatedPrograms = {
    resource: "programs",
    language: appLanguage,
    param: "programType",
    string:
      getProgramdyIDQueryData.data && getProgramdyIDQueryData.data.programType,
  };
  const getRelatedProgramsQueryResult = useQuery(
    ["getRelatedPrograms", queryDataGetRelatedPrograms],
    () => programsRequests.getRelatedPrograms(queryDataGetRelatedPrograms)
  );

  useEffect(() => {
    getProgramdyIDQueryData.data &&
      setArticleLoadedLanguage(getProgramdyIDQueryData.data.language);
  }, [getProgramdyIDQueryData.data]);

  useEffect(() => {
    if (articleLoadedLanguage) {
      i18n.changeLanguage(articleLoadedLanguage)
    }
  }, [articleLoadedLanguage])

  useEffect(() => {
    let switchArticleId =
      getProgramdyIDQueryData.data &&
      getProgramdyIDQueryData.data.toggleLanguageWith &&
      getProgramdyIDQueryData.data.toggleLanguageWith.id;

    let swtichArticleLanguage =
      getProgramdyIDQueryData.data &&
      getProgramdyIDQueryData.data.toggleLanguageWith &&
      getProgramdyIDQueryData.data.toggleLanguageWith.language;

    if (!switchArticleId) return;
    if (swtichArticleLanguage && swtichArticleLanguage === appLanguage) {
      props.history.push(`/program/${switchArticleId}`);
    }

  }, [appLanguage]);

  return (
    <>
      {getProgramdyIDQueryData.data && (
        <>
          <MainNavBar
            backUrl="/program"
            pageTitle={`${getProgramdyIDQueryData.data.title}`}
            bgClass="bg-white"
          />
          <div className="content-container-wrapper">
            <div className="content-container-content">
              <>
                {getProgramdyIDQueryData.data && (
                  <ProgramPageSection1 queryData={getProgramdyIDQueryData} />
                )}
              </>
            </div>
          </div>

          {
            getProgramdyIDQueryData.data && getProgramdyIDQueryData.data.articleContent && getProgramdyIDQueryData.data.articleContent.length > 0 ?
              <RenderArticleContent articleContent={getProgramdyIDQueryData.data.articleContent} /> : null
          }

          {getProgramdyIDQueryData.data &&
            getProgramdyIDQueryData.data.author && (
              <AuthorSection
                queryData={getProgramdyIDQueryData.data}
                authorDetails={getProgramdyIDQueryData?.data?.author}
                t={t}
              />
            )}
          {getProgramdyIDQueryData.data && getProgramdyIDQueryData.data.project && (
            <ArticleAboutProject
              data={getProgramdyIDQueryData?.data?.project}
              t={t}
            />
          )}

          {getRelatedProgramsQueryResult.data && getRelatedProgramsQueryResult.data.length > 0 && (
            <div className="content-container-wrapper">
              <div className="content-container-content">
                <ProgramPageSection3
                  queryData={getRelatedProgramsQueryResult}
                />
              </div>
            </div>
          )}

          <Footer />
        </>
      )}
    </>
  );
}

export default withRouter(ProgramPage);
