import React from "react";
import "./styles.css";

import { Row, Col } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import { formatDate } from "../../../helper/formatDate";

import LocalizationContext from "../../../../localization/LocalizationContext";

export default function PossibilitiesSection1(props) {
  const { t, i18n } = React.useContext(LocalizationContext);

  const { isLoading, data, error } = props.queryData;

  return (
    <div className="possibilitiessection1__container">
      <Row gutter={12}>
        {data && data.length > 0 && (
          <>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 16 }}
              xl={{ span: 16 }}
              xxl={{ span: 16 }}
            >
              <div className="possibilitiessection1__content">
                <p className="possibilitiessection1__content-title fs-2-5em lh-1-2em">
                  {data[0].title}
                </p>
                <p className="possibilitiessection1__content-description fs-1-2em lh-1-4em">
                  {data[0].description}
                </p>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 8 }}
              xl={{ span: 8 }}
              xxl={{ span: 8 }}
            >
              <div className="possibilitiessection1__right">
                <p className="possibilitiessection1__right-deadline fs-2-5em lh-1-2em">
                  {t("Application Deadline")}: <br />{" "}
                  {formatDate(data[0].applicationDeadline)}
                </p>
                <p className="possibilitiessection1__right-more">
                  <Link to={`/possibilities/${data[0].id}`}>
                    <span className="possibilitiessection1__right-more-text fs-2-5em lh-1-2em hide-underline-link">
                      {t("Read More")} →
                    </span>
                  </Link>
                </p>
              </div>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}
