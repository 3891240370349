import {
  setAbout,
  resetAbout,
  setIsLoadingAbout,
  setAboutError,
} from "./aboutSlice";
import to from "await-to-js";

import aboutRequests from "../../../services/helpers/aboutquery";

export const getAboutInfo = (language) => async (dispatch) => {
  // console.log(language);
  dispatch(setIsLoadingAbout());

  let queryData = {
    resource: "about-hks",
    language: language,
  };

  let [err, response] = await to(aboutRequests.getAboutHks(queryData));

  if (err) return dispatch(setAboutError(err));
  dispatch(setAbout(response[0]));
};
