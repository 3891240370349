import React from "react";
import "./style.css";
import { Row, Col, Button } from "antd";
import moment from "moment";

import { Link } from "react-router-dom";

import { renderStartAndEndDate } from "../../../helper/formatDate";

// language context
import LocalizationContext from "../../../../localization/LocalizationContext";

export default function ArtistInResidencySection2(props) {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  const { queryData } = props;
  const { isLoading, data, error } = queryData;
  let thisYear = moment().format("YYYY");

  return (
    <div className="artistinresidencysection2__container">
      {data && (
        <>
          <div className="artistinresidencysection2__title text-fabric-sans">
            {t("Artists In Residency")}
            {"  "}
            {thisYear}
          </div>
          <div className="artistinresidencysection2__artist-list">
            {data.map((artist) => {
              return (
                <Link to={`/artistprofile/${artist.id}`} key={artist.name}>
                  <div className="artistinresidencysection2__artist">
                    <p className="artistinresidencysection2__artist-date">
                      {
                        renderStartAndEndDate(artist.dateStart, artist.dateEnd)
                      }
                    </p>
                    <p className="artistinresidencysection2__artist-name fs-2-5em lh-2em">
                      {artist.name} {artist.country && ` (${artist.country})`}
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
}
