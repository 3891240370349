import React, { useState, useEffect } from "react";
import "./style.css";

import {
  MainNavBar,
  Footer,
  LineSeperator,
} from "../../UIcomponents/UiComponents";

import { Row, Col } from "antd";
import { IMAGE_BASE_URL } from "../../../services/api";

import { useQuery } from "react-query";
import projectRequests from "../../../services/helpers/projectquery";

import ReactHtmlParser from "react-html-parser";

import { updatePageHead } from "../../../common/UpdateMetaAndTitle";
import { withRouter } from "react-router";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";
import { LightBoxSimple } from "../../UIcomponents/lightboxsimple/LightBoxSimple";
import RelatedProjects from "../../UIcomponents/projectpage/related-projects/RelatedProjects";

function ProjectPage(props) {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  updatePageHead(`${t("Project")}`, "", "");

  const { projectID } = props.match.params;

  // initial article language while page  loading
  let [articleLoadedLanguage, setArticleLoadedLanguage] = useState(null);

  const [imageListForLightBox, setImageListForLightBox] = useState([])
  const [toggle, setToggle] = useState(false);
  const [sIndex, setSIndex] = useState(0);

  // Handler
  const lightBoxHandler = (state, sIndex) => {
    setToggle(state);
    setSIndex(sIndex);
  };


  //getArtistByID from url
  let queryDataGetProject = {
    resource: "projects",
    id: projectID,
  };
  const getProjectByIdQueryData = useQuery(
    ["getProjectById", queryDataGetProject],
    () => projectRequests.getProjectById(queryDataGetProject)
  );

  // these use  effects are to get aleternate language article information
  // and then redirect to the page on change of langugage

  useEffect(() => {
    getProjectByIdQueryData.data &&
      setArticleLoadedLanguage(getProjectByIdQueryData.data.language);
  }, [getProjectByIdQueryData.data]);

  useEffect(() => {
    if (articleLoadedLanguage) {
      i18n.changeLanguage(articleLoadedLanguage)
    }
  }, [articleLoadedLanguage])

  useEffect(() => {
    let switchArticleId =
      getProjectByIdQueryData.data &&
      getProjectByIdQueryData.data.toggleLanguageWith &&
      getProjectByIdQueryData.data.toggleLanguageWith.id;

    let swtichArticleLanguage =
      getProjectByIdQueryData.data &&
      getProjectByIdQueryData.data.toggleLanguageWith &&
      getProjectByIdQueryData.data.toggleLanguageWith.language;

    if (!switchArticleId) return;
    if (swtichArticleLanguage && swtichArticleLanguage === appLanguage) {
      props.history.push(`/projects/${switchArticleId}`);
    }

  }, [appLanguage]);


  useEffect(() => {
    let _x = []
    getProjectByIdQueryData.data && getProjectByIdQueryData.data.image1 &&
      _x.push(
        {
          "id": 0,
          "image": `${IMAGE_BASE_URL}${getProjectByIdQueryData.data.image1.url}`,
          "title": "",
          "description": ""
        }
      )

    getProjectByIdQueryData.data && getProjectByIdQueryData.data.image2 &&
      _x.push(
        {
          "id": 1,
          "image": `${IMAGE_BASE_URL}${getProjectByIdQueryData.data.image2.url}`,
          "title": "",
          "description": ""
        }
      )

    setImageListForLightBox(_x)
  }, [getProjectByIdQueryData.data]);



  return (
    <>
      {getProjectByIdQueryData.isLoading && <p>Loading...</p>}
      {getProjectByIdQueryData.data && (
        <>
          <MainNavBar
            backUrl="/"
            pageTitle={`${t("Projects")}/${getProjectByIdQueryData.data.title.length > 15
              ? `${getProjectByIdQueryData.data.title.slice(0, 15)} (...)`
              : getProjectByIdQueryData.data.title
              }`}
          />
          <div className="content-container-wrapper text-fabric-sans">
            <div className="content-container-content">
              <>
                <Row className="possibilities_content-container">
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 9 }}
                    xl={{ span: 9 }}
                    xxl={{ span: 9 }}
                  >
                    <p className="possibilities_left_section-title fs-2-5em lh-1-2em">
                      {getProjectByIdQueryData.data.title}
                    </p>

                    <div className="possibilities_left_section_images-large">
                      <div className="possibilities_left_section-seperator"></div>

                      <div className="possibilities_left_section_image">
                        {getProjectByIdQueryData.data.image1 && (
                          <img
                            src={`${IMAGE_BASE_URL}${getProjectByIdQueryData.data.image1.url}`}
                            onClick={() => {
                              lightBoxHandler(true, 0);
                            }}
                          />
                        )}
                        <p className="possibilities_left_section_caption">
                          {getProjectByIdQueryData.data.image1Caption}
                        </p>
                      </div>

                      <div className="possibilities_left_section_image">
                        {getProjectByIdQueryData.data.image2 && (
                          <img
                            src={`${IMAGE_BASE_URL}${getProjectByIdQueryData.data.image2.url}`}
                            onClick={() => {
                              lightBoxHandler(true, 1);
                            }}
                          />
                        )}
                        <p className="possibilities_left_section_caption">
                          {getProjectByIdQueryData.data.image2Caption}
                        </p>
                      </div>
                    </div>
                    {/* lightbox for carousel */}
                    <LightBoxSimple
                      toggle={toggle}
                      lightBoxHandler={lightBoxHandler}
                      data={imageListForLightBox}
                      sIndex={sIndex}
                      setSIndex={setSIndex}
                    />
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 3 }}
                    xl={{ span: 3 }}
                    xxl={{ span: 3 }}
                  ></Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}
                    xl={{ span: 12 }}
                    xxl={{ span: 12 }}
                  >
                    <div className="possibilities_right_section">
                      <p className="possibilities_right_section-title fs-2-5em lh-1-2em">
                        {t("About the project")}
                      </p>
                      <div className="possibilities_right_section-description">
                        <div className="ck-content">
                          {ReactHtmlParser(
                            getProjectByIdQueryData.data.description
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 9 }}
                    xl={{ span: 9 }}
                    xxl={{ span: 9 }}
                  >
                    <div className="possibilities_left_section_images-small">
                      <div className="possibilities_left_section-seperator"></div>

                      <div className="possibilities_left_section_image">
                        {getProjectByIdQueryData.data.image1 && (
                          <img
                            src={`${IMAGE_BASE_URL}${getProjectByIdQueryData.data.image1.url}`}
                          />
                        )}
                        <p className="possibilities_left_section_caption">
                          {getProjectByIdQueryData.data.image1Caption}
                        </p>
                      </div>

                      <div className="possibilities_left_section_image">
                        {getProjectByIdQueryData.data.image2 && (
                          <img
                            src={`${IMAGE_BASE_URL}${getProjectByIdQueryData.data.image2.url}`}
                          />
                        )}
                        <p className="possibilities_left_section_caption">
                          {getProjectByIdQueryData.data.image2Caption}
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            </div>
          </div>
          {/* <LineSeperator /> */}

          <div className="content-container-wrapper">
            <div className="content-container-content">
              {getProjectByIdQueryData.data && (
                <RelatedProjects
                  data={getProjectByIdQueryData.data.programs}
                />
              )}
            </div>
          </div>

          <Footer />
        </>
      )}
    </>
  );
}

export default withRouter(ProjectPage);
