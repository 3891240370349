import React from "react";
import "./styles.css";
import { Row, Col, Button } from "antd";
import { Link } from "react-router-dom";

import { renderStartAndEndDate } from "../../../helper/formatDate";

export default function ArtistInResidencySearchResult(props) {
  const { filteredData, pagination, handlePagination, t } = props;
  return (
    <div className="artistinresidencysearchresult__container">
      {filteredData && filteredData.length < 1 ? <p className="mar-t-10">{t("No Results")}</p> :
        <>
          {filteredData &&
            filteredData.length > 0 &&
            filteredData.map((artist) => {
              return (
                <Row
                  key={artist.id}
                  // className={
                  //   item === 3
                  //     ? "artistinresidencysearchresult__content-row-selected"
                  //     : "artistinresidencysearchresult__content-row"
                  // }
                  className="artistinresidencysearchresult__content-row"
                >
                  <Col span={20}>
                    <div className="artistinresidencysearchresult__program-info">
                      <p className="artistinresidencysearchresult__program-info-date">
                        {
                          renderStartAndEndDate(artist.dateStart, artist.dateEnd)
                        }
                      </p>
                      <Link to={`/artistprofile/${artist.id}`}>
                        <p className="artistinresidencysearchresult__program-info-name fs-1-6em lh-1-2em">
                          {artist.name}
                        </p>
                      </Link>
                    </div>
                  </Col>
                </Row>
              );
            })}
          <>
            {pagination.isNextPageAvailable ? (
              <div className="content__load-more">
                <Button
                  className="content__load-more-button"
                  onClick={handlePagination}
                >
                  {t("Load More")}
                </Button>
              </div>
            ) : (
              <div className="content__load-more">
                <p className="content__load-more-text">{t("No more results")}</p>
              </div>
            )}
          </>
        </>
      }
    </div>
  );
}
