import React from "react";

import {
  MainNavBar,
  Footer,
  ArtistInResidencySection1,
  ArtistInResidencySection2,
  ArtistInResidencySection3,
  ArtistInResidencySection4,
  TriangleSection2,
  TriangleSection3,
  TriangleSection4,
  LineSeperator,
  SimpleSectionOne
} from "../../UIcomponents/UiComponents";

import { useQuery } from "react-query";
import artistsRequests from "../../../services/helpers/artistsquery";
import triangleRequests from "../../../services/helpers/trianglequery";

import { updatePageHead } from "../../../common/UpdateMetaAndTitle";

// language context
import LocalizationContext from "../../../localization/LocalizationContext";

export default function ArtistInResidency() {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  //update page head
  updatePageHead(`${t("Artist In Residency")}`, "", "");


  // get section 1
  let queryDataSectionOne = {
    resource: "artist-in-residency-section-one",
  };

  const artistInResidencySection1QueryResult = useQuery(
    ["getArtistInResidencySection1", queryDataSectionOne],
    () => artistsRequests.getArtistInResidencySection1(queryDataSectionOne)
  );


  //get current artist in residency
  //with this it will also return the programs that artist is assoiciated with
  let queryDataSection1CurrentArtist = {
    resource: "artists",
    param: "current",
    string: "true",
  };
  const currentArtistQueryResult = useQuery(
    ["getCurrentArtist", queryDataSection1CurrentArtist],
    () => artistsRequests.getCurrentArtist(queryDataSection1CurrentArtist)
  );

  //list  all  artists
  let queryDataListArtists = {
    resource: "artists",
  };
  const listAllArtistsQueryData = useQuery(
    ["getCurrentArtists", queryDataListArtists],
    () => artistsRequests.getCurrentArtists(queryDataListArtists)
  );

  //get info  about  artist application

  let queryDataArtistApplication = {
    resource: "artist-application",
  };
  const getApplicationInfoQueryData = useQuery(
    ["getArtistApplyInfo", queryDataArtistApplication],
    () => artistsRequests.getArtistApplyInfo(queryDataArtistApplication)
  );

  // getsection5
  // featured triangle for home page
  //multilingual
  let queryDataSection5 = {
    resource: "triangles",
    language: appLanguage,
    param: "if_artistInResidency",
    string: "true",
  };
  const featuredTriangleOnAIR = useQuery(
    ["getFeaturedTriangleForAIR", queryDataSection5],
    () => triangleRequests.getFeaturedTriangle(queryDataSection5)
  );

  // console.log(getApplicationInfoQueryData.data);
  return (
    <>
      <MainNavBar
        pageTitle={`${t("Artist In Residency")}`}
        bgClass="bg-white"
      />

      <div className="content-container-wrapper">
        <div className="content-container-content">

          {/* section 1 */}
          {artistInResidencySection1QueryResult.isLoading && <p>{t("Loading")}...</p>}

          {artistInResidencySection1QueryResult.data && (
            <SimpleSectionOne
              queryData={artistInResidencySection1QueryResult.data}
              appLanguage={appLanguage}
            />
          )}


          {currentArtistQueryResult.isLoading && <p>{t("Loading")}...</p>}
          {currentArtistQueryResult.data && currentArtistQueryResult.data.length > 0 && (
            <ArtistInResidencySection1 queryData={currentArtistQueryResult} />
          )}

          {listAllArtistsQueryData.isLoading && <p>{t("Loading")}...</p>}
          <ArtistInResidencySection2 queryData={listAllArtistsQueryData} />
        </div>
      </div>

      <div className="content-container-wrapper bg-highlight">
        <div className="content-container-content">
          {getApplicationInfoQueryData.isLoading && <p>{t("Loading")}...</p>}
          {getApplicationInfoQueryData.data && (
            <ArtistInResidencySection3
              queryData={getApplicationInfoQueryData}
              appLanguage={appLanguage}
              t={t}
            />
          )}
        </div>
      </div>

      {/* section 4 */}
      {/* section 5  this will  be  a initative type  */}

      <div className="content-container-wrapper">
        <div className="content-container-content">
          {featuredTriangleOnAIR.data &&
            featuredTriangleOnAIR.data.length > 0 && (
              <>
                <>
                  {featuredTriangleOnAIR.data[0].type ===
                    "literaryResponse" ? (
                    <TriangleSection2
                      queryData={featuredTriangleOnAIR.data[0]}
                    />
                  ) : null}
                </>
                <>
                  {featuredTriangleOnAIR.data[0].type === "archiveStory" ||
                    featuredTriangleOnAIR.data[0].type === "interview" ? (
                    <TriangleSection3
                      queryData={featuredTriangleOnAIR.data[0]}
                    />
                  ) : null}
                </>
                <>
                  {featuredTriangleOnAIR.data[0].type === "article" ? (
                    <TriangleSection4
                      queryData={featuredTriangleOnAIR.data[0]}
                    />
                  ) : null}
                </>
              </>
              // <HomeSection5 queryData={homeSection5QueryResult} />
            )}
        </div>
      </div>

      {/*  */}

      <div className="content-container-wrapper bg-highlight">
        <div className="content-container-content">
          <ArtistInResidencySection4 />
        </div>
      </div>

      <LineSeperator />
      <Footer />
    </>
  );
}
