import api from "../../api";
import to from "await-to-js";
import moment from "moment";
import qs from 'qs'

const trianglesSearchRequests = {
    searchTriangles: async (queryData, searchCase) => {
        let query = null

        if (searchCase === 'keyword') {
            query = qs.stringify({
                _start: queryData.start,
                _limit: queryData.limit,
                _sort: `date:${queryData.sortType}`,
                _where: {
                    language_eq: queryData.lang,
                    'title_contains': queryData.string

                },
            });
        }
        if (searchCase === 'tag') {
            query = qs.stringify({
                _start: queryData.start,
                _limit: queryData.limit,
                _sort: `date:${queryData.sortType}`,
                _where: {
                    language_eq: queryData.lang,
                    'tags.tagName_contains': queryData.param
                },
            })
        }
        if (searchCase === 'year') {
            query = qs.stringify({
                _start: queryData.start,
                _limit: queryData.limit,
                _sort: `date:${queryData.sortType}`,
                _where: {
                    language_eq: queryData.lang,
                    'year.year_contains': queryData.param
                },
            });
        }
        if (searchCase === 'keywordTag') {
            query = qs.stringify({
                _start: queryData.start,
                _limit: queryData.limit,
                _sort: `date:${queryData.sortType}`,
                _where: {
                    language_eq: queryData.lang,
                    'tags.tagName_eq': queryData.param,
                    'title_contains': queryData.string
                },
            });
        }
        if (searchCase === 'keywordYear') {
            query = qs.stringify({
                _start: queryData.start,
                _limit: queryData.limit,
                _sort: `date:${queryData.sortType}`,
                _where: {
                    language_eq: queryData.lang,
                    'year.year_eq': queryData.param,
                    'title_contains': queryData.string
                },
            });
        }
        if (searchCase === 'keywordTagYear') {
            query = qs.stringify({
                _start: queryData.start,
                _limit: queryData.limit,
                _sort: `date:${queryData.sortType}`,
                _where: {
                    language_eq: queryData.lang,
                    'tags.tagName_eq': queryData.param,
                    'year.year_eq': queryData.secondParam,
                    'title_contains': queryData.string

                },
            });
        }
        if (searchCase === 'tagYear') {
            query = qs.stringify({
                _start: queryData.start,
                _limit: queryData.limit,
                _sort: `date:${queryData.sortType}`,
                _where: {
                    language_eq: queryData.lang,
                    'tags.tagName_eq': queryData.param,
                    'year.year_eq': queryData.secondParam,
                },
            });
        }

        let resourceString = `triangles?${query}`
        let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
        if (error) {
            throw error
        }
        if (response.status === "fail") {
            throw { ...response };
        } else {
            return response;
        }
    },

};

export default trianglesSearchRequests;
