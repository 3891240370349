import api from "../api";
import to from "await-to-js";
import { l_listAll, listAll, getSingle } from "../queryGenerator";

const artAndPublicRequests = {
  getArtAndPublicSection1: async (queryData) => {
    // console.log(queryData);
    let resourceString = await getSingle(queryData.resource);
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
  listAllArtAndPublicSections: async (queryData) => {
    // console.log(queryData);
    let resourceString = await l_listAll(
      queryData.resource,
      queryData.language
    );
    // console.log(resourceString);

    let [error, response] = await to(api.GETPUBLIC_QUERY(resourceString));
    // console.log(error, response);

    if (response.status === "fail") {
      throw { ...response };
    } else {
      return response;
    }
  },
};

export default artAndPublicRequests;
