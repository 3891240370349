import React, { useState, useEffect } from "react";

import "./styles.css";

import { Affix, Row, Col } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AnnouncementBar } from "../../UIcomponents/UiComponents";

import { useDispatch, connect } from "react-redux";

import HamMenu from "./HamMenu";

import { getAboutInfo } from "../../../store/features/about/aboutActions";

import LocalizationContext from "../../../localization/LocalizationContext";
import CookieStorageNotification from "../cookiestoragenotification/CookieStorageNotification";

export default function MainNavBar(props) {
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  const dispatch = useDispatch();

  let { pageTitle, backUrl } = props;

  let [mainNavOffset, setMainNavOffset] = useState(0);

  useEffect(() => {
    if (appLanguage) {
      dispatch(getAboutInfo(appLanguage));
    } else {
      dispatch(getAboutInfo("en"));
    }
  }, [appLanguage]);

  // session storage
  // messages
  useEffect(() => {
    // if (!sessionStorage.getItem("cookieMessageDisplayed")) {
    //   setStateOfCookieMessage(false);
    // }
    // if (!sessionStorage.getItem("announcementMessageDisplayed")) {
    //   setStateOfAnnouncementMessage(false);
    // }
  }, []);

  const setStateOfCookieMessage = (val) => {
    sessionStorage.setItem("cookieMessageDisplayed", val);
  };
  const setStateOfAnnouncementMessage = (val) => {
    sessionStorage.setItem("announcementMessageDisplayed", val);
  };

  let stateOfAnnouncementMessage = sessionStorage.getItem(
    "announcementMessageDisplayed"
  );
  let stateOfCookieMessage = sessionStorage.getItem("cookieMessageDisplayed");

  return (
    <>
      {/* render them based on values of session  storage  to make sure they are no  re-rendered on  every  refresh  */}
      {!stateOfCookieMessage && (
        <CookieStorageNotification
          setStateOfCookieMessage={setStateOfCookieMessage}
        />
      )}
      {!stateOfAnnouncementMessage && (
        <AnnouncementBar
          setStateOfAnnouncementMessage={setStateOfAnnouncementMessage}
        />
      )}
      <Affix
        offsetTop={mainNavOffset}
      // onChange={(e) => {
        //   if (e) {
          //     setMainNavOffset(200);
      //   }
      //   console.log("change", e);
      // }}
      >
        <div
          id="main-nav-container"
          className={
            props.bgClass
              ? `${props.bgClass} content-container-wrapper`
              : "content-container-wrapper"
          }
        >
          <div className="content-container-content text-fabric-serif">
            <div className="mainnavbar__container">
              <Row>
                <Col
                  xs={{ span: 22, order: 1 }}
                  sm={{ span: 22, order: 1 }}
                  md={{ span: 22, order: 1 }}
                  lg={{ span: 8, order: 1 }}
                  xl={{ span: 8, order: 1 }}
                  xxl={{ span: 8, order: 1 }}
                >
                  {/* <Link to="/"> */}
                  <div className="mainnavbar__site-title text-fabric-sans fs-1-2em">
                    <Link to="/">
                      {t("Hordaland")} {t("Kunstsenter")}
                    </Link>
                  </div>
                  {/* </Link> */}
                </Col>

                <Col
                  xs={{ span: 24, order: 3 }}
                  sm={{ span: 24, order: 3 }}
                  md={{ span: 24, order: 3 }}
                  lg={{ span: 14, order: 2 }}
                  xl={{ span: 14, order: 2 }}
                  xxl={{ span: 14, order: 2 }}
                >
                  {/* <div className="mainnavbar__page-title">{pageTitle}</div> */}

                  <div className="mainnavbar__page-title text-fabric-sans">
                    {backUrl && (
                      <span className="mainnavbar__url-back">
                        <Link to={`${backUrl}`}>←/ </Link>
                      </span>
                    )}
                    <span className="">{t(pageTitle)}</span>
                  </div>

                  {/* new layout for page title */}
                  {/* about or <-/artistName */}
                </Col>

                <Col
                  xs={{ span: 2, order: 2 }}
                  sm={{ span: 2, order: 2 }}
                  md={{ span: 2, order: 2 }}
                  lg={{ span: 2, order: 3 }}
                  xl={{ span: 2, order: 3 }}
                  xxl={{ span: 2, order: 3 }}
                >
                  <div className="mainnavbar__ham-menu-icon flex-row-justify-flex-end fs-1-6em lh-1-4em">
                    {
                      <MenuOutlined
                        className="openbtn"
                        onClick={() => {
                          window.openNav();
                        }}
                      />
                    }
                  </div>
                </Col>
              </Row>
            </div>

          </div>

        </div>
      </Affix>
      <HamMenu />
    </>
  );
}
