// these  are  small components that  are  going  to  be rendered conditionally  as  we map
import React, { useState } from "react";
import { IMAGE_BASE_URL } from "../../../../services/api";

import { Link } from "react-router-dom";
import { TagsDisplay } from "../../UiComponents";

import { Row, Col } from 'antd'

import { formatDate } from "../../../helper/formatDate";


export function DisplayProgramResult(props) {
  const { resultList } = props;

  // display image on hover over title [state]
  const [displayImagePreviewOnHover, setDisplayImagePreviewOnHover] = useState(null)

  const handleShowOnHoverOverTitle = (id) => {
    setDisplayImagePreviewOnHover(id)
  }
  const handleHideOnHoverOverTitle = (id) => {
    setDisplayImagePreviewOnHover(null)
  }

  // console.log(resultList);
  return (
    <div>
      {resultList.length > 0 &&
        resultList.map((item) => {
          return (
            <div className="globalsearchresult__container" key={item.id}>
              <Row>
                <Col span={18}>

                  <p className="globalsearchresult__date">
                    {formatDate(item.dateStart)} - {formatDate(item.dateEnd)}
                  </p>
                  {item.artist && (
                    <Link to={`/artistprofile/${item.artist.id}`}>
                      <p className="globalsearchresult__artist-name fs-1-6em lh-1-2em">
                        {item.artist.name}
                      </p>
                    </Link>
                  )}

                  <Link to={`/program/${item.id}`}
                    onMouseOver={() => { handleShowOnHoverOverTitle(item.id) }}
                    onMouseOut={() => { handleHideOnHoverOverTitle(item.id) }}
                  >
                    <p className="globalsearchresult__title fs-1-6em lh-1-2em text-fabric-sans-italic">
                      {item.title}
                    </p>
                  </Link>
                  <div className="globalsearchresult__tags-container">
                    <TagsDisplay tagList={item.tags} />
                  </div>
                </Col>
                <Col span={6}>
                  <div className="previousprogramsdisplay__content-row-image"  >
                    {
                      // true &&
                      (displayImagePreviewOnHover === item.id) &&
                      <>
                        {item.mainImg && item.mainImg.url && <img alt={`${item.title}`} src={`${IMAGE_BASE_URL}${item.mainImg.url}`} />}
                      </>
                    }
                  </div>

                </Col>

              </Row>
            </div>
          );
        })}
    </div>
  );
}

export function DisplayTriangleResult(props) {
  const { resultList } = props;
  return (
    <div>
      {resultList.length > 0 &&
        resultList.map((item) => {
          return (
            <div className="globalsearchresult__container" key={item.id}>
              <Link to={`/triangle/${item.id}`}>
                <p className="globalsearchresult__title fs-1-6em lh-1-2em">
                  {item.title}
                </p>
              </Link>
              <div className="globalsearchresult__tags-container">
                <TagsDisplay tagList={item.tags} />
              </div>
            </div>
          );
        })}
    </div>
  );
}

export function DisplayOpportunitiesResult(props) {
  const { resultList } = props;
  return (
    <div>
      {resultList.length > 0 &&
        resultList.map((item) => {
          return (
            <div className="globalsearchresult__container" key={item.id}>
              <Link to={`/possibilities/${item.id}`}>
                <p className="globalsearchresult__title fs-1-6em lh-1-2em">
                  {item.title}
                </p>
              </Link>
              <div className="globalsearchresult__tags-container">
                <TagsDisplay tagList={item.tags} />
              </div>
            </div>
          );
        })}
    </div>
  );
}

export function DisplayArtistsResult(props) {
  const { resultList } = props;
  return (
    <div>
      {resultList.length > 0 &&
        resultList.map((item) => {
          return (
            <div className="globalsearchresult__container" key={item.id}>
              <Link to={`/artistprofile/${item.id}`}>
                <p className="globalsearchresult__title fs-1-6em lh-1-2em">
                  {item.name}
                </p>
              </Link>

              <div className="globalsearchresult__tags-container">
                <TagsDisplay tagList={item.tags} />
              </div>
            </div>
          );
        })}
    </div>
  );
}
