import React, { useState, useEffect } from "react";
import "./style.css";
import { Row, Col, Button } from "antd";

import { IMAGE_BASE_URL } from "../../../../services/api";
import { TagsDisplay } from "../../UiComponents";
import { Link } from "react-router-dom";

import { formatDate } from "../../../helper/formatDate";

// language context
import LocalizationContext from "../../../../localization/LocalizationContext";

export default function ArtistInResidencySection1(props) {
  // i18n and current app language
  const { t, i18n } = React.useContext(LocalizationContext);
  const appLanguage = i18n.language;

  // console.log(props.queryData.data);

  return (
    <>
      <p className="artistinresidencysection1__title-one text-fabric-sans">
        {t("Current Artist In Residency")}
      </p>
      <ArtistInResidencySection1sub1 queryData={props.queryData} t={t} />
      <p className="artistinresidencysection1__title-two text-fabric-sans">
        {t("Related Content")}
      </p>
      <ArtistInResidencySection1sub2
        appLanguage={appLanguage}
        queryData={props.queryData}
        t={t}
      />
    </>
  );
}

function ArtistInResidencySection1sub1(props) {
  const { data, isLoading, error } = props.queryData;
  const { t } = props;

  return (
    <div className="artistInResidencySection1sub1__container">
      {isLoading && <p>{t("Loading")}...</p>}
      {data && data.length > 0 && (
        <div className="artistInResidencySection1sub1__content">
          <div className="artistInResidencySection1sub1__image">
            <Link to={`/artistprofile/${data[0].id}`}>
              {data[0].profileImg && (
                <img src={`${IMAGE_BASE_URL}${data[0].profileImg.url}`} />
              )}
            </Link>
          </div>

          <div className="artistInResidencySection1sub1__info-text">
            <p className="artistInResidencySection1sub1__text-line1">
              {formatDate(data[0].dateStart)} - {formatDate(data[0].dateEnd)}
            </p>
            <Link to={`/artistprofile/${data[0].id}`}>
              <p className="artistInResidencySection1sub1__text-line2 fs-5-5em">
                {data[0].name}({data[0].country})
              </p>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export function ArtistInResidencySection1sub2(props) {
  const { data, isLoading } = props.queryData;
  const { appLanguage, t } = props;

  let [gridDataList, setGridDataList] = useState([]);
  // let gridDataList = data && data[0].programs.slice(0, 4);

  const [showHoverEffect, setShowHoverEffect] = useState(false)
  const [showHoverEffectId, setShowHoverEffectId] = useState(null)

  useEffect(() => {
    // console.log(data);
    if (!data.length > 0) return;
    let x = data[0].programs.filter((item) => {
      return item.language === appLanguage;
    });
    let y = x && x.slice(0, 4);
    setGridDataList(y);
    // return () => {
    //   cleanup
    // }
  }, [data, appLanguage]);

  const onHoverStyleText = {
    textDecoration: "underline"
  }
  const onHoverStyleImage = {
    opacity: 0.8
  }
  const onMouseHoverHeadingOrImage = (id) => {
    setShowHoverEffectId(id)
    setShowHoverEffect(true)
  }
  const onMouseRemoveHeadingOrImage = (id) => {
    setShowHoverEffectId(null)
    setShowHoverEffect(false)
  }

  let getColVal = (layoutString, baseCol) => {
    if (layoutString === "ONE") {
      return baseCol;
    } else if (layoutString === "TWO") {
      return baseCol;
      // return baseCol * 2;
    } else if (layoutString === "THREE") {
      return baseCol;
      // return baseCol * 3;
    } else return baseCol;
  };

  return (
    <div className="artistinresidencysection1sub2__container">
      {isLoading && <p>{t("Loading")}...</p>}

      {gridDataList && (
        <Row gutter={35}>
          {gridDataList.map((item) => {
            return (
              <Col
                key={item.title}
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: `${getColVal(item.layoutColumn, 6)}` }}
                xl={{ span: `${getColVal(item.layoutColumn, 6)}` }}
                xxl={{ span: `${getColVal(item.layoutColumn, 6)}` }}
                style={{ marginBottom: "35px" }}
                flex=""
              >
                <div className="programgrid__program-info-container">
                  <div className="programgrid__program-image">
                    <Link to={`/program/${item.id}`}>
                      <div className="programgrid__image-container">
                        {item.mainImg && (
                          <img src={`${IMAGE_BASE_URL}${item.mainImg.url}`}
                            style={showHoverEffect && showHoverEffectId === item.id ? { ...onHoverStyleImage } : null}
                            onMouseOver={() => onMouseHoverHeadingOrImage(item.id)}
                            onMouseOut={() => onMouseRemoveHeadingOrImage(item.id)}
                          />
                        )}
                      </div>
                    </Link>
                  </div>
                  <p className="programgrid__program-info-date">
                    {formatDate(item.dateStart)} - {formatDate(item.dateEnd)}
                  </p>
                  {/* <p className="programgrid__program-info-artist-name">
                    {data[0].name}
                  </p> */}
                  <Link to={`/program/${item.id}`}>
                    <p className="programgrid__program-info-program-title fs-1-4em"
                      style={showHoverEffect && showHoverEffectId === item.id ? { ...onHoverStyleText } : null}
                      onMouseOver={() => onMouseHoverHeadingOrImage(item.id)}
                      onMouseOut={() => onMouseRemoveHeadingOrImage(item.id)}
                    >
                      {item.title}
                    </p>
                  </Link>

                  <div style={{ margin: "20px" }}></div>
                  <TagsDisplay tagList={item.tags} />
                </div>
              </Col>
            );
          })}
        </Row>
      )}
    </div>
  );
}
